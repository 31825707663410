import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/professionConfigurations',
        name: 'professionConfigurations',
        meta: { sideMenuLabel: "Configurazione professioni" },
        component: () => import("./views/professionConfigurations.vue")
    },
    {
        path: '/professionConfigurationDetail/:identifier',
        name: 'professionConfigurationDetail',
        component: () => import("./views/professionConfigurationDetail.vue")
    },
];

export default routes;