<template>
    <input type="datetime-local" class="form-control" v-model="localValue" v-date="localValue" @input="emitIsoDate" />
</template>
    
<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel'

@Options({})
export default class datetimeLocal extends mixins(WithModel) {

    onModelValueChange(next, prev){
    }

    // created(){
    //     this.formatLocalDate()
    // }

    // formatLocalDate(){
    //     let date = new Date(this.localValue);
    //     var anni = date.getFullYear().toString();
    //     var mese = (date.getMonth() + 1).toString();
    //     if(mese.length == 1)
    //         mese += "0" + mese;
    //     var giorno = date.getDate().toString();
    //     if(giorno.length == 1)
    //         giorno += "0" + giorno;
    //     var hour = date.getHours().toString();
    //     if(hour.length == 1)
    //         hour += "0" + hour;
    //     var minutes = date.getMinutes().toString();
    //     if(minutes.length == 1)
    //         minutes += "0" + minutes;

    //     return "" + anni + "-" + mese + "-" + giorno + "T" + hour + ":" + minutes
    // }

    emitIsoDate(ev){
        try {
            let toEmit = new Date(ev.target.value).toISOString();
            this.emitModel(toEmit);
        } catch(ex){
            this.emitModel(null);
        }
    }

}
</script>
<style scoped>

</style>