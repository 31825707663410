import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/profile',
        name: 'personalProfile',
        meta: { sideMenuLabel: "Profilo" },
        component: () => import("./views/profile.vue")
    },
    {
        path: '/profile/:identifier?',
        name: 'profile',
        component: () => import("./views/profile.vue")
    },
];

export default routes;