<template>
    <div class="box">
        <div class="boxTitle d-flex justify-content-between mb-3">
            <localized-text localizedKey="Avvisi" text="Avvisi"></localized-text>
            <button type="button" class="btn btn-primary btn-sm" @click="setAllRead">
                <localized-text localizedKey="Segna tutti come letti" text="Segna tutti come letti"></localized-text>
            </button>
        </div>
        <div class="d-flex align-items-center">
            <toggler v-model="showUnreadOnly" @update:modelValue="init"></toggler>
            <localized-text class="ms-1" localizedKey="Mostra solo non letti" text="Mostra solo non letti"></localized-text>
        </div>
        <op-table class="table table-striped backendNotificationTable" :itemsPerPage="pagedVm.perPage" :items="tableResult.data"
            :totalItemsCount="tableResult.count" :filter="pagedVm" @search="init" v-slot:default="opData" :showClearFilters="false" :showItemsPerPageSelector="false">
            <thead>
                <tr>
                    <td width="80"></td>
                    <td width="120">
                        <localized-text localizedKey="Data" text="Data"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="Dettaglio" text="Dettaglio"></localized-text>
                    </td>
                    <td width="220" class="text-end">
                        <localized-text localizedKey="Azioni" text="Azioni"></localized-text>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-if="opData.items.length == 0">
                    <td colspan="99">
                        <localized-text localizedKey="Nessun elemento trovato" text="Nessun elemento trovato"></localized-text>
                    </td>
                </tr>
                <tr v-for="(item, i) in (opData.items as OM.BaseBackendNotification[])" :key="i">
                    <td class="text-center">
                        <button type="button" class="btn btn-link btn-sm" @click="setRead(item)" v-if="!item.readOn">
                            <img src="@/img/exclamation.svg" />
                        </button>
                    </td>
                    <td>{{$filters.date(item.createdOn, "YYYY-MM-DD HH:mm")}}</td>
                    <assenza-cliente :item="item" v-if="item.type == 'AssenzaCliente'"></assenza-cliente>
                    <generic :item="item" v-if="item.type == 'Generic'"></generic>
                    <messaggio-in-prenotazione :item="item" v-if="item.type == 'MessaggioInPrenotazione'"></messaggio-in-prenotazione>
                    <prenotazione-ricevuta :item="item" v-if="item.type == 'PrenotazioneRicevuta'"></prenotazione-ricevuta>
                    <ticket-ricevuto :item="item" v-if="item.type == 'TicketRicevuto'"></ticket-ricevuto>
                    <nota-su-cliente :item="item" v-if="item.type == 'NotaSuCliente'"></nota-su-cliente>
                    <commento-su-cliente :item="item" v-if="item.type == 'CommentoSuCliente'"></commento-su-cliente>
                </tr>
            </tbody>
        </op-table>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BackendNotificationClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import assenzaCliente from './assenzaCliente.vue';
import generic from './generic.vue';
import messaggioInPrenotazione from './messaggioInPrenotazione.vue';
import prenotazioneRicevuta from './prenotazioneRicevuta.vue';
import ticketRicevuto from './ticketRicevuto.vue';
import notaSuCliente from './notaSuCliente.vue';
import commentoSuCliente from './commentoSuCliente.vue';

@Options({
    components: {
        assenzaCliente,
        generic,
        messaggioInPrenotazione,
        prenotazioneRicevuta,
        ticketRicevuto,
        notaSuCliente,
        commentoSuCliente
    }
})
export default class backendNotificationsDrawer extends Vue {

    @Prop() user: OM.BaseUser;

    pagedVm = { pageNumber: 1, perPage: 8 };
    tableResult: OM.TablePagedResultOfBaseBackendNotification = new OM.TablePagedResultOfBaseBackendNotification();
    showUnreadOnly: boolean = false;
    newNotificationCount: number = 0;
    
    created(){
        this.init();
    }

    init(){
        // BackendNotificationClient.countUnread(this.user.identifier)
        // .then(x => {
        //     this.newNotificationCount = x;
        // })
        BackendNotificationClient.getByUser(this.user.identifier, this.showUnreadOnly, this.pagedVm.pageNumber, this.pagedVm.perPage)
        .then(x => {
            this.tableResult = x;
        })
    }

    setRead(item: OM.BaseBackendNotification){
        BackendNotificationClient.setRead(item.identifier)
        .then(x => {
            item.readOn = new Date().toISOString();
        })
    }

    setAllRead(){
        BackendNotificationClient.setAllRead(this.user.identifier)
        .then(x => {
            this.init();
        })
    }
    
}

</script>
