import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-control" }
const _hoisted_6 = { class: "col-sm-6" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "form-control" }
const _hoisted_9 = { class: "col-sm-6" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-control" }
const _hoisted_12 = { class: "col-sm-6" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-control" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "form-control" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-control" }
const _hoisted_19 = {
  filter: "domanda.name",
  sort: "domanda.name"
}
const _hoisted_20 = { filter: "answerFilter" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { colspan: "99" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, [
      _createVNode(_component_localized_text, {
        localizedKey: "Risposte questionario",
        text: "Risposte questionario"
      })
    ]),
    (_ctx.answers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", null, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Progetto",
                  text: "Progetto"
                })
              ]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.answers.project), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", null, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Nazione",
                  text: "Nazione"
                })
              ]),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.answers.country), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", null, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Dottore",
                  text: "Dottore"
                })
              ]),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.answers.doctor.name), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("label", null, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Professione",
                  text: "Professione"
                })
              ]),
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.answers.profession.name), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Cliente",
          text: "Cliente"
        })
      ]),
      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.answers.customer.name), 1)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Completato",
          text: "Completato"
        })
      ]),
      _createElementVNode("span", _hoisted_18, [
        (_ctx.answers.isCompleted)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 0,
              localizedKey: "Si",
              text: "Si"
            }))
          : (_openBlock(), _createBlock(_component_localized_text, {
              key: 1,
              localizedKey: "No",
              text: "No"
            }))
      ])
    ]),
    _createVNode(_component_op_table, {
      class: "table table-striped",
      items: _ctx.answers.answerValues,
      filtersFn: _ctx.filtersFn
    }, {
      default: _withCtx((opData) => [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_19, [
              _createVNode(_component_localized_text, {
                localizedKey: "Domanda",
                text: "Domanda"
              })
            ]),
            _createElementVNode("td", _hoisted_20, [
              _createVNode(_component_localized_text, {
                localizedKey: "Risposte",
                text: "Risposte"
              })
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          (opData.items.length == 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
                _createElementVNode("td", _hoisted_22, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessun elemento trovato",
                    text: "Nessun elemento trovato"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((opData.items as OM.AnswerValue[]), (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, _toDisplayString(item.questionText.values[_ctx.$store.state.selectedLanguage]), 1),
              _createElementVNode("td", null, [
                (item.selectAnswer.length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(item.selectAnswer.map(x => x.values[_ctx.$store.state.selectedLanguage]).join(' - ')), 1)
                    ], 64))
                  : (item.textAnswer)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(item.textAnswer), 1)
                      ], 64))
                    : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["items", "filtersFn"])
  ]))
}