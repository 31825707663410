import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/operators',
        name: 'operators',
        meta: { sideMenuLabel: "Operatori" },
        component: () => import("./views/operators.vue")
    },
    {
        path: '/operators/:identifier',
        name: 'operatorsDetail',
        meta: { classes: "" },
        component: () => import("./views/operatorsDetail.vue")
    }
];

export default routes;