<template>
    <div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Username" text="Username"></localized-text>
            </label>
            <input type="text" class="form-control" v-model="localValue.username" />
        </div>
        <label class="text-warning pointer" @click="showPasswordChange">
            <template v-if="!showPassword">
                <localized-text localizedKey="Clicca per modificare la password" text="Clicca per modificare la password"></localized-text>
            </template>
            <template v-else>
                <localized-text localizedKey="Annulla" text="Annulla"></localized-text>
            </template>
        </label>
        <button class="btn btn-sm btn-success ms-2" v-if="showPassword" :disabled="!localValue.password" @click="savePasswordChange">
            <localized-text localizedKey="Salva" text="Salva"></localized-text>
        </button>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Password" text="Password"></localized-text>
            </label>
            <input type="password" class="form-control" :disabled="!showPassword" v-model="localValue.password" />
        </div>
        <div class="form-group" v-if="$store.state.isAdmin()">
            <label>
                <localized-text localizedKey="Login abilitata" text="Login abilitata"></localized-text>
            </label>
            <input type="checkbox" v-model="localValue.loginEnabled" class="form-control" />
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model'
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { BaseUserClient } from '@/services/Services';

@Options({})
export default class authData extends mixins(WithModel) {

    @Prop() user: OM.BaseUser;

    declare localValue: OM.AuthData;

    showPassword: boolean = false;
    previousPassword: string = "";

    showPasswordChange(){
        this.showPassword = !this.showPassword;
        if(this.showPassword){
            this.previousPassword = this.localValue.password;
            this.localValue.password = "";
        } else {
            this.localValue.password = this.previousPassword;
            this.previousPassword = "";
        }
    }

    savePasswordChange(){
        BaseUserClient.setPassword(this.user.identifier, this.localValue.password)
        .then(x => {
            this.localValue.password = x;
            this.previousPassword = x;
            this.showPassword = false;
        });
    }

}
</script>
<style scoped>
</style>
