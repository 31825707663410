import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-sm-6" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "col-sm-6" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = {
  key: 0,
  class: "row"
}
const _hoisted_12 = { class: "form-group col-md-4" }
const _hoisted_13 = { class: "form-group col-md-4" }
const _hoisted_14 = { class: "form-group col-md-4" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "form-group col-md-4" }
const _hoisted_17 = { class: "form-group col-md-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_toggler = _resolveComponent("toggler")!
  const _component_op_date = _resolveComponent("op-date")!
  const _component_op_address = _resolveComponent("op-address")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Nome (fatturazione)",
              text: "Nome (fatturazione)"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.name) = $event))
          }, null, 512), [
            [_vModelText, _ctx.localValue.name]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Cognome (fatturazione)",
              text: "Cognome (fatturazione)"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.surname) = $event))
          }, null, 512), [
            [_vModelText, _ctx.localValue.surname]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Codice Fiscale",
              text: "Codice Fiscale"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue.fiscalCode) = $event))
          }, null, 512), [
            [_vModelText, _ctx.localValue.fiscalCode]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Partita iva",
              text: "Partita iva"
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_toggler, {
              modelValue: _ctx.localValue.hasVat,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue.hasVat) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ])
    ]),
    (_ctx.localValue.hasVat)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Numero partita iva",
                text: "Numero partita iva"
              })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localValue.vatNumber) = $event))
            }, null, 512), [
              [_vModelText, _ctx.localValue.vatNumber]
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Codice destinatario",
                text: "Codice destinatario"
              })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localValue.codiceDestinatario) = $event))
            }, null, 512), [
              [_vModelText, _ctx.localValue.codiceDestinatario]
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Pec",
                text: "Pec"
              })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localValue.pec) = $event))
            }, null, 512), [
              [_vModelText, _ctx.localValue.pec]
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Data di nascita",
            text: "Data di nascita"
          })
        ]),
        _createVNode(_component_op_date, {
          class: "form-control",
          modelValue: _ctx.localValue.dataNascita,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localValue.dataNascita) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Luogo nascita",
            text: "Luogo nascita"
          })
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localValue.luogoNascita) = $event))
        }, null, 512), [
          [_vModelText, _ctx.localValue.luogoNascita]
        ])
      ])
    ]),
    _createVNode(_component_op_address, {
      modelValue: _ctx.localValue.fiscalAddress,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localValue.fiscalAddress) = $event)),
      label: _ctx.$localizationService.GetLocalizedValue('Indirizzo di fatturazione', 'Indirizzo di fatturazione')
    }, null, 8, ["modelValue", "label"])
  ]))
}