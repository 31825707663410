import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/leads',
        name: 'leads',
        meta: { sideMenuLabel: "Lead" },
        component: () => import("./views/leads.vue")
    },
    {
        path: '/leads/:identifier',
        name: 'leadsDetail',
        meta: { classes: "" },
        component: () => import("./views/leadsDetail.vue")
    },
    {
        path: '/leadsAcceptance',
        name: 'leadsAcceptance',
        meta: { sideMenuLabel: "Accettazione" },
        component: () => import("./views/leadsAcceptance.vue")
    },
    {
        path: '/pipeline',
        name: 'pipeline',
        meta: { classes: "", sideMenuLabel: "Pipeline" },
        component: () => import("./views/pipeline.vue")
    },
    {
        path: '/overviewCalendar',
        name: 'overviewCalendar',
        meta: { classes: "", sideMenuLabel: "Calendario" },
        component: () => import("./views/overviewCalendar.vue")
    },
];

export default routes;