<template>
    <div>
        <div class="form-group">
            <label>Alimento</label>
            <input type="text" class="form-control" v-model="localValue.name" />
        </div>
        <div class="form-group">
            <label>Quantità</label>
            <input type="text" class="form-control" v-model="localValue.quantity" />
        </div>
    </div>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';

@Options({})
export default class spesaItem extends mixins(WithModel) {

    declare localValue: OM.GroceryItem;

}

</script>
<style scoped>
</style>
