<template>
    <div v-if="user">
        <div class="row">
            <div class="col-md-6 mb-4">
                <customer-food-plans :user="user"></customer-food-plans>
            </div>
            <div class="col-md-6 mb-4">
                <div class="box">
                    <div class="midTitle">
                        <localized-text localizedKey="Documenti cliente" text="Documenti cliente"></localized-text>
                    </div>
                    <div class="opTableScroller">
                        <table class="opTable table table-striped">
                            <thead>
                                <tr>
                                    <td><localized-text localizedKey="Data creazione" text="Data creazione"></localized-text></td>
                                    <td><localized-text localizedKey="Tipo documento" text="Tipo documento"></localized-text></td>
                                    <td><localized-text localizedKey="Allegati" text="Allegati"></localized-text></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="docs.length == 0">
                                    <td colspan="99">
                                        <localized-text localizedKey="Nessun documento trovato" text="Nessun documento trovato"></localized-text>
                                    </td>
                                </tr>
                                <tr v-for="(doc, i) in docs" :key="i">
                                    <td>{{$filters.date(doc.createdOn, 'DD-MM-YYYY HH:mm')}}</td>
                                    <td>{{doc.type}}</td>
                                    <td>
                                        <div v-for="(item, i) in doc.signedDocumentAttachmentList" :key="i">
                                            <a :href="item.publicUrl" target="_blank">
                                                {{item.fileName}}
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-md-6 mb-4" v-if="user">
                <div class="box">
                    <misure-table :user="user"></misure-table>
                </div>
            </div>
            <div class="col-md-6 mb-4">
                <div class="box">
                    <div class="midTitle">
                        <localized-text localizedKey="Acquisti cliente" text="Acquisti cliente"></localized-text>
                    </div>
                    <div class="opTableScroller">
                        <table class="opTable table table-striped">
                            <thead>
                                <tr>
                                    <td><localized-text localizedKey="Data acquisto" text="Data acquisto"></localized-text></td>
                                    <td><localized-text localizedKey="Carrello" text="Carrello"></localized-text></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in (purchases as OM.Purchase[])" :key="i">
                                    <td>
                                        {{$filters.date(item.createdOn, 'DD-MM-YYYY HH:mm')}}
                                    </td>
                                    <td>
                                        <u>{{item.purchasedOffers.length}} 
                                            <localized-text localizedKey="elementi" text="elementi"></localized-text>
                                        </u><br/>
                                        <div v-for="(offer, i) in item.purchasedOffers" :key="i">
                                            <small>- {{offer.name}} </small><b>(x{{offer.quantity}})</b>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-4" v-if="user">
                <div class="box">
                    <customer-bookings :user="user"></customer-bookings>
                </div>
            </div>
            <div class="col-md-6 mb-4">
                <div class="box">
                    <customer-survey-answers :user="user"></customer-survey-answers>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import { FoodPlanClient, MeasureClient, PurchaseClient, SignedDocumentsClient } from '@/services/Services';
import { LocalizationServices } from '@/services/LocalizationServices';
import { ModalServices } from '@/services/ModalServices';
import { Prop } from 'vue-property-decorator';
import qualification from '@/components/user/components/qualification.vue';
import newFoodPlanFromDefaultsModal from './newFoodPlanFromDefaultsModal.vue';

@Options({
    components: {
        qualification
    }
})
export default class customerData extends Vue {
 
    @Prop() user: OM.Lead;

    foodPlanVouchers: OM.FoodPlanVoucher[] = [];
    foodPlans: OM.FoodPlan[] = [];
    purchases: OM.Purchase[] = [];
    docs: OM.BaseSignedDocument[] = [];
    surveyAnswers: OM.SurveyAnswers[] = [];

    created(){
        this.init();
    }

    init(){
        PurchaseClient.getByCliente(this.user.identifier, this.$store.state.selectedProject, true).then(x => this.purchases = x);
        SignedDocumentsClient.getByUser(this.$route.params.identifier as string).then(x => this.docs = x);
    }

    newPianoAlimentareFromDefaults(){
        this.$opModal.show(newFoodPlanFromDefaultsModal, {
            customer: this.user,
            voucher: this.foodPlanVouchers[0]
        })
    }
    
    newPianoAlimentare(){
        ModalServices.confirmModal(
            LocalizationServices.GetLocalizedValue('Confermi?', 'Confermi?'),
            LocalizationServices.GetLocalizedValue('Scaleremo un ticket', 'Scaleremo un ticket'),
            LocalizationServices.GetLocalizedValue('Si', 'Si'),
            LocalizationServices.GetLocalizedValue('No', 'No'),
            () => {
                let nutrizionista = this.user.professionistiAssegnati.find(x => x.professione.name == 'Nutrizionista');
                FoodPlanClient.createPianoAlimentare(this.user.identifier, nutrizionista.user.identifier, this.foodPlanVouchers[0].identifier)
                .then(x => {
                    this.$router.push('/foodPlans/' + x);
                })
            }
        );
    }

    saveMisura(item: OM.Measure){
        MeasureClient.save(item)
        .then(x => {
            delete (<any>item).modify;
        });
    }

    get isDottoreOfUser(){
        if(!this.user.professionistiAssegnati)
            return false;
        return this.user.professionistiAssegnati.some(x => x.user && x.user.identifier == this.$store.state.user.identifier);
    }

    
}

</script>
