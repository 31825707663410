import * as OM from '@/model';

export default {
    leadstatus: function (el, binding, vnode, prevVnode) {
        let statusFound = false;
        [].forEach(x => {
            let sanitized = x.replace(/ /g, "-").toLowerCase();
            el.classList.remove(sanitized);
            if(binding.value.status && binding.value.status.toLowerCase() == x.toLowerCase()){
                el.classList.add(sanitized);
                statusFound = true;
            }
        })
        if(!statusFound){
            let startDate;
            if(!binding.value.scadenze){
                if(binding.value.startDate)
                    startDate = binding.value.startDate;
                else
                    return;
            } else {
                if(binding.value.scadenze.length == 0)
                    return;
                    
                startDate = binding.value.scadenze[0].startDate;
            }
            if(new Date(startDate).getTime() <= Date.now()){
                el.classList.add('da-fare');
            } else {
                el.classList.add('in-attesa');
            }
        }
    },
    date: function(el, binding, vnode, prevVnode){
        if(!binding.value)
            return;
    
        if(el.tagName.toLowerCase() != 'input'){
            console.error('v-date directive must be used on input tags. use type="date"');
            return;
        }
        if(el.getAttribute('type') != 'date' && el.getAttribute('type') != 'datetime-local'){
            console.error('v-date directive must be used on input with type="date"');
            return;
        }
    
        let val = binding.value;
        if(val){
            (<HTMLInputElement>el).value = formatDateForInput(val, el.getAttribute('type') == 'datetime-local');
        }
    },
}


function validateDate(inputDate: string){
    var stringDate = inputDate.toString();
    let date = new Date(stringDate);
    if(isValidDate(date)){
        let years = date.getFullYear().toString();
        while(years.length < 4){
            years = "0" + years;
        } 
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;

        return years +  "-" + months + "-" + days;
    }
}

function isValidDate(d: any) {
    return d instanceof Date && !isNaN(<any>d);
}
function formatDateForInput(inputDate: string, isDateTimeLocal){
    var stringDate = inputDate.toString();
    let date = new Date(stringDate);
    if(isValidDate(date)){
        let years = date.getFullYear().toString();
        while(years.length < 4){
            years = "0" + years;
        } 
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;

        let ris = years +  "-" + months + "-" + days;

        if(isDateTimeLocal){   
            let hours = date.getHours().toString();
            if(hours.length < 2) hours = "0" + hours;
            let minutes = date.getMinutes().toString();
            if(minutes.length < 2) minutes = "0" + minutes;
            ris += "T" + hours + ":" + minutes;
        }
        return ris;
    }
}