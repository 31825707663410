import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2ae97258"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "midTitle text-center mb-2" }
const _hoisted_3 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_contact_data = _resolveComponent("contact-data")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        localizedKey: "Dati di contatto",
        text: "Dati di contatto"
      })
    ]),
    _createVNode(_component_contact_data, {
      modelValue: _ctx.centro,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.centro) = $event)),
      readonly: true
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, "Chiudi")
    ])
  ]))
}