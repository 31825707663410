import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "boxTitle"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-3" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "form-control"
}
const _hoisted_7 = { value: "" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "col-sm-9" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("label", _hoisted_5, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Prefisso",
                  text: "Prefisso"
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.readonly)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.localValue.prefix), 1))
            : _withDirectives((_openBlock(), _createElementBlock("select", {
                key: 2,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.prefix) = $event)),
                class: "form-control"
              }, [
                _createElementVNode("option", _hoisted_7, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Seleziona",
                    text: "Seleziona"
                  })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prefixList, (item, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: '+' + item.prefix
                  }, _toDisplayString(item.country) + " + " + _toDisplayString(item.prefix), 9, _hoisted_8))
                }), 128))
              ], 512)), [
                [_vModelSelect, _ctx.localValue.prefix]
              ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_10, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Numero",
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.number) = $event)),
          readonly: _ctx.readonly
        }, null, 8, _hoisted_11), [
          [_vModelText, _ctx.localValue.number]
        ])
      ])
    ])
  ]))
}