import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e2e19d14"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "opSelect",
  ref: "root"
}
const _hoisted_2 = {
  key: 0,
  class: "opOptionsContainer"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "opOption selected",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, [
      _renderSlot(_ctx.$slots, "default", {
        item: _ctx.getItemText(_ctx.localValue)
      }, () => [
        _createTextVNode(_toDisplayString(_ctx.getItemText(_ctx.localValue)), 1)
      ])
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "opOption",
              key: i,
              onClick: ($event: any) => (_ctx.setOption(item))
            }, [
              _renderSlot(_ctx.$slots, "default", {
                item: _ctx.getItemText(item)
              }, () => [
                _createTextVNode(_toDisplayString(_ctx.getItemText(item)), 1)
              ])
            ], 8, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}