<template>
    <td>
        <!-- <localized-text :localizedKey="item.text" :text="item.text"></localized-text> -->
        {{item.text}}
    </td>
    <td class="text-end">
    </td>
</template>

<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class generic extends Vue {

    @Prop() item: OM.GenericBackend;

}

</script>
