<template>
    <div class="opModal appuntamentiOfDateModal">
        <div class="modalHeader flex-column">
            <div class="bigTitle">{{$filters.date(date, 'DD MMMM YYYY')}}</div>
            <div class="midTitle text-orange mt-2">
                <localized-text localizedKey="Prenotazioni" text="Prenotazioni"></localized-text>
            </div>
        </div>
        <div class="modalBody container">
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Professione" text="Professione"></localized-text>
                </label>
                <span class="form-control">{{selectedProfessione.name}}</span>
                <!-- <select class="form-control" v-model="selectedProfessione">
                    <option v-for="(item, i) in professioni" :value="item">{{item.name}}</option>
                </select> -->
            </div>
            <hr/>
            <div class="row" v-if="selectedProfessione">
                <div class="col-md-6">
                    <div class="midTitle text-center">
                        <localized-text localizedKey="Slot liberi" text="Slot liberi"></localized-text>
                    </div>
                    <div class="list-group">
                        <div class="list-group-item text-center mt-2" v-for="(item, i) in localOrari.fasceOrarie" :key="i" :class="{ 'bg-warning': isEsclusione(item) }">
                            <div>
                                <b>{{$filters.timespan(item.from)}} - {{$filters.timespan(item.to)}}</b>
                            </div>
                            <div class="mt-1">
                                <template v-if="!isEsclusione(item)">
                                    <button type="button" class="btn btn-success btn-sm" @click="prenota(item)">
                                        <localized-text localizedKey="Prenota" text="Prenota"></localized-text>
                                    </button>
                                    <button type="button" class="btn btn-warning btn-sm ms-2" @click="disabilita(item)">
                                        <localized-text localizedKey="Disabilita" text="Disabilita"></localized-text>
                                    </button>
                                </template>
                                <button type="button" class="btn btn-info btn-sm ms-2" @click="riabilita(item)" v-else>
                                    <localized-text localizedKey="Riabilita" text="Riabilita"></localized-text>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="midTitle text-center">
                        <localized-text localizedKey="Prenotazioni" text="Prenotazioni"></localized-text>
                    </div>
                    <div class="list-group">
                        <div class="mt-2" v-if="prenotazioni.length == 0">
                            <localized-text localizedKey="Nessuna prenotazione" text="Nessuna prenotazione"></localized-text>
                        </div>
                        <div class="list-group-item text-center mt-2" v-for="(item, i) in prenotazioni" :key="i">
                            <div>
                                <b>{{$filters.timespan(item.fasciaOraria.from)}} - {{$filters.timespan(item.fasciaOraria.to)}}</b>
                            </div>
                            <div class="mt-1">
                                <div>
                                    <localized-text localizedKey="Cliente" text="Cliente" afterText=":"></localized-text>
                                </div>
                                <div>
                                    <b v-if="item.cliente">{{item.cliente.name}}</b>
                                </div>
                            </div>
                            <div class="mt-1">
                                <div>
                                    <localized-text localizedKey="Professione" text="Professione" afterText=":"></localized-text>
                                </div>
                                <div>
                                    <b v-if="item.professione">{{item.professione.name}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modalFooter text-end">
            <button type="button" class="btn btn-primary" @click="$emit('close')">
                <localized-text localizedKey="Chiudi" text="Chiudi"></localized-text>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient, OperatorClient, TimeTableExclusionClient } from '@/services/Services';
import { UploadServices } from '@/services/UploadServices';
import { ModalServices } from '@/services/ModalServices';
import EventBus from '@/services/EventBus';
import { JsonClone } from '@/utils';
import prenotaPerClienteModal from './prenotaPerClienteModal.vue';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class appuntamentiOfDateModal extends Vue {

    @Prop() doctorIdentifier: string;
    @Prop() customerIdentifier: string;
    @Prop() voucher: OM.BookingVoucher;
    @Prop() vm: OM.OrariPerPrenotareVm;
    @Prop() booking: OM.BookingForListVm;
    @Prop() prenotazioni: OM.BookingForListVm[];
    @Prop() professione: OM.NameIdentifier;
    @Prop() date: Date;

    localOrari: OM.OrariPerPrenotareVm = new OM.OrariPerPrenotareVm();
    esclusioni: OM.TimeTableExclusion[] = [];
    professioni: OM.NameIdentifier[] = [];
    selectedProfessione: OM.NameIdentifier = null;

    created(){
        if(this.professione){
            this.selectedProfessione = this.professione;
        }
        BaseUserClient.getProfessioniOfUser(this.doctorIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
        })
    }
    
    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione)
            this.init();
    }

    init(){
        if(this.vm){
            this.localOrari = JsonClone(this.vm);
            TimeTableExclusionClient.getByDate(this.doctorIdentifier, this.selectedProfessione.identifier, this.vm.date)
            .then(x => {
                this.esclusioni = x;
                this.localOrari.fasceOrarie.push(...x.flatMap(c => c.timeTable));
                this.localOrari.fasceOrarie = this.localOrari.fasceOrarie.sort((a, b) => parseInt(a.from) - parseInt(b.to))
            })
        } else {
            this.localOrari = new OM.OrariPerPrenotareVm();
        }
    }

    prenota(item: OM.TimeTableItem){
        this.$opModal.show(prenotaPerClienteModal, {
            doctorIdentifier: this.doctorIdentifier,
            customerIdentifier: this.customerIdentifier,
            voucher: this.voucher,
            booking: this.booking,
            fasciaOraria: item,
            date: this.date
        })
    }

    isEsclusione(item: OM.TimeTableItem){
        return this.esclusioni.some(x => x.timeTable.some(c => c.from == item.from && c.to == item.to));
    }

    disabilita(item: OM.TimeTableItem){
        ModalServices.confirmModal("Confermi?", "Vuoi disabilitare questo slot?", null, null, () => {
            let toSave = new OM.TimeTableExclusion();
            toSave.date = this.localOrari.date;
            toSave.timeTable = [item];
            toSave.userIdentifier = this.doctorIdentifier;
            toSave.profession = this.professione;
            TimeTableExclusionClient.save([toSave])
            .then(x => {
                toSave.identifier = x[0];
                this.esclusioni.push(toSave);
                ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Salvato", "Salvato"));
                EventBus.$emit('reloaddash');
            })
        })
    }

    riabilita(item: OM.TimeTableItem){
        let esclusione = this.esclusioni.find(x => x.timeTable.some(c => c.from == item.from && c.to == item.to));
        TimeTableExclusionClient.deleteById(esclusione.identifier)
            .then(x => {
                this.esclusioni = this.esclusioni.filter(c => c.identifier != esclusione.identifier);
                ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Fatto", "Fatto"));
                EventBus.$emit('reloaddash');
            })
    }

}

</script>

<style scoped>

.opModal {
    min-width: 500px;
    max-width: 1280px;
}

</style>