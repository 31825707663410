class Enum {
    static GetAll(){
        let ris = [];
        for(let p in this){
            ris.push(this[p]);
        }
        return ris;
    }
    static GetDictionary(){
        let ris = {};
        for(let p in this){
            ris[p] = this[p];
        }
        return ris;
    }
}

export class RoutesGroup extends Enum {
    static Performance: RoutesGroupConfig = { text: "Performance", order: 1 };
    static Gestione: RoutesGroupConfig = { text: "Gestione", order: 2 };
    static Marketing: RoutesGroupConfig = { text: "Marketing", order: 3 };
    static Configurazione: RoutesGroupConfig = { text: "Configurazione", order: 4 };
}
class RoutesGroupConfig {
    text: string;
    order: number;
}

export class ProjectNames extends Enum
{
    static SHC: string = "SHC";
    static NutriLab: string = "NutriLab";
    static NutriAmo: string = "NutriAmo";
    static DimagriAmo: string = "DimagriAmo";
    static AntismokingIT: string = "Antismoking IT";
    static AntismokingCH: string = "Antismoking CH";
    static FranchisingIT: string = "Franchising IT";
    static FranchisingCH: string = "Franchising CH";
}

export class DayOfWeek extends Enum
{
    static Sunday: string = "0";
    static Monday: string = "1";
    static Tuesday: string = "2";
    static Wednesday: string = "3";
    static Thursday: string = "4";
    static Friday: string = "5";
    static Saturday: string = "6";
}

export class SurveyType extends Enum
{
    static Assessment: string = "Assessment";
    static Internal: string = "Internal";
}

export class UserTypes extends Enum
{
    static Lead: string = "Lead";
    static Customer: string = "Customer";
    static Operator: string = "Operator";
    static Doctor: string = "Doctor";
}

export class Sesso
{
    static Maschio: string = "Maschio";
    static Femmina: string = "Femmina";
}

export class ModalitaPagamentoUser extends Enum {
    static Bonifico: string = "Bonifico";
    static Paypal: string = "Paypal";
    static Revolut: string = "Revolut";
}
