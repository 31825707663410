<template>
    <div class="opModal">
        <div class="modalHeader"><h4>Account collegati</h4></div>
        <div class="modalBody">
            <div class="text-center">
                <div class="midTitle">
                    <localized-text localizedKey="Hai più ruoli all'interno di NutriLab?" text="Hai più ruoli all'interno di NutriLab?"></localized-text>
                </div>
                <div class="mt-2 fs-14">
                    <localized-text localizedKey="Collega i tuoi account per poter passare velocemente da un all'altro." text="Collega i tuoi account per poter passare velocemente da un all'altro."></localized-text>
                </div>
                <div class="list-group mt-3">
                    <div class="list-group-item" v-if="!$store.state.user.linkedAccounts || $store.state.user.linkedAccounts.length == 0">
                        <localized-text localizedKey="Nessun account collegato" text="Nessun account collegato"></localized-text>
                    </div>
                    <div class="list-group-item" v-for="(item, i) in $store.state.user.linkedAccounts">
                        <div class="d-flex justify-content-around align-items-center">
                            <div>{{item.name}}</div>
                            <div>
                                <button type="button" class="btn btn-success" @click="switchToThis(item)">
                                    <localized-text localizedKey="Passa a questo account" text="Passa a questo account"></localized-text>
                                </button>
                                <button type="button" class="btn btn-danger" @click="removeThis(item)">
                                    <localized-text localizedKey="Scollega" text="Scollega"></localized-text>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="list-group-item">
                        <button type="button" class="btn btn-primary" @click="addAccountModal">
                            <localized-text localizedKey="Aggiungi account" text="Aggiungi account"></localized-text>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modalFooter">
            <div class="text-end">
                <button type="button" class="btn btn-primary" @click="$emit('close')">
                    <localized-text localizedKey="Chiudi" text="Chiudi"></localized-text>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import addLinkedAccountModal from './addLinkedAccountModal.vue'
import { BaseUserClient, LoginClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import store from '@/store';

@Options({})
export default class linkedAccountModal extends Vue {

    addAccountModal(){
        this.$opModal.show(addLinkedAccountModal, {});
    }

    switchToThis(item: OM.NameIdentifier){
        LoginClient.switchToAccount(item.identifier)
        .then(x => {
            LoginServices.loginCallback(x)
            .then(x => {
                this.$router.replace('/dashboard')
                .then(x => {
                    store.state.showSpinner = 99;
                    window.location.reload();
                });
            });
        })
    }

    removeThis(item: OM.NameIdentifier){
        BaseUserClient.removeLinkedAccount(item.identifier)
        .then(x => {
            store.state.user.linkedAccounts = store.state.user.linkedAccounts.filter(x => x != item);
        })
    }

}

</script>
