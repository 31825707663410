<template>
    <div class="row">
        <div class="col-sm-12 mb-3">
            <div class="box">
                <accordion>
                    <template #title>
                        <div class="boxTitle">
                            <localized-text localizedKey="Prenotazioni ({{tickets}} disponibili, {{prenotazioni}} effettuate)" text="Prenotazioni ({{tickets}} disponibili, {{prenotazioni}} effettuate)"
                                :object="{ tickets: tickets.length, prenotazioni: prenotazioni.length }"></localized-text>
                        </div>
                    </template>
                    <div class="mb-3 d-flex align-items-center justify-content-between">
                        <div>
                            <span class="midTitle" v-if="tickets.length > 0">    
                                <localized-text localizedKey="Hai una prenotazione a disposizione!" text="Hai una prenotazione a disposizione!"></localized-text>
                            </span>
                        </div>
                        <button type="button" class="btn btn-primary" @click="makePrenotazione" :disabled="tickets.length <= 0">
                            <localized-text localizedKey="Effettua prenotazione" text="Effettua prenotazione"></localized-text>
                        </button>
                    </div>
                    <op-table class="table table-striped" :items="prenotazioni" v-slot:default="opData" :filtersFn="filtersFn" 
                        :showUpperPagination="useOpTable" :showLowerPagination="useOpTable">
                        <thead>
                            <tr>
                                <td width="80" :filter="useOpTable ? 'data' : false" :sort="useOpTable ? 'data' : false" filterType="date"><localized-text localizedKey="Data" text="Data"></localized-text></td>
                                <td width="100" :filter="useOpTable ? 'user.name' : false"><localized-text localizedKey="Centro" text="Centro"></localized-text></td>
                                <td width="100" :filter="useOpTable ? 'fasciaOraria' : false"><localized-text localizedKey="Fascia oraria" text="Fascia oraria"></localized-text></td>
                                <td :filter="useOpTable ? 'note' : false" :sort="useOpTable ? 'note' : false"><localized-text localizedKey="Note" text="Note"></localized-text></td>
                                <td :filter="useOpTable ? 'stato.value' : false" :sort="useOpTable ? 'stato.value' : false"><localized-text localizedKey="Stato" text="Stato"></localized-text></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="opData.items.length == 0">
                                <td colspan="99"><localized-text localizedKey="Nessun elemento trovato" text="Nessun elemento trovato"></localized-text></td>
                            </tr>
                            <tr v-for="(item, i) in (opData.items as OM.BookingForListVm[])" :key="i">
                                <td>{{$filters.date(item.data)}}</td>
                                <td>
                                    <button type="button" class="btn btn-link text-left" @click="centroDetails(item.user)">
                                        <u>{{item.user.name}}</u>
                                    </button>
                                </td>
                                <td><localized-text localizedKey="Dalle" text="Dalle"></localized-text> {{$filters.timespan(item.fasciaOraria.from)}} <localized-text localizedKey="alle" text="alle"></localized-text> {{$filters.timespan(item.fasciaOraria.to)}}</td>
                                <td>{{item.note}}</td>
                                <td>
                                    <template v-if="item.stato">
                                        {{item.stato.value}}<br/>({{$filters.date(item.stato.date, "YYYY-MM-DD HH:mm")}})
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </op-table>
                </accordion>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewmodel';
import { ModalServices } from '@/services/ModalServices';
import centroDetailModal from './centroDetailModal.vue';
import EventBus from '@/services/EventBus';
import { BaseUserClient, BaseVoucherClient, BookingClient, BookingVoucherClient } from '@/services/Services';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class tabellaPrenotazioni extends Vue {
 
    @Prop() user: OM.BaseUser;
    @Prop() reload;
    @Prop({
        default: true
    }) useOpTable: boolean;

    tickets: OM.BookingVoucher[] = [];
    prenotazioni: OM.BookingForListVm[] = [];
    
    filtersFn = {
        fasciaOraria: (prop: OM.TimeTableItem, val) => {
            return prop.from.indexOf(val) > -1 || prop.to.indexOf(val) > -1;
        }
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }
    
    created(){
        this.init();
    }

    init(){
        // BookingVoucherClient.getAvailableBookingVoucher(this.user.identifier)
        // .then(x => {
        //     this.tickets = x;
        // })
        BookingClient.getByCliente(this.user.identifier)
        .then(x => {
            this.prenotazioni = x;
        })
    }

    makePrenotazione(){
        if(this.tickets.length <= 0)
            return;

        alert("coming soon");

        // this.$opModal.show(prenotaAppuntamentoModal, {
        //     ticketPrenotazione: this.tickets[0],
        //     lead: this.user
        // }, () => {
        //     EventBus.$emit('reloaddash');
        // })

        // this.$opModal.show(appuntamentoModal, {
        //     cliente: this.user,
        //     ticketPrenotazioneIdentifier: this.tickets[0]
        // }, () => {
        //     this.init();
        // })
    }

    centroDetails(centro: OM.NameIdentifier){
        BaseUserClient.getCentroFumoDetails(centro.identifier)
        .then(x => {
            this.$opModal.show(centroDetailModal, {
                centro: x
            })
        })
    }

}

</script>
