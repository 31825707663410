<template>
    <div class="addCommentContainer">
        <div class="commentAttachmentDropArea droparea" @drop="onDrop">
            <localized-text localizedKey="Rilascia qui per allegare" text="Rilascia qui per allegare"></localized-text>
        </div>
        <auto-textarea class="form-control" placeholder="Scrivi qui" v-model="localValue.text" :maxHeight="150"></auto-textarea>
        <label class="btn btn-link">
            <img src="@/img/common/icon_clip.svg" />
            <file-input class="hidden" v-model="tempFile" @update:modelValue="addFile"></file-input>
        </label>
        <slot>
            <button type="button" class="btn btn-success" :disabled="!canSaveComment" @click="addComment">
                <localized-text localizedKey="Salva" text="Salva"></localized-text>
            </button>
        </slot>
    </div>
    <div class="addCommentAttachment" v-if="localValue.fileList.length > 0">
        <div v-for="item in localValue.fileList" :key="item.name">
            <localized-text localizedKey="Allegato:" text="Allegato:"></localized-text> {{item.name}}
            <button type="button" class="btn btn-danger btn-sm" @click="removeFile(item)">
                x
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import * as OM from '@/model';
import { JsonClone } from '@/utils';
import * as VM from '@/viewmodel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    emits: {
        add: ""
    }
})
export default class newCommentForm extends mixins(WithModel) {

    declare localValue: VM.AddCommentVm;

    tempFile: File = null;

    onDrop(ev){
        this.tempFile = ev.dataTransfer.files[0];
        this.addFile();
    }

    addComment(){
        if(!this.canSaveComment)
            return;

        this.$emit('add', this.localValue);

        this.localValue = new VM.AddCommentVm();
    }

    addFile(){
        this.localValue.fileList.push(this.tempFile);
        this.tempFile = null;
    }

    removeFile(item: File){
        let index = this.localValue.fileList.findIndex(x => x == item);
        this.localValue.fileList.splice(index, 1);
    }
    
    get canSaveComment(){
        return this.localValue.text || this.localValue.fileList.length > 0;
    }

}

</script>

<style scoped>

.hidden {
    left: 0;
}


</style>