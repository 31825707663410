<template>
    <div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Intestatario" text="Intestatario"></localized-text>
            </label>
            <input class="form-control" v-model="localValue.intestatario" />
        </div>
        <div class="row">
            <div class="form-group col-sm-3">
                <label>
                    <localized-text localizedKey="Nome banca" text="Nome banca"></localized-text>
                </label>
                <input class="form-control" v-model="localValue.bankName" />
            </div>
            <div class="form-group col-sm-9">
                <label>
                    <localized-text localizedKey="Swift" text="Swift"></localized-text>
                </label>
                <input class="form-control" v-model="localValue.swift" />
            </div>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Iban" text="Iban"></localized-text>
            </label>
            <input class="form-control" v-model="localValue.iban" />
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model'
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class bankAccountData extends mixins(WithModel) {

    declare localValue: OM.BankAccountData;

}
</script>
<style scoped>
</style>
