<template>
    <div class="commentsContainer">
        <div class="scrollListContainer">
            <div class="scrollListItem" v-if="todos.length == 0">
                <localized-text localizedKey="Nessun elemento inserito" text="Nessun elemento inserito"></localized-text>
            </div>
            <template v-else>
                <div class="scrollListItem comment" v-for="(item, i) in todos" :key="i">
                    <div class="top">
                        <div class="date">{{$filters.date(item.createdOn, 'DD-MM-YYYY HH:mm')}}</div>
                        <div class="author">{{item.author.name}}</div>
                        <div class="deleteComment" v-if="$store.state.isAdmin()" @click="deleteToDo(item)">
                            <button type="button" class="btn btn-mini btn-danger">x</button>
                        </div>
                    </div>
                    <div class="bottom">
                        <to-do-text :toDo="item"></to-do-text>
                        <!-- <div>{{item.text}}</div> -->
                        <template v-if="item.attachments.length > 0">
                            <div v-for="(x, i) in item.attachments" :key="i" class="fs-12">
                                <a class="text-orange" :href="x.publicUrl" target="_blank">
                                    {{x.fileName}}
                                </a>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import Component, { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class toDoContainer extends Vue {

    @Prop() todos: OM.ToDo[];

    deleteToDo(item: OM.Comment){
        this.$emit('deleteToDo', item);
    }

}

</script>

<style scoped>

.hidden {
    left: 0;
}


</style>