<template>
    <div class="fs-22 text-center mb-2">
        <localized-text localizedKey="Lista della spesa" text="Lista della spesa"></localized-text>
    </div>
    <op-table class="table table-striped" :items="items" v-slot:default="opData">
        <thead>
            <tr>
                <td filter="alimento" sort="alimento"><localized-text localizedKey="Alimento" text="Alimento"></localized-text></td>
                <td filter="quantita" sort="quantita"><localized-text localizedKey="Quantità" text="Quantità"></localized-text></td>
                <td class="align-bottom text-right" v-if="$store.state.isDoctor() || $store.state.isAdmin()">
                    <button type="button" class="btn btn-success" @click="addItem">
                        <localized-text localizedKey="Aggiungi" text="Aggiungi"></localized-text>
                    </button>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr v-if="opData.items.length == 0">
                <td colspan="99">
                    <localized-text localizedKey="Nessun elemento trovato" text="Nessun elemento trovato"></localized-text>
                </td>
            </tr>
            <tr v-for="(item, i) in (opData.items as OM.GroceryItem[])" :key="i">
                <td>
                    <span v-if="readonly" class="form-control">{{item.name}}</span>
                    <input v-else type="text" class="form-control" v-model="item.name" />
                </td>
                <td>
                    <span v-if="readonly" class="form-control">{{item.quantity}}</span>
                    <input v-else type="text" class="form-control" v-model="item.quantity" />
                </td>
                <td class="text-right" v-if="$store.state.isDoctor() || $store.state.isAdmin()">
                    <button v-if="!readonly" type="button" class="btn btn-sm btn-danger" @click="removeItem(i)">
                        <localized-text localizedKey="Rimuovi" text="Rimuovi"></localized-text>
                    </button>
                </td>
            </tr>
        </tbody>
    </op-table>
</template>

<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class spesaItemTable extends Vue {

    @Prop() items: OM.GroceryItem[];
    @Prop() readonly: boolean;

    addItem(){
        let toPush = new OM.GroceryItem();
        this.items.push(toPush);
    }

    removeItem(index: number){
        this.items.splice(index, 1);
    }

}

</script>
<style scoped>
</style>
