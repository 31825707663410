<template>
    <div class="opModal">
        <div class="modalHeader">
            <h4>
                <localized-text localizedKey="Aggiungi account collegato" text="Aggiungi account collegato"></localized-text>
            </h4>
        </div>
        <div class="modalBody">
            <form name="login" @submit.prevent="login()">
                <div class="form-group genericInputGroup">
                    <label>
                        <localized-text localizedKey="Email o username" text="Email o username"></localized-text>
                    </label>
                    <input type="text" class="form-control" placeholder="Email" v-model="vm.email" required>
                </div>
                <div class="form-group genericInputGroup posRel">
                    <label>
                        <localized-text localizedKey="Password" text="Password"></localized-text>
                    </label>
                    <input :type="showPassword ? 'text' : 'password'" class="form-control" 
                        placeholder="Password" v-model="vm.password">
                    <button type="button" class="btn btn-default showPswBtn" @click="showPassword=!showPassword">
                        <localized-text localizedKey="Mostra password" text="Mostra password"></localized-text>
                    </button>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-primary">
                        <localized-text localizedKey="Login" text="Login"></localized-text>
                    </button>
                </div>
                <div v-if="error">
                    <br />
                    <p class="text-danger">{{error}}</p>
                </div>
            </form>
        </div>
        <div class="modalFooter">
            <div class="text-end">
                <button type="button" class="btn btn-primary" @click="$emit('close')">Annulla</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';
import store from '@/store';

@Options({})
export default class addLinkedAccountModal extends Vue {

    vm = { email: '', password: ''};
    showPassword: boolean = false;
    error: string = "";

    login(){
        let loginVm: OM.LoginVm = {
            username: this.vm.email, 
            password: this.vm.password,
            project: null
        }
        BaseUserClient.addLinkedAccount(loginVm)
        .then(x => {
            store.state.user.linkedAccounts.push(x);
            this.$emit('close');
        })
        .catch(err => {
            if(err == 'not_found'){
                this.error = "Dati di login non corretti";
            } else {
                this.error = err;
            }
        });
    }

}

</script>
