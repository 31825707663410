import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/bookings',
        name: 'bookings',
        meta: { sideMenuLabel: "Prenotazioni" },
        component: () => import("./views/bookings.vue")
    },
    {
        path: '/bookingDetail/:identifier',
        name: 'bookingDetail',
        meta: { classes: "" },
        component: () => import("./views/bookingDetail.vue")
    },
];

export default routes;