<template>
    <div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Aggiungi professione" text="Aggiungi professione"></localized-text>
            </label>
            <select class="form-control" v-model="tempProfessione">
                <option v-for="(item, i) in availableProfessioni" :key="i" :value="item">{{item.name}}</option>
            </select>
        </div>
        <div class="mb-2" v-for="(item, i) in localValue">
            <div>{{item.professione && item.professione.name}} - <span class="text-danger pointer" @click="removeLine(i)">
                <localized-text localizedKey="Elimina riga" text="Elimina riga"></localized-text>
            </span></div>
            <div class="d-flex align-items-center">
                <select class="form-control" v-model="item.user">
                    <option v-for="(item, i) in (dottori as OM.NameIdentifier[])" :key="i" :value="item">
                        {{item.name}}    
                    </option>
                </select>
                <button type="button" class="btn btn-danger btn-sm" v-if="item.user" @click="item.user = null">
                    x
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { mixins, Options, Vue } from 'vue-class-component';
import * as VM from '@/viewmodel';
import { BaseUserClient, ProfessionConfigurationClient } from '@/services/Services';
import store from '@/store';
import WithModel from '@/mixins/withModel';

@Options({})
export default class professionistiAssegnati extends mixins(WithModel) {

    declare localValue: OM.ProfessioneUserPair[];

    dottori: OM.NameIdentifier[] = [];
    professioni: OM.NameIdentifier[] = [];
    tempProfessione: OM.NameIdentifier = null;

    created(){
        console.log(this.localValue);
        ProfessionConfigurationClient.getAllAsNameIdentifier()
        .then(x => {
            this.professioni = x;
            // this.professioni.forEach(x => {
            //     if(!this.localValue.some(c => c.professione.identifier == x.identifier)){
            //         this.localValue.push({
            //             professione: x,
            //             user: null
            //         })
            //     }
            // })
        })
        BaseUserClient.getListDottori()
        .then(x => {
            this.dottori = x;
        })
    }

    removeLine(i){
        this.localValue.splice(i, 1);
    }

    get availableProfessioni(){
        return this.professioni.filter(x => !this.localValue.some(c => c.professione && c.professione.identifier == x.identifier))
    }

    @Watch('tempProfessione')
    onTempProfessione(){
        if(this.tempProfessione){
            let toPush = {
                professione: this.tempProfessione,
                user: null
            }  
            this.localValue.push(toPush);
            this.tempProfessione = null;
        }
    }

}

</script>
