<template>
    <div class="d-flex justify-content-between">
        <div class="midTitle">
            <localized-text localizedKey="Misure" text="Misure"></localized-text>
        </div>
        <div v-if="$store.state.isAdmin()">
            <button type="button" class="btn btn-sm btn-primary" @click="addMisura">
                <localized-text localizedKey="Aggiungi misura" text="Aggiungi misura"></localized-text>
            </button>
        </div>
    </div>
    <div class="opTableScroller">
        <table class="opTable table table-striped">
            <thead>
                <tr>
                    <th><localized-text localizedKey="Data creazione" text="Data creazione"></localized-text></th>
                    <th><localized-text localizedKey="Vita" text="Vita"></localized-text></th>
                    <th><localized-text localizedKey="Fianchi" text="Fianchi"></localized-text></th>
                    <th><localized-text localizedKey="Addome" text="Addome"></localized-text></th>
                    <th><localized-text localizedKey="Peso" text="Peso"></localized-text></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in misure" :key="i">
                    <td>
                        <span v-if="!(<any>item).modify" class="form-control">{{$filters.date(item.date, 'DD-MM-YYYY HH:mm')}}</span>
                        <datetime-local v-else class="form-control" v-model="item.date"></datetime-local>
                    </td>
                    <td>
                        <span v-if="!(<any>item).modify" class="form-control">{{item.waist}}</span>
                        <input v-else type="number" step="0.01" class="form-control" v-model="item.waist" />
                    </td>
                    <td>
                        <span v-if="!(<any>item).modify" class="form-control">{{item.flanks}}</span>
                        <input v-else type="number" step="0.01" class="form-control" v-model="item.flanks" />
                    </td>
                    <td>
                        <span v-if="!(<any>item).modify" class="form-control">{{item.abdomen}}</span>
                        <input v-else type="number" step="0.01" class="form-control" v-model="item.abdomen" />
                    </td>
                    <td>
                        <span v-if="!(<any>item).modify" class="form-control">{{item.weight}}</span>
                        <input v-else type="number" step="0.01" class="form-control" v-model="item.weight" />
                    </td>
                    <td v-if="$store.state.isAdmin()">
                        <button v-if="!(<any>item).modify" type="button" class="btn btn-sm btn-primary" @click="(<any>item).modify = true">
                            <localized-text localizedKey="Modifica" text="Modifica"></localized-text>
                        </button>
                        <button v-else type="button" class="btn btn-sm btn-primary" @click="saveMisura(item)">
                            <localized-text localizedKey="Salva" text="Salva"></localized-text>
                        </button>
                        <button type="button" class="btn btn-sm btn-danger" v-if="$store.state.isAdmin()" @click="removeMisura(item, i)">
                            <localized-text localizedKey="Rimuovi" text="Rimuovi"></localized-text>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">

import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewmodel';
import { MeasureClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class misureTable extends Vue {
 
    @Prop() user: OM.BaseUser;

    misure: OM.Measure[] = [];

    created(){
        this.init();
    }

    init(){
        MeasureClient.getMisureOfUser(this.user.identifier).then(x => this.misure = x);
    }

    saveMisura(item: OM.Measure){
        MeasureClient.save(item)
        .then(x => {
            delete (<any>item).modify;
        });
    }

    addMisura(){
        let toSave = new OM.Measure();
        toSave.customer = {
            identifier: this.user.identifier,
            name: this.user.personalData.completeName
        };
        toSave.date = new Date().toISOString();
        MeasureClient.save(toSave)
        .then(x => {
            this.init();
        })
    }
    
    removeMisura(item: OM.Measure, i: number){
        if(!confirm("Confermi?"))
            return;

        MeasureClient.deleteById(item.identifier)
        .then(x => {
            this.misure.splice(i, 1);
        });
    }

}

</script>
