import { RouteRecordRaw } from 'vue-router';
import Faqs from './faqNodesConfigurator.vue';

let routes: RouteRecordRaw[] = [
    {
        path: '/faq',
        component: () => import("./views/faqNodesConfigurator.vue"),
        meta: { sideMenuLabel: "Faq" },
    },
];

export default routes;