<template>
    <div v-if="provvigioniConfig.length > 0">
        <div class="form-group">
            <label>
                <localized-text localizedKey="Qualifica provvigioni" text="Qualifica provvigioni"></localized-text>
            </label>
            <select class="form-control" v-model="selectedProvvigioni" @change="onSelectedProvvigioniChange">
                <option v-for="(item, i) in provvigioniConfig" :key="i" :value="item">
                    {{item.qualificationCode}}
                </option>
            </select>
        </div>
        <div class="scrollListContainer">
            <localized-text localizedKey="Storico qualifiche" text="Storico qualifiche"></localized-text>
            <div class="scrollListItem d-flex justify-content-start" v-for="(item, i) in localValue.qualificationHistory" :key="i">
                <div class="text-cyan">{{$filters.date(item.date, "YYYY-MM-DD HH:mm")}}</div> 
                <div class="ms-2">
                    {{item.value.from.qualificationCode}} -> {{item.value.to.qualificationCode}}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model'
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel'
import { QualificationConfigClient } from '@/services/Services';

@Options({})
export default class qualification extends mixins(WithModel) {

    selectedProvvigioni: OM.QualificationConfig = null;
    provvigioniConfig: OM.QualificationConfig[] = [];

    declare localValue: OM.BaseUser;

    created(){
        QualificationConfigClient.getQualifiche()
        .then(x => {
            this.provvigioniConfig = x;
            if(!this.localValue){
                this.localValue.qualification = new OM.Qualification();
            }
            if(this.localValue){
                this.selectedProvvigioni = this.provvigioniConfig.find(x => x.qualificationCode == this.localValue.qualification.qualificationCode);
            }
        })
    }

    onSelectedProvvigioniChange(){
        this.localValue.qualification.qualificationCode = this.selectedProvvigioni.qualificationCode;
    }


}
</script>
<style scoped>
</style>
