import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_telephone = _resolveComponent("telephone")!
  const _component_op_address = _resolveComponent("op-address")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Nome di contatto",
          text: "Nome di contatto"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.contactName) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.contactName]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Email",
          text: "Email"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.email) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.email]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_telephone, {
        modelValue: _ctx.localValue.telephone,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue.telephone) = $event)),
        label: _ctx.$localizationService.GetLocalizedValue('Telefono', 'Telefono')
      }, null, 8, ["modelValue", "label"])
    ]),
    _createVNode(_component_op_address, {
      class: "mt-2",
      modelValue: _ctx.localValue.address,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue.address) = $event)),
      label: _ctx.$localizationService.GetLocalizedValue('Indirizzo di contatto e spedizione', 'Indirizzo di contatto e spedizione')
    }, null, 8, ["modelValue", "label"])
  ]))
}