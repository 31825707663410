<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Nome (fatturazione)" text="Nome (fatturazione)"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.name" />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Cognome (fatturazione)" text="Cognome (fatturazione)"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.surname" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Codice Fiscale" text="Codice Fiscale"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.fiscalCode" />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Partita iva" text="Partita iva"></localized-text>
                    </label>
                    <div>
                        <toggler v-model="localValue.hasVat"></toggler>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="localValue.hasVat">
            <div class="row">
                <div class="form-group col-md-4">
                    <label>
                        <localized-text localizedKey="Numero partita iva" text="Numero partita iva"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.vatNumber" />
                </div>
                <div class="form-group col-md-4">
                    <label>
                        <localized-text localizedKey="Codice destinatario" text="Codice destinatario"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.codiceDestinatario" />
                </div>
                <div class="form-group col-md-4">
                    <label>
                        <localized-text localizedKey="Pec" text="Pec"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.pec" />
                </div>
            </div>
        </template>
        <div class="row">
            <div class="form-group col-md-4">
                <label>
                    <localized-text localizedKey="Data di nascita" text="Data di nascita"></localized-text>
                </label>
                <op-date class="form-control" v-model="localValue.dataNascita"></op-date>
            </div>
            <div class="form-group col-md-8">
                <label>
                    <localized-text localizedKey="Luogo nascita" text="Luogo nascita"></localized-text>
                </label>
                <input type="text" class="form-control" v-model="localValue.luogoNascita" />
            </div>
        </div>
        <op-address v-model="localValue.fiscalAddress" :label="$localizationService.GetLocalizedValue('Indirizzo di fatturazione', 'Indirizzo di fatturazione')"></op-address>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model'
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';
import * as utils from '@/utils';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class fiscalData extends mixins(WithModel) {

    @Prop() user: OM.BaseUser;

    declare localValue: OM.FiscalData;
    codFiscError: boolean = false;
    codFiscNonCoerente: boolean = false;
    
    checkValidityCodiceFiscale(){
        this.$store.state.formErrors.codiceFiscale = false;
        this.codFiscError = false;
        try {
            let inverso = utils.CodFiscInverso(this.localValue.fiscalCode);
            this.localValue.dataNascita = new Date(inverso.year, inverso.month - 1, inverso.day).toISOString();
            this.user.personalData.sesso = inverso.gender;
            this.localValue.luogoNascita = inverso.birthplace;
            
            let calculated = utils.CalcolaCodFisc(this.user.personalData.name, this.user.personalData.surname, (<any>this.user.personalData.sesso), inverso.day, inverso.month, inverso.year, inverso.birthplace, inverso.birthplaceProvincia);
            if(calculated.cf == inverso.cf){
                this.$store.state.formErrors.codiceFiscale = false;
                this.codFiscNonCoerente = false;
            } else {
                this.$store.state.formErrors.codiceFiscale = true;
                this.codFiscNonCoerente = true;
            }
        } catch(ex){
            this.codFiscError = true;
            this.$store.state.formErrors.codiceFiscale = true;
        }
    }

    alertLuogoDataNascita(){
        ModalServices.alertModal(
            '',
            LocalizationServices.GetLocalizedValue('Luogo e data di nascita vengono compilati tramite il codice fiscale', 'Luogo e data di nascita vengono compilati tramite il codice fiscale')
        )
    }

}
</script>
<style scoped>
</style>
