import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/paymentRequests',
        name: 'paymentRequests',
        meta: { sideMenuLabel: "Richieste pagamento" },
        component: () => import("./views/paymentRequests.vue")
    }
];

export default routes;