<template>
    <div class="form-group">
        <label>
            {{label}}
        </label>
        <button class="btn btn-sm btn-success ms-2" @click="add">
            +
        </button>
        <div class="d-flex align-items-center mb-2" v-for="(item, i) in localValue" :key="i">
            <div>
                {{i + 1}}. 
            </div>
            <input class="mr-2 form-control" type="text" v-model="localValue[i]" />
            <div class="d-flex">
                <button class="btn btn-sm btn-danger ms-2" @click="remove(i)">
                    -
                </button>
                <button class="btn btn-sm btn-link ms-2" @click="moveUp(i)" :disabled="i == 0">
                    <img src="@/img/common/chevron_up.svg" />
                </button>
                <button class="btn btn-sm btn-link ms-2" @click="moveDown(i)" :disabled="i == localValue.length - 1">
                    <img src="@/img/common/chevron_down.svg" />
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import { JsonClone } from '@/utils';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class listFiller extends mixins(WithModel) {

    @Prop() label: string;

    declare localValue: string[];

    add(){
        this.localValue.push("");
    }
    remove(index){
        this.localValue.splice(index, 1);
    }

    moveUp(index: number){
        if(index <= 0)
            return;

        let toMove = JsonClone(this.localValue[index]);
        this.localValue.splice(index, 1);
        this.localValue.splice(index - 1, 0, toMove);
    }

    moveDown(index: number){
        if(index >= this.localValue.length - 1)
            return;
        

        let toMove = JsonClone(this.localValue[index]);
        this.localValue.splice(index, 1);
        this.localValue.splice(index + 1, 0, toMove);
    }

}

</script>