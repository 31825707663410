<template>
    <td>
        <localized-text localizedKey="Nuovo messaggio da <b>{{nomeCliente}}</b> nella sua prenotazione"
            text="Nuovo messaggio da <b>{{nomeCliente}}</b> nella sua prenotazione"
            :object="{ nomeCliente: item.cliente.name }">
        </localized-text>
    </td>
    <td class="text-end">
        <router-link class="btn btn-link" :to="'/bookingDetail/' + item.prenotazioneIdentifier">
            <localized-text localizedKey="Vedi prenotazione" text="Vedi prenotazione"></localized-text>
        </router-link>
    </td>
</template>

<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BackendNotificationClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class messaggioInPrenotazione extends Vue {

    @Prop() item: OM.MessaggioInPrenotazione;

}

</script>
