<template>
    <div>
        <div class="text-center">
            <button type="button" class="btn btn-primary" @click="pushContent">
                <localized-text localizedKey="Aggiungi nuovo contenuto" text="Aggiungi nuovo contenuto"></localized-text>
            </button>
        </div>
        <div class="row">
            <div class="col-sm-3 mt-3" v-for="(item, i) in contents" :key="i">
                <div class="form-group courseContentItem">
                    <div class="text-center">
                        <button type="button" class="btn btn-sm btn-danger" @click="removeContent(i)">
                            x
                        </button>
                    </div>
                    <div class="">
                        <button type="button" class="btn btn-sm btn-primary" @click="moveSx(i)">
                            <localized-text localizedKey="Sposta a sx" text="Sposta a sx"></localized-text>
                        </button>
                        <button type="button" class="btn btn-sm btn-primary" @click="moveDx(i)">
                            <localized-text localizedKey="Sposta a dx" text="Sposta a dx"></localized-text>
                        </button>
                    </div>
                    <label>{{i + 1}}. {{item.title}}</label>
                    <div>
                        <button type="button" class="btn btn-primary" @click="openContentModal(item)">
                            <localized-text localizedKey="Apri gestione video" text="Apri gestione video"></localized-text>
                        </button>
                    </div>
                    <div>
                        <span class="ms-2" v-if="item.url"><localized-text localizedKey="Video caricato" text="Video caricato"></localized-text><i class="fa fa-check text-success"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import Toggler from '@/components/toggler.vue';
import CourseContentModal from './courseContentModal.vue';

@Options({})
export default class CourseContentEditor extends Vue {
 
    @Prop() contents: OM.CourseContent[];

    openContentModal(item: OM.CourseContent){
        this.$opModal.show(CourseContentModal, {
            content: item
        })
    }

    pushContent(){
        this.contents.push(new OM.CourseContent());
    }

    removeContent(i: number){
        if(this.contents[i].url){
            if(!confirm("Confermi rimozione?"))
                return;
        }
        this.contents.splice(i, 1);
    }

    moveSx(i){
        this.array_move(this.contents, i, i - 1);
    }

    moveDx(i){
        this.array_move(this.contents, i, i + 1);
    }

    array_move(arr: any[], old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            return;
        }
        let old = arr.splice(old_index, 1);
        arr.splice(new_index, 0, old[0]);
    };
}

</script>
