<template>
    <input ref="input" />
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { mixins, Options, Vue } from "vue-class-component";
import WithModel from "@/mixins/withModel";

@Options({})
export default class OpDate extends mixins(WithModel) {

    @Prop({
        required: false,
        default: 'd/m/Y'
    }) format: string;
    @Prop() initialvalue: any;
    @Prop() minDate: any;

    fp: any = null;
    mounted(){
        var date = new Date().toISOString();
        if(this.modelValue){
            date = new Date(this.modelValue).toISOString();
        } else {
            if(this.initialvalue !== undefined){
                date = this.initialvalue;
            } else {
                this.emitModel(new Date(date).toISOString());
            }
        }

        this.fp = flatpickr(this.$refs.input as HTMLInputElement, {
            defaultDate: date,
            dateFormat: this.format,
            allowInput: true,
            onChange: (val) => {
                this.emitModel(new Date(this.validateDate(val[0])).toISOString());
            },
            minDate: this.minDate
        });
    }

    @Watch('value')
    onValueChange(next, prev){
        var data = null;
        if(next !== null){
            data = new Date(next).toISOString();
        }
        this.fp.setDate(data, false);
    }

    validateDate(inputDate: Date){
        if(this.isValidDate(inputDate)){
            let years = inputDate.getFullYear().toString();
            while(years.length < 4){
                years = "0" + years;
            } 
            let months = (inputDate.getMonth() + 1).toString();
            if(months.length < 2) months = "0" + months;
            let days = inputDate.getDate().toString();
            if(days.length < 2) days = "0" + days;
            return years +  "-" + months + "-" + days;
        }
    }
    
    isValidDate(d: any) {
        return d instanceof Date && !isNaN(d as any);
    }

}
</script>

<style scoped>

</style>