import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/mailSentLog',
        name: 'mailSentLog',
        meta: { sideMenuLabel: "Log email" },
        component: () => import("./views/mailSentLog.vue")
    },
];

export default routes;