import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/dictionary',
        name: 'dictionary',
        meta: { sideMenuLabel: "Dizionario" },
        component: () => import("./views/dictionary.vue")
    },
];

export default routes;