<template>
    <div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Aggiungi progetto" text="Aggiungi progetto"></localized-text>
            </label>
            <select class="form-control" v-model="projectToAdd">
                <option v-for="(item, i) in availableProjects" :key="i">{{item}}</option>
            </select>
        </div>
        <div class="form-group" v-for="(value, key, i) in localValue" :key="i">
            <label>
                {{key}}
            </label>
            <select class="form-control" v-model="localValue[key]">
                <option v-for="(item, j) in countriesForProject[key]" :key="j">{{item}}</option>
            </select>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model'
import * as CONST from '@/const'
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { ProjectConfigClient } from '@/services/Services';

@Options({})
export default class projectCountryPairs extends mixins(WithModel) {

    declare localValue: { [key: string]: string };

    projects = CONST.ProjectNames.GetAll();
    countriesForProject: { [key: string]: OM.CountryConfig[] } = {};

    projectToAdd: string = "";
    @Watch('projectToAdd')
    onProjectToAddChange(){
        if(this.projectToAdd){
            this.localValue[this.projectToAdd] = "";
        }
    }

    get availableProjects(){
        return this.projects.filter(x => !this.localValue[x]);
    }

    removeKey(key: string){
        delete this.localValue[key];
    }

    loadCountries(){
        this.countriesForProject = {};
        for(let p in this.localValue){
            ProjectConfigClient.getEnabledCountries(p)
            .then(x => {
                this.countriesForProject[p] = x;
            })
        }
    }

}
</script>
<style scoped>
</style>
