<template>
    <div>
        <div class="boxTitle" v-if="label">{{label}}</div>
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group">
                    <label v-if="label">
                        <localized-text localizedKey="Prefisso" text="Prefisso"></localized-text>
                    </label>
                    <span v-if="readonly" class="form-control">{{localValue.prefix}}</span>
                    <select v-else v-model="localValue.prefix" class="form-control">
                        <option value="">
                            <localized-text localizedKey="Seleziona" text="Seleziona"></localized-text>
                        </option>
                        <option v-for="(item, i) in prefixList" :key="i" :value="'+' + item.prefix">{{item.country}} + {{item.prefix}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-9">
                <label v-if="label">{{label}}</label>
                <input type="text" placeholder="Numero" class="form-control" v-model="localValue.number" :readonly="readonly" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model'
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { ProjectConfigClient } from '@/services/Services';

@Options({})
export default class bankAccountData extends mixins(WithModel) {

    @Prop() label: string;
    @Prop() readonly: boolean;

    declare localValue: OM.Telephone;

    prefixList: OM.PrefixCountry[] = [];

    created(){
        ProjectConfigClient.getPhonePrefixes()
        .then(x => {
            this.prefixList = x;
        })
    }

}
</script>
<style scoped>
</style>
