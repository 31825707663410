<template>
    <div class="opModal">
        <h4>
            <localized-text localizedKey="Risposte questionario" text="Risposte questionario"></localized-text>
        </h4>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Professione" text="Professione"></localized-text>
            </label>
            <select class="form-control" v-model="selectedProfessione">
                <option v-for="(item, i) in professioni" :value="item">{{item.name}}</option>
            </select>
        </div>
        <template v-if="risposte">
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Cliente" text="Cliente"></localized-text>
                </label>
                <span class="form-control">{{risposte.customer.name}}</span>
            </div>
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Completato" text="Completato"></localized-text>
                </label>
                <span class="form-control">
                    <localized-text localizedKey="Si" text="Si" v-if="risposte.isCompleted"></localized-text>
                    <localized-text localizedKey="No" text="No" v-else></localized-text>
                </span>
            </div>
            <op-table class="table table-striped" :items="risposte.answerValues" v-slot:default="opData" :filtersFn="filtersFn">
                <thead>
                    <tr>
                        <td filter="domanda.name" sort="domanda.name"><localized-text localizedKey="Domanda" text="Domanda"></localized-text></td>
                        <td filter="answerFilter"><localized-text localizedKey="Risposte" text="Risposte"></localized-text></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="opData.items.length == 0">
                        <td colspan="99">
                            <localized-text localizedKey="Nessun elemento trovato" text="Nessun elemento trovato"></localized-text>
                        </td>
                    </tr>
                    <tr v-for="(item, i) in (opData.items as OM.AnswerValue[])" :key="i">
                        <td>{{item.questionText.values[$store.state.selectedLanguage]}}</td>
                        <td>
                            <template v-if="item.selectAnswer.length > 0">
                                {{item.selectAnswer.map(x => x.values[$store.state.selectedLanguage]).join(' - ')}}
                            </template>
                            <template v-else-if="item.questionText">
                                {{item.questionText}}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </op-table>
        </template>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { ProfessionConfigurationClient, SurveyClient } from '@/services/Services';

@Options({})
export default class risposteQuestionarioModal extends Vue {
 
    @Prop() userIdentifier: string;
    @Prop() isInterno: boolean;

    risposte: OM.SurveyAnswers = null;
    selectedProfessione: OM.NameIdentifier = null
    professioni: OM.NameIdentifier[] = [];

    filtersFn = {
        answerFilter: (item: OM.AnswerValue, input) => {
            return item.selectAnswer.some(x => x.values[this.$store.state.selectedLanguage].toLowerCase().indexOf(input.toLowerCase()) > -1) ||
            item.textAnswer.indexOf(input.toLowerCase()) > -1; 
        }
    }

    created(){
        ProfessionConfigurationClient.getAllAsNameIdentifier()
        .then(x => {
            this.professioni = x;
        })
    }

    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione){
            this.getQuestionario();
        }
    }

    getQuestionario(){
        this.risposte = null;
        let prom;
        // if(this.isInterno){
        //     prom = SurveyClient.getQuestionarioInternoOfUser(this.userIdentifier, this.selectedProfessione.identifier);
        // } else {
        //     prom = SurveyClient.getQuestionarioOfUser(this.userIdentifier, this.selectedProfessione.identifier);
        // }
        prom
        .then(x => {
            this.risposte = x;
        })
    }

}

</script>
