import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/backendRoleList',
        name: 'backendRoleList',
        meta: { sideMenuLabel: "Ruoli backoffice" },
        component: () => import("./views/backendRoleList.vue")
    },
    {
        path: '/backendRoleDetail/:identifier',
        name: 'backendRoleDetail',
        component: () => import("./views/backendRoleDetail.vue")
    },
];

export default routes;