import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/mailEvents',
        name: 'mailEvents',
        meta: { sideMenuLabel: "Mail Events" },
        component: () => import("./views/mailEvents.vue")
    },
    {
        path: '/mailEvents/:eventName/templates',
        name: 'mailEventTemplates',
        component: () => import("./views/interpolationTemplates.vue")
    },
    {
        path: '/interpolationTemplates',
        name: 'interpolationTemplates',
        meta: { sideMenuLabel: "Template" },
        component: () => import("./views/interpolationTemplates.vue")
    },
    {
        path: '/interpolationTemplates/:identifier',
        name: 'interpolationTemplateDetail',
        component: () => import("./views/interpolationTemplateDetail.vue")
    },
];

export default routes;