<template>
    <table class="table table-striped">
        <thead>
            <tr>
                <td>
                    <localized-text localizedKey="Importo lordo" text="Importo lordo"></localized-text>
                </td>
                <td>
                    <localized-text localizedKey="% iva" text="% iva"></localized-text> (0 - 100)
                </td>
                <td v-if="!hidePoints">
                    <localized-text localizedKey="Punti" text="Punti"></localized-text>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <input type="number" class="form-control" v-model="localValue.grossAmount" />
                </td>
                <td>
                    <input type="number" class="form-control" v-model="localValue.vatPerc" /><br/>
                    {{$filters.currency(localValue.grossAmount * localValue.vatPerc / 100)}}
                </td>
                <td v-if="!hidePoints">
                    <input type="number" class="form-control" v-model="localValue.points" />
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import * as OM from '@/model';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class priceConfig extends mixins(WithModel) {

    @Prop() hidePoints: boolean

    declare localValue: OM.PriceConfig;
}

</script>
