<template>
    <div>
        <div class="opModal">
            <div class="modalHeader">
                <h4>
                    <localized-text localizedKey="Crea piano alimentare dalle settimane di default" text="Crea piano alimentare dalle settimane di default"></localized-text>
                </h4>
            </div>
            <div class="modalBody">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Data di inizio" text="Data di inizio"></localized-text>
                    </label>
                    <op-date class="form-control" v-model="startDate" @update:modelValue="getWeekNumber"></op-date>
                    <b><localized-text localizedKey="Numero settimana" text="Numero settimana"></localized-text> {{weekNumber}}</b>
                </div>
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Tipo di dieta" text="Tipo di dieta"></localized-text>
                    </label>
                    <select class="form-control" v-model="selectedDiet">
                        <option v-for="(item, i) in dietTypes" :key="i" :value="item">{{item}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Quante settimane?" text="Quante settimane?"></localized-text>
                    </label>
                    <input type="number" v-model="howManyWeeks" class="form-control" />
                </div>
            </div>
            <div class="modalFooter text-end">
                <button type="button" class="btn btn-link text-danger" @click="$emit('close')">
                    <localized-text localizedKey="Annulla" text="Annulla"></localized-text>
                </button>
                <button type="button" class="btn btn-primary" @click="save" :disabled="!canSave">
                    <localized-text localizedKey="Salva" text="Salva"></localized-text>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { FoodPlanClient, ProjectConfigClient } from '@/services/Services';
import moment from 'moment';

@Options({})
export default class newFoodPlanFromDefaultsModal extends Vue {

    @Prop() customer: OM.BaseUser;
    @Prop() voucher: OM.FoodPlanVoucher;

    startDate: string = "";
    howManyWeeks: number = null;
    selectedDiet: string = "";
    dietTypes: string[] = [];
    weekNumber: number = null;

    created(){
        this.selectedDiet = this.customer.projectStatusesPair[this.voucher.project].dietType;
        ProjectConfigClient.getDietTypes(this.voucher.project)
        .then(x => {
            this.dietTypes = x;
        })
    }

    getWeekNumber(){
        this.weekNumber = moment(this.startDate).get('week');
    }

    get canSave(){
        return this.selectedDiet && this.startDate;
    }

    save(){
        FoodPlanClient.createPianoAlimentareFromDefaults(this.customer.identifier, this.voucher.identifier, this.selectedDiet, this.weekNumber, this.howManyWeeks)
        .then(x => {
            this.$router.push('/foodPlans/' + x);
        })
    }

}

</script>

<style scoped>


</style>