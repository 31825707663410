import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "modalHeader" }
const _hoisted_3 = { class: "modalBody" }
const _hoisted_4 = { class: "form-group mt-4 mb-0" }
const _hoisted_5 = { class: "list scrollListContainer" }
const _hoisted_6 = {
  key: 0,
  class: "listItem"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "text-green fs-4 fw-semibold" }
const _hoisted_9 = { class: "mt-4" }
const _hoisted_10 = { class: "fw-bold" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "d-flex align-items-center mt-2" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "modalFooter text-end" }
const _hoisted_16 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_calendar = _resolveComponent("calendar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Nuovo appuntamento",
            text: "Nuovo appuntamento"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Cerca per nome/cognome",
              text: "Cerca per nome/cognome"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event))
          }, null, 512), [
            [_vModelText, _ctx.searchText]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.clientiList.length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Scrivi per cercare tra i tuoi clienti",
                  text: "Scrivi per cercare tra i tuoi clienti"
                })
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.clientiList as OM.NameIdentifier[]), (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["scrollListItem pointer", { 'selected': _ctx.vm.cliente == item }]),
              key: i,
              onClick: ($event: any) => (_ctx.selectCliente(item))
            }, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.name), 1)
            ], 10, _hoisted_7))
          }), 128))
        ]),
        (_ctx.vm.cliente && _ctx.tickets.length == 0)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 0,
              localizedKey: "Questo cliente non ha voucher prenotazione disponibili",
              text: "Questo cliente non ha voucher prenotazione disponibili"
            }))
          : (_ctx.tickets.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Seleziona la data",
                      text: "Seleziona la data"
                    })
                  ])
                ]),
                _createVNode(_component_calendar, {
                  class: "month smallCalendar mt-3",
                  "show-mode-changer": false,
                  "scroll-to-hour": false,
                  "start-mode": 'month'
                }, {
                  default: _withCtx((data) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.days, (day, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["calendarDay pointer", [day.classes, { selected: day.date == _ctx.vm.date }]]),
                        key: i,
                        onClick: ($event: any) => (_ctx.selectDate(day))
                      }, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$filters.date(day.date, 'DD')), 1)
                      ], 10, _hoisted_11))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Ora inizio",
                        text: "Ora inizio"
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "time",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vm.fasciaOraria.from) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.vm.fasciaOraria.from]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Ora fine",
                        text: "Ora fine"
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "time",
                      class: "form-control",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vm.fasciaOraria.to) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.vm.fasciaOraria.to]
                    ])
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
          disabled: !_ctx.canSave
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Salva",
            text: "Salva"
          })
        ], 8, _hoisted_16),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-link text-danger",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Annulla",
            text: "Annulla"
          })
        ])
      ])
    ])
  ]))
}