import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/projectConfigs',
        name: 'projectConfigs',
        meta: { sideMenuLabel: "Configurazione progetti" },
        component: () => import("./views/projectConfigs.vue")
    },
    {
        path: '/projectConfigDetail/:identifier',
        name: 'projectConfigDetail',
        component: () => import("./views/projectConfigDetail.vue")
    },
];

export default routes;