<template>
    <div class="box" v-if="user">
        <div class="d-flex justify-content-between align-items-center">
            <div class="midTitle">
                <localized-text localizedKey="Piani alimentari" text="Piani alimentari"></localized-text>
            </div>
            <button type="button" class="btn btn-primary ml-2" :disabled="foodPlanVouchers.length == 0" @click="newPianoAlimentareFromDefaults" v-if="isDottoreOfUser || $store.state.isAdmin()">
                <localized-text localizedKey="Nuovo piano da default" text="Nuovo piano da default"></localized-text><br/>
                (<localized-text localizedKey="Ticket rimasti: {{ticket}}" text="Ticket rimasti: {{ticket}}" :object="{ ticket: foodPlanVouchers.length }"></localized-text>)
            </button>
            <button type="button" class="btn btn-primary ml-2" :disabled="foodPlanVouchers.length == 0" @click="newPianoAlimentare" v-if="isDottoreOfUser || $store.state.isAdmin()">
                <localized-text localizedKey="Nuovo piano alimentare" text="Nuovo piano alimentare"></localized-text><br/>
                (<localized-text localizedKey="Ticket rimasti: {{ticket}}" text="Ticket rimasti: {{ticket}}" :object="{ ticket: foodPlanVouchers.length }"></localized-text>)
            </button>
            <router-link class="btn btn-primary ml-2" :to="'/foodPlanVouchers/' + user.identifier" v-if="$store.state.isAdmin()">
                <localized-text localizedKey="Vedi voucher alimentari" text="Vedi voucher alimentari"></localized-text>
            </router-link>
        </div>
        <div class="opTableScroller">
            <table class="opTable table table-striped">
                <thead>
                    <tr>
                        <td><localized-text localizedKey="Data creazione" text="Data creazione"></localized-text></td>
                        <td><localized-text localizedKey="Data inizio" text="Data inizio"></localized-text></td>
                        <td><localized-text localizedKey="Data fine" text="Data fine"></localized-text></td>
                        <td><localized-text localizedKey="Settimane" text="Settimane"></localized-text></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in foodPlans" :key="i">
                        <td>{{$filters.date(item.createdOn, 'DD-MM-YYYY HH:mm')}}</td>
                        <td>{{$filters.date(item.dateStart, 'DD-MM-YYYY HH:mm')}}</td>
                        <td>{{$filters.date(item.dateEnd, 'DD-MM-YYYY HH:mm')}}</td>
                        <td>{{item.planWeeks.length}}</td>
                        <td>
                            <router-link :to="'/foodPlans/' + item.identifier" class="btn btn-sm btn-primary">
                                <localized-text localizedKey="Dettaglio" text="Dettaglio"></localized-text>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import { BookingClient, BookingVoucherClient, FoodPlanClient, FoodPlanVoucherClient, MeasureClient, PurchaseClient, SignedDocumentsClient, SurveyAnswersClient } from '@/services/Services';
import { LocalizationServices } from '@/services/LocalizationServices';
import { ModalServices } from '@/services/ModalServices';
import { Prop, Watch } from 'vue-property-decorator';
import newFoodPlanFromDefaultsModal from './newFoodPlanFromDefaultsModal.vue';
import store from '@/store';

@Options({})
export default class customerFoodPlans extends Vue {
 
    @Prop() user: OM.Lead;

    projects: string[] = [];
    foodPlanVouchers: OM.FoodPlanVoucher[] = [];
    foodPlans: OM.FoodPlan[] = [];
    purchases: OM.Purchase[] = [];
    docs: OM.BaseSignedDocument[] = [];
    surveyAnswers: OM.SurveyAnswers[] = [];

    created(){
        this.projects = Object.keys(this.user.projectCountryPairs);
        this.init();
    }

    init(){
        this.foodPlanVouchers = [];
        this.foodPlans = [];
        if(!this.$store.state.selectedProject || this.projects.indexOf(this.$store.state.selectedProject) == -1)
            return;

        FoodPlanVoucherClient.getAvailableFoodPlanVoucher(this.$store.state.selectedProject, this.user.identifier).then(x => this.foodPlanVouchers = x)
        FoodPlanClient.getByClienteIdentifier(this.user.identifier).then(x => this.foodPlans = x)
    }

    @Watch('$store.state.selectedProject')
    projectChange(){
        if(this.$store.state.selectedProject){
            this.init();
        }
    }

    newPianoAlimentareFromDefaults(){
        this.$opModal.show(newFoodPlanFromDefaultsModal, {
            customer: this.user,
            voucher: this.foodPlanVouchers[0]
        })
    }
    
    newPianoAlimentare(){
        ModalServices.confirmModal(
            LocalizationServices.GetLocalizedValue('Confermi?', 'Confermi?'),
            LocalizationServices.GetLocalizedValue('Scaleremo un ticket', 'Scaleremo un ticket'),
            LocalizationServices.GetLocalizedValue('Si', 'Si'),
            LocalizationServices.GetLocalizedValue('No', 'No'),
            () => {
                let nutrizionista = this.user.professionistiAssegnati.find(x => x.professione.name == 'Nutrizionista');
                FoodPlanClient.createPianoAlimentare(this.user.identifier, nutrizionista.user.identifier, this.foodPlanVouchers[0].identifier)
                .then(x => {
                    this.$router.push('/foodPlans/' + x);
                })
            }
        );
    }

    get isDottoreOfUser(){
        if(!this.user.professionistiAssegnati)
            return false;
        return this.user.professionistiAssegnati.some(x => x.user && x.user.identifier == this.$store.state.user.identifier);
    }

    
}

</script>
