import * as VM from "./viewmodel";

function getModules(): VM.ModuleConfig[] {
    const context = require.context('./views', true, /index\.ts$/);
    let ris: VM.ModuleConfig[] = [];

    context.keys().forEach((key: string) => {
        let toPush = new VM.ModuleConfig();
        if(context(key).default.moduleName){
            toPush.name = context(key).default.moduleName;
            toPush.group = context(key).default.group;
            toPush.routes = context(key).default.routes.filter(x => x.meta && !!x.meta.sideMenuLabel);
            ris.push(toPush);
        }
    });

    return ris;
}

export default getModules;
