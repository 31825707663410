import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-20e10898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opColorPicker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_color_picker = _resolveComponent("color-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_color_picker, {
      disableHistory: true,
      isWidget: true,
      pureColor: _ctx.colorBridge,
      "onUpdate:pureColor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.colorBridge) = $event)),
      "picker-type": "chrome"
    }, null, 8, ["pureColor"])
  ]))
}