<template>
    <div class="comment">
        <!-- <div class="d-flex">
            <a class="commentImage" v-for="(item, i) in comment.attachmentUrl" :key="i" :href="item" target="_blank">
                <img :src="item" />
            </a>
        </div> -->
        <!-- <div class="commentDate">
            <span v-if="!comment.startDate">{{$filters.date(comment.createdOn, 'YYYY-MM-DD HH:mm')}}</span>
            <b v-else> ----- {{$filters.date(comment.startDate, 'YYYY-MM-DD HH:mm')}}</b>
        </div> -->
        <div class="commentBody">
            <div class="commentAuthor">
                {{comment.author.name}}
            </div>
            <comment-text :comment="comment"></comment-text>
            <div class="commentRemover" @click.stop="removeComment" v-if="canRemove">
                x
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import store from '@/store';

@Options({})
export default class Comment extends Vue {

    @Prop() comment: OM.Comment;
    @Prop() removable: boolean;
    @Prop() alwaysRemovable: boolean;

    get canRemove(){
        return this.alwaysRemovable || this.removable && (this.comment.author.identifier == store.state.loginData.userIdentifier || store.state.isAdmin())
    }

    removeComment(){
        this.$emit('remove', this.comment);
    }

}

</script>

<style scoped>
</style>