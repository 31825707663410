<template>
    <div>
        <bank-account-data v-model="localValue.bankAccountData"></bank-account-data>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Email Paypal" text="Email Paypal"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.paypalEmail" />
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Numero revolut" text="Numero revolut"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.revolutNumber" />
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Modalità pagamento" text="Modalità pagamento"></localized-text>
                    </label>
                    <select class="form-control" v-model="localValue.modalitaPagamento">
                        <option value=""></option>
                        <option v-for="(item, i) in modalitaPagamentos" :key="i" :value="item">{{item}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import * as CONST from '@/const';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class userPaymentData extends mixins(WithModel) {

    declare localValue: OM.UserPaymentData;

    modalitaPagamentos: string[] = CONST.ModalitaPagamentoUser.GetAll();

}
</script>
<style scoped>
</style>
