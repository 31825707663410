<template>
    <div class="text">
        <span v-html="finalText"></span>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import linkifyHtml from 'linkify-html';

@Options({})
export default class CommentText extends Vue {

    @Prop() comment: OM.Comment;

    finalText: string = "";

    created(){
        if(this.comment.text){
            this.finalText = linkifyHtml(this.comment.text, {
                target: '_blank'
            });
        }
    }

    @Watch('comment.text')
    onTextChange(next, prev){
        if(this.comment.text){
            this.finalText = linkifyHtml(this.comment.text);
        }
    }

}

</script>

<style scoped>
</style>