import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/helpTickets',
        name: 'helpTickets',
        meta: { sideMenuLabel: "Ticket assistenza" },
        component: () => import("./views/helpTickets.vue")
    },
    {
        path: '/helpTicket/detail/:identifier',
        name: 'helpTicketDetail',
        component: () => import("@/views/chatRoom/views/chatRoomDetail.vue")
    },
    {
        path: '/helpTicket/read/:identifier',
        name: 'helpTicketRead',
        component: () => import("@/views/chatRoom/views/chatRoomDetail.vue")
    },
];

export default routes;