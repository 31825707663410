<template>
    <td>
        <localized-text localizedKey="<b>{{author}}</b> ha lasciato un commento su <b>{{cliente}}</b>" 
            text="<b>{{author}}</b> ha lasciato un commento su <b>{{cliente}}</b>"
            :object="{ author: item.author.name, cliente: item.cliente.name }">
        </localized-text>
    </td>
    <td class="text-end">
        <router-link class="btn btn-link" :to="'/customers/' + item.cliente.identifier">
            <localized-text localizedKey="Vedi scheda" text="Vedi scheda"></localized-text>
        </router-link>
    </td>
</template>

<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BackendNotificationClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class commentoSuCliente extends Vue {

    @Prop() item: OM.CommentoSuCliente;

}

</script>
