<template>
    <div v-if="item">
        <video height="320" width="320" controls playsinline>
            <source :src="item.url" v-if="item.url">
            <source :src="item.url" v-else-if="item.attachment && item.attachment.publicUrl">
        </video>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model'
import { VideoDellaSettimanaClient } from '@/services/Services';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class videoDellaSettimanaViewer extends Vue {

    @Prop() video: OM.NameIdentifier;

    item: OM.VideoDellaSettimana = null;

    created(){
        this.init();
    }

    init(){
        if(!this.video)
            return;

        this.item = null;
        VideoDellaSettimanaClient.getById(this.video.identifier)
        .then(x => {
            this.item = x;
        })
    }

    @Watch('video')
    onVideoChange(next, prev){
        this.init();
    }

}
</script>
<style scoped>
</style>
