import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "form-group col-sm-3" }
const _hoisted_4 = { class: "form-group col-sm-9" }
const _hoisted_5 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Intestatario",
          text: "Intestatario"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.intestatario) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.intestatario]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Nome banca",
            text: "Nome banca"
          })
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.bankName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.localValue.bankName]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Swift",
            text: "Swift"
          })
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue.swift) = $event))
        }, null, 512), [
          [_vModelText, _ctx.localValue.swift]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Iban",
          text: "Iban"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue.iban) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.iban]
      ])
    ])
  ]))
}