<template>
    <div v-if="user">
        <div class="float-end">
            <button class="btn btn-primary" @click="newSurveyAnswers">
                <localized-text localizedKey="Nuovo questionario" text="Nuovo questionario"></localized-text>
            </button>
        </div>
        <div class="midTitle">
            <localized-text localizedKey="Questionari" text="Questionari"></localized-text>
        </div>
        <!-- <div class="form-group" v-if="!project">
            <label>
                <localized-text localizedKey="Progetto" text="Progetto"></localized-text>
            </label>
            <select class="form-control" v-model="selectedProject">
                <option v-for="item in projects" :value="item">
                    {{item}}
                </option>
            </select>
        </div> -->
        <op-table class="table table-striped" :items="surveyAnswers" v-slot:default="opData">
            <thead>
                <tr>
                    <td filter="createdOn" filterType="date" sort="createdOn">
                        <localized-text localizedKey="Data creazione" text="Data creazione"></localized-text>
                    </td>
                    <td filter="profession.name" sort="profession.name">
                        <localized-text localizedKey="Professione" text="Professione"></localized-text>
                    </td>
                    <td filter="doctor.name" sort="doctor.name">
                        <localized-text localizedKey="Dottore" text="Dottore"></localized-text>
                    </td>
                    <td filter="surveyType" sort="surveyType">
                        <localized-text localizedKey="Tipo" text="Tipo"></localized-text>
                    </td>
                    <td filter="isCompleted" filterType="checkbox" sort="isCompleted">
                        <localized-text localizedKey="Completato" text="Completato"></localized-text>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-if="opData.items.length == 0">
                    <td colspan="99">
                        <localized-text localizedKey="Nessun elemento trovato" text="Nessun elemento trovato"></localized-text>
                    </td>
                </tr>
                <tr v-for="(item, i) in (opData.items as OM.SurveyAnswers[])" :key="i">
                    <td>{{$filters.date(item.createdOn, "YYYY-MM-DD HH:mm")}}</td>
                    <td>{{item.profession && item.profession.name}}</td>
                    <td>{{item.doctor && item.doctor.name}}</td>
                    <td>{{item.surveyType}}</td>
                    <td>
                        <div class="fs-22" v-if="item.isCompleted">✓</div>
                    </td>
                    <td>
                        <button class="btn btn-primary btn-sm" @click="showAnswers(item)" v-if="item.isCompleted">
                            <localized-text localizedKey="Vedi risposte" text="Vedi risposte"></localized-text>
                        </button>
                        <button class="btn btn-primary btn-sm" @click="continueSurveyAnswers(item)" v-else>
                            <localized-text localizedKey="Continua" text="Continua"></localized-text>
                        </button>
                    </td>
                </tr>
            </tbody>
        </op-table>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import { SurveyAnswersClient } from '@/services/Services';
import { Prop, Watch } from 'vue-property-decorator';
import surveyAnswersViewerModal from '@/components/survey/surveyAnswersViewerModal.vue';
import surveyAnswersInputModal from '@/components/survey/surveyAnswersInputModal.vue';
import store from '@/store';

@Options({})
export default class customerData extends Vue {
 
    @Prop() user: OM.Lead;
    @Prop() project: string;

    projects: string[] = [];
    country: string = ""
    surveyAnswers: OM.SurveyAnswers[] = [];

    created(){
        this.projects = Object.keys(this.user.projectCountryPairs);
        this.country = this.user.projectCountryPairs[this.$store.state.selectedProject];
        this.init();
    }

    init(){
        this.surveyAnswers = [];
        if(!this.$store.state.selectedProject || this.projects.indexOf(this.$store.state.selectedProject) == -1)
            return;
        
        SurveyAnswersClient.getAnswersOfCustomer(this.user.identifier, this.$store.state.selectedProject, this.country)
        .then(x => {
            this.surveyAnswers = x;
        })
    }

    @Watch('$store.state.selectedProject')
    projectChange(){
        if(this.$store.state.selectedProject){
            this.country = this.user.projectCountryPairs[this.$store.state.selectedProject];
            this.init();
        }
    }
    
    showAnswers(answers: OM.SurveyAnswers){
        this.$opModal.show(surveyAnswersViewerModal, {
            answers: answers
        })
    }
    
    continueSurveyAnswers(answers: OM.SurveyAnswers){
        this.$opModal.show(surveyAnswersInputModal, {
            customer: this.user,
            existingSurveyAnswers: answers
        }, () => {
            this.init();
        })
    }
    
    newSurveyAnswers(){
        this.$opModal.show(surveyAnswersInputModal, {
            customer: this.user
        }, () => {
            this.init();
        })
    }
}

</script>
