import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/network',
        name: 'network',
        meta: { sideMenuLabel: "Network" },
        component: () => import("./views/network.vue")
    },
];

export default routes;