import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/smsEvents',
        name: 'smsEvents',
        meta: { sideMenuLabel: "Sms Events" },
        component: () => import("./views/smsEvents.vue")
    },
    {
        path: '/smsEvents/:eventName/templates',
        name: 'smsEventTemplates',
        component: () => import("./views/smsTemplates.vue")
    },
    {
        path: '/smsTemplates',
        name: 'smsTemplates',
        meta: { sideMenuLabel: "Template" },
        component: () => import("./views/smsTemplates.vue")
    },
    {
        path: '/smsTemplates/:identifier',
        name: 'smsTemplateDetail',
        component: () => import("./views/smsTemplateDetail.vue")
    },
];

export default routes;