<template>
    <div class="form-group">
        <label v-if="label">{{label}}</label>
        <div class="scrollListContainer" ref="scrollListContainer">
            <div class="scrollListItem" v-for="(item, i) in localValue" :key="i">
                <div>
                    <template v-if="i < localValue.length - 1"><b>{{localValue[i + 1].value.assigned.name}} → </b></template><b>{{item.value.assigned && item.value.assigned.name}}</b>
                </div>
                <div>
                    <span class="text-primary"><localized-text :localizedKey="item.value.reason" :text="item.value.reason"></localized-text></span>
                </div>
                <div>
                    {{$filters.date(item.date, "DD/MM/yyyy HH:mm")}}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model'
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel'

@Options({})
export default class parentNodeHistory extends mixins(WithModel) {

    @Prop() label: string;
    @Prop() userIdentifier: string;

    declare localValue: OM.DateValueOfNodeAssignment[];

}
</script>
<style scoped>
</style>
