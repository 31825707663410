<template>
	<div class="addressContainer">
        <div class="boxTitle" v-if="label">{{label}}</div>
        <div class="row">
            <div class="col-sm-12" v-if="!readonly">
                <google-autocomplete v-model="googleAddress"></google-autocomplete>
            </div>
            <div class="form-group col-sm-10">
                <label>Via</label>
                <span class="form-control" v-if="readonly">{{localValue.via || '-'}}</span>
                <input v-else class="form-control" v-model="localValue.via" />
            </div>
            <div class="form-group col-sm-2">
                <label>Civico</label>
                <span class="form-control" v-if="readonly">{{localValue.civico || '-'}}</span>
                <input v-else class="form-control" v-model="localValue.civico" />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label>Città</label>
                <span class="form-control" v-if="readonly">{{localValue.citta || '-'}}</span>
                <input v-else class="form-control" v-model="localValue.citta" />
            </div>
            <div class="form-group col-md-4">
                <label>Regione</label>
                <span class="form-control" v-if="readonly">{{localValue.regione || '-'}}</span>
                <input v-else class="form-control" v-model="localValue.regione" />
            </div>
            <div class="form-group col-md-4">
                <label>Nazione</label>
                <span class="form-control" v-if="readonly">{{localValue.nazione || '-'}}</span>
                <input v-else class="form-control" v-model="localValue.nazione" />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-8">
                <label>Provincia</label>
                <span class="form-control" v-if="readonly">{{localValue.provincia || '-'}}</span>
                <input v-else class="form-control" v-model="localValue.provincia" />
            </div>
            <div class="form-group col-4">
                <label>CAP</label>
                <span class="form-control" v-if="readonly">{{localValue.cap || '-'}}</span>
                <input v-else class="form-control" v-model="localValue.cap" />
            </div>
        </div>
        <div class="form-group">
            <label>Scala, piano, interno ecc.</label>
            <span class="form-control" v-if="readonly">{{localValue.scalaPianoInterno || '-'}}</span>
            <input v-else class="form-control" v-model="localValue.scalaPianoInterno" />
        </div>
        <div class="form-group">
            <label>Note di contatto</label>
            <span class="form-control" v-if="readonly">{{localValue.note || '-'}}</span>
            <input v-else class="form-control" v-model="localValue.note" />
        </div>
	</div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class opAddress extends mixins(WithModel) {
    
    @Prop({
        default: false
    }) readonly: boolean;
    @Prop() label: string;

    googleAddress: any = null;
    declare localValue: OM.Address;

    created(){
        this.googleAddress = this.localValue.typedAddress;
    }

    onModelValueChange(next, prev){
        this.localValue = next;
        this.googleAddress = this.localValue.typedAddress;
    }

    @Watch('googleAddress')
    onGoogleAddressChange(next, prev){
        if(typeof next != 'object')
            return;

        this.localValue.typedAddress = next.formatted_address;
        this.localValue.completeAddress = next.formatted_address;
        this.localValue.latLong.lat = next.geometry.location.lat();
        this.localValue.latLong.long = next.geometry.location.lng();
        //this.localValue.name = next.name;
        this.localValue.civico = this.returnValue(next.address_components, "street_number");
        this.localValue.via = this.returnValue(next.address_components, "route");
        this.localValue.nazione = this.returnValue(next.address_components, "country"); // + " (" + this.returnValue(next.address_components, "country", true) + ")";
        // this.localValue.countryCode = this.returnValue(next.address_components, "country_code");
        this.localValue.citta = this.returnValue(next.address_components, "administrative_area_level_3");
        this.localValue.cap = this.returnValue(next.address_components, "postal_code");
        this.localValue.provincia = this.returnValue(next.address_components, "administrative_area_level_2", true);
        this.localValue.regione = this.returnValue(next.address_components, "administrative_area_level_1");

        this.googleAddress = next.formatted_address;
    }

    returnValue(array: any[], key: string, shortName: boolean = false){
        var object = array.find( x => {
            return x.types.find( y => {
                return y == key
            })
        });
        
        if(object){
            if(!shortName)
                return object.long_name;
            return object.short_name;
        }
        return "";
    }

}

</script>