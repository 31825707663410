import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/surveys',
        name: 'surveys',
        meta: { sideMenuLabel: "Questionari" },
        component: () => import("./views/surveys.vue")
    },
    {
        path: '/surveys/:identifier',
        name: 'surveyDetail',
        component: () => import("./views/surveyDetail.vue")
    },
];

export default routes;