<template>
    <div class="opModal p-3">
        <div class="form-group">
            <label class="control-label">
                <localized-text localizedKey="Titolo" text="Titolo"></localized-text>
            </label>
            <localized-value v-slot:default="slotData">
                <input class="form-control" type="text" v-model="content.title.values[slotData.selectedLanguage]" />
            </localized-value>
        </div>
        <div class="form-group">
            <label class="control-label">
                <localized-text localizedKey="Sottotitolo" text="Sottotitolo"></localized-text>
            </label>
            <localized-value v-slot:default="slotData">
                <input class="form-control" type="text" v-model="content.subTitle.values[slotData.selectedLanguage]" />
            </localized-value>
        </div>
        <div class="form-group text-center">
            <label>
                <localized-text localizedKey="Video (viene caricato su S3)" text="Video (viene caricato su S3)"></localized-text>
            </label>
            <localized-value v-slot:default="slotData">
                <file-dropper v-model="fileToUpload" @update:modelValue="onFileToUploadChange($event, slotData.selectedLanguage)" v-if="!preview.values[slotData.selectedLanguage]"></file-dropper>
                <a :href="content.url.values[slotData.selectedLanguage]" target="_blank" v-else class="preview">
                    <img :src="preview.values[slotData.selectedLanguage]" />
                    <button type="button" @click.stop.prevent="preview.values[slotData.selectedLanguage] = null">
                        <localized-text localizedKey="Modifica" text="Modifica"></localized-text>
                    </button>
                </a>
                <div class="text-center" v-if="fileToUpload">
                    <button type="button" @click="uploadVideo(slotData.selectedLanguage)" :disabled="loadingVideo">
                        <template v-if="!loadingVideo">
                            <localized-text localizedKey="Carica su server" text="Carica su server"></localized-text>
                        </template>
                        <template v-else>
                            <localized-text localizedKey="Attendi per favore" text="Attendi per favore"></localized-text>
                        </template>
                    </button>
                </div>
            </localized-value>
        </div>
        <localized-value v-slot:default="slotData">
            <div class="form-group text-center">
                <label>
                    <localized-text localizedKey="Url del video" text="Url del video"></localized-text>
                </label>
                <input class="form-control" type="text" v-model="content.url.values[slotData.selectedLanguage]" />
            </div>
        </localized-value>
        <localized-value v-slot:default="slotData">
            <div class="form-group">
                <label class="control-label">
                    <localized-text localizedKey="Parte testuale" text="Parte testuale"></localized-text>
                </label>
                <tiny-editor v-model="content.textContent.values[slotData.selectedLanguage]" />
            </div>
        </localized-value>
        <hr/>
        <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-danger" @click="annulla">
                <localized-text localizedKey="Annulla modifiche e chiudi" text="Annulla modifiche e chiudi"></localized-text>
            </button>
            <button type="button" class="btn btn-success" @click="$emit('close')">
                <localized-text localizedKey="Ok e chiudi" text="Ok e chiudi"></localized-text>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import FileDropper from '@/components/fileDropper.vue'
import { UploadServices } from '@/services/UploadServices';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class CourseContentModal extends Vue {

    @Prop() content: OM.CourseContent;

    backup: OM.CourseContent = null;

    loadingVideo: boolean = false;
    fileToUpload: any = null;
    preview: OM.LocalizedValue = null;


    onFileToUploadChange(next, language){
        if(next){
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.preview.values[language] = e.target.result;
            }
            reader.readAsDataURL(next);
        }
    }

    uploadVideo(language){
        this.loadingVideo = true;
        UploadServices.UploadFile({
            entityIdentifier: "",
            file: this.fileToUpload
        })
        .then(x => {
            this.content.url[language] = x.publicUrl;
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("File caricato", "File caricato"));
        })
        .catch(err => ModalServices.alertModal("!", LocalizationServices.GetLocalizedValue("Errore nel caricamento", "Errore nel caricamento")))
        .finally(() => this.loadingVideo = false);
    }

    created(){
        this.backup = {...this.content};
        this.preview = this.content.url;
    }

    annulla(){
        this.content.title = this.backup.title;
        this.content.subTitle = this.backup.subTitle;
        this.content.url = this.backup.url;
        this.$emit('close');
    }

}

</script>
