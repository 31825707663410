import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    placeholder: "hh:mm",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.parseAndEmit && _ctx.parseAndEmit(...args)))
  }, null, 544)), [
    [_vModelText, _ctx.localValue]
  ])
}