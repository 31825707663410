import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-217ed72f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "modalHeader" }
const _hoisted_3 = { class: "closer" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "py-3"
}
const _hoisted_6 = { class: "midTitle text-center" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-12" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "midTitle text-center" }
const _hoisted_13 = { class: "row mt-3" }
const _hoisted_14 = { class: "col-md-6" }
const _hoisted_15 = { class: "col-md-6" }
const _hoisted_16 = {
  key: 1,
  class: "form-group"
}
const _hoisted_17 = { class: "modalFooter" }
const _hoisted_18 = { class: "text-end" }
const _hoisted_19 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_project_country_selector = _resolveComponent("project-country-selector")!
  const _component_toggler = _resolveComponent("toggler")!
  const _component_fiscal_data = _resolveComponent("fiscal-data")!
  const _component_user_payment_data = _resolveComponent("user-payment-data")!
  const _component_file_previewer = _resolveComponent("file-previewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("i", {
          class: "fa fa-times",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        })
      ]),
      _createElementVNode("span", _hoisted_4, [
        _createVNode(_component_localized_text, {
          localizedKey: "Richiedi pagamento",
          text: "Richiedi pagamento"
        })
      ])
    ]),
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("form", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_localized_text, {
              localizedKey: "Richiesta di pagamento",
              text: "Richiesta di pagamento"
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_project_country_selector, {
                project: _ctx.vm.project,
                "onUpdate:project": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vm.project) = $event)),
                country: _ctx.vm.country,
                "onUpdate:country": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vm.country) = $event)),
                hideCountry: true
              }, null, 8, ["project", "country"]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Importo richiesto (importo minimo: <b>{{minimo}}</b> - importo massimo: <b>{{saldo}}</b>) (*)",
                    text: "Importo richiesto (importo minimo: <b>{{minimo}}</b> - importo massimo: <b>{{saldo}}</b>) (*)",
                    object: { minimo: _ctx.minimo, saldo: _ctx.saldo }
                  }, null, 8, ["object"])
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.vm.importo) = $event))
                }, null, 512), [
                  [
                    _vModelText,
                    _ctx.vm.importo,
                    void 0,
                    { number: true }
                  ]
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Usa ritenuta d'acconto",
                    text: "Usa ritenuta d'acconto"
                  })
                ]),
                _createVNode(_component_toggler, {
                  modelValue: _ctx.vm.useRitenutaAcconto,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.vm.useRitenutaAcconto) = $event))
                }, null, 8, ["modelValue"])
              ]),
              (_ctx.vm.useRitenutaAcconto)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Per generare la ritenuta d'acconto useremo i seguenti dati. Controlla che siano corretti",
                        text: "Per generare la ritenuta d'acconto useremo i seguenti dati. Controlla che siano corretti"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_fiscal_data, {
                          value: _ctx.user.fiscalData
                        }, null, 8, ["value"])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_user_payment_data, {
                          value: _ctx.user.paymentData
                        }, null, 8, ["value"])
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("label", null, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Allega la fattura (*)",
                        text: "Allega la fattura (*)"
                      })
                    ]),
                    _createVNode(_component_file_previewer, {
                      modelValue: _ctx.vm.fatturaAttachment,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.vm.fatturaAttachment) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-primary",
                disabled: !_ctx.canSend,
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
              }, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Invia richiesta",
                  text: "Invia richiesta"
                })
              ], 8, _hoisted_19)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}