import { CommonServices } from "@/services/CommonServices";
import * as VM from '@/viewmodel'
import * as OM from '@/model'
import store from "@/store";
import { BackendRoleConfigClient, BaseUserClient } from "./Services";
import { StorageServices } from "./StorageServices";
import router from "@/router";

class _LoginServices {

    loginCallback(loginData: OM.LoginData){
        store.state.loginData = loginData;
        StorageServices.setLoginData(loginData);
        CommonServices.setAuthToken(loginData.token);

        return new Promise<void>((resolve, reject) => {
            BaseUserClient.getById(loginData.userIdentifier)
            .then(x => {
                store.state.user = x;
                resolve();
            })
        }) 
    }

    // findFirstRoute(modules: VM.AppRoute[]): any{
    //     let ris: any;
    //     modules.some(x => {
    //         if(x.routeName){
    //             ris = x;
    //             return true;
    //         } else {
    //             ris = this.findFirstRoute(x.children);
    //             return true;
    //         }
    //     });
    //     return ris;
    // }

    logout(){
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            store.state.user = null;
            store.state.loginData = null;
            StorageServices.setLoginData(null);
            resolve();
        })
        return prom;
    }
}

export let LoginServices = new _LoginServices();
