<template>
    <div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Nome di contatto" text="Nome di contatto"></localized-text>
            </label>
            <input type="text" class="form-control" v-model="localValue.contactName" />
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Email" text="Email"></localized-text>
            </label>
            <input type="text" class="form-control" v-model="localValue.email" />
        </div>
        <div class="pt-2">
            <telephone v-model="localValue.telephone" :label="$localizationService.GetLocalizedValue('Telefono', 'Telefono')"></telephone>
        </div>
        <op-address class="mt-2" v-model="localValue.address" :label="$localizationService.GetLocalizedValue('Indirizzo di contatto e spedizione', 'Indirizzo di contatto e spedizione')"></op-address>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model'
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class contactData extends mixins(WithModel) {

    declare localValue: OM.ContactData;

}
</script>
<style scoped>
</style>
