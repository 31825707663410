import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Aggiungi progetto",
          text: "Aggiungi progetto"
        })
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.projectToAdd) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableProjects, (item, i) => {
          return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(item), 1))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.projectToAdd]
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue, (value, key, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "form-group",
        key: i
      }, [
        _createElementVNode("label", null, _toDisplayString(key), 1),
        _withDirectives(_createElementVNode("select", {
          class: "form-control",
          "onUpdate:modelValue": ($event: any) => ((_ctx.localValue[key]) = $event)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countriesForProject[key], (item, j) => {
            return (_openBlock(), _createElementBlock("option", { key: j }, _toDisplayString(item), 1))
          }), 128))
        ], 8, _hoisted_2), [
          [_vModelSelect, _ctx.localValue[key]]
        ])
      ]))
    }), 128))
  ]))
}