<template>
    <div class="row">
        <div class="col-md-6">
            <spesa-item-table :readonly="readonly" :items="week.spesaItems"></spesa-item-table>
        </div>
        <div class="col-md-6" v-if="videoList">
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Video della settimana" text="Video della settimana"></localized-text>
                </label>
                <div class="d-flex align-items-center">
                    <select class="form-control" v-model="week.weekVideo">
                        <option v-for="item in videoList" :key="item.identifier" :value="item">{{item.name}}</option>
                    </select>
                    <button type="button" class="btn btn-danger" @click="week.weekVideo = null">
                        X
                    </button>
                </div>
            </div>
            <video-della-settimana-viewer v-if="week.weekVideo && week.weekVideo.identifier" :video="week.weekVideo"></video-della-settimana-viewer>
        </div>
    </div>
    <hr/>
    <div class="row">
        <div class="col-md-12">
            <menu-step-table :readonly="readonly" :items="week.menuList"></menu-step-table>
        </div>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class planWeek extends Vue {

    @Prop() week: OM.PlanWeek;
    @Prop() videoList: OM.NameIdentifier[];
    @Prop() readonly: boolean;

}
</script>
