<template>
    <td>
        <localized-text localizedKey="La cliente <b>{{nomeCliente}}</b> non ha effettuato l'accesso per più di 3 giorni"
            text="La cliente <b>{{nomeCliente}}</b> non ha effettuato l'accesso per più di 3 giorni"
            :object="{ nomeCliente: item.cliente.name }">
        </localized-text>
    </td>
    <td class="text-end">
        <router-link class="btn btn-link" :to="'/customers/' + item.cliente.identifier">
            <localized-text localizedKey="Vedi cliente" text="Vedi cliente"></localized-text>
        </router-link>
    </td>
</template>

<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BackendNotificationClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class assenzaCliente extends Vue {

    @Prop() item: OM.AssenzaCliente;

}

</script>
