<template>
    <div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Periodo giornata" text="Periodo giornata"></localized-text>
            </label>
            <span v-if="readonly" class="form-control">{{entry.dayPeriod}}</span>
            <input v-else type="text" class="form-control" v-model="entry.dayPeriod" />
            <!-- <select class="form-control" v-model="entry.periodoPasto">
                <option v-for="(item, i) in periodi" :key="i" :value="item">{{item}}</option>
            </select> -->
        </div>
        <div class="form-group" v-html="entry.htmlText" v-if="!edit"></div>
        <div class="form-group" v-else>
            <tiny-editor v-model="entry.htmlText"></tiny-editor>
        </div>
        <div class="d-flex justify-content-center">
            <button v-if="!readonly" type="button" class="btn btn-primary btn-sm" @click="edit = !edit">
                <localized-text localizedKey="Modifica" text="Modifica" v-if="!edit"></localized-text>
                <localized-text localizedKey="Chiudi" text="Chiudi" v-else></localized-text>
            </button>
            <button v-if="!readonly" type="button" class="btn btn-danger btn-sm ms-2" @click="remove">
                <localized-text localizedKey="Rimuovi" text="Rimuovi" v-if="!edit"></localized-text>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class menuEntry extends Vue {

    @Prop() entry: OM.MenuEntry;
    @Prop() readonly: boolean;

    edit: boolean = false;

    remove(){
        this.$emit('remove');
    }

}

</script>
<style scoped>
</style>
