 <template>
    <div>
        <div class="mb-3" :aria-label="config.text.values[$store.state.selectedLanguage]">
            <div><b>{{config.text.values[$store.state.selectedLanguage]}}</b></div>
            <div v-if="config.isMultipleSelect"><i> - <localized-text localizedKey="Risposta multipla" text="Risposta multipla"></localized-text></i></div>
            <div v-if="config.isSingleSelect"><i> - <localized-text localizedKey="Risposta singola" text="Risposta singola"></localized-text></i></div>
            <div v-if="config.required"><i> - <localized-text localizedKey="Risposta obbligatoria" text="Risposta obbligatoria"></localized-text></i></div>
        </div>
        <template v-if="config.isSingleSelect">
            <div class="form-group" v-for="(item, i) in config.options" :key="i">
                <label class="fw-normal d-flex pointer">
                    <input type="radio" class="form-control" :value="item" v-model="answerValue.selectAnswer[0]" @input="setIndex(i)" />
                    {{item.values[$store.state.selectedLanguage]}}
                </label>
            </div>
            <!-- <div class="form-group">
                <select class="form-control" v-model="answerValue.answer[0]">
                    <option :value="undefined" disabled>
                        <localized-text localizedKey="Seleziona una risposta" text="Seleziona una risposta"></localized-text>  
                    </option>
                    <option v-for="(item, i) in config.options" :key="i" :value="item">{{item}}</option>
                </select>
            </div> -->
        </template>
        <template v-else-if="config.isMultipleSelect">
            <div class="form-group" v-for="(item, i) in config.options" :key="i">
                <label class="fw-normal d-flex pointer">
                    <input type="checkbox" class="form-control" :value="item" v-model="answerValue.selectAnswer" />
                    {{item.values[$store.state.selectedLanguage]}}
                </label>
            </div>
        </template>
        <template v-else>
            <input type="text" class="form-control" v-model="answerValue.textAnswer" />
        </template>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class answerValue extends Vue {

    @Prop() config: OM.QuestionConfig;
    @Prop() answerValue: OM.AnswerValue;

    created(){
        this.onConfigChange();
    }

    @Watch('config')
    @Watch('answerValue')
    onConfigChange(){
        this.answerValue.questionIdentifier = this.config.identifier;
        this.answerValue.questionText = this.config.text;
    }

    setIndex(i){
        if(this.config.isSingleSelect){
            this.answerValue.answerIndexes.splice(0);
            this.answerValue.answerIndexes.push(i);
        } 
    }

    @Watch('answerValue.selectAnswer.length')
    selectAnswerChange(){
        if(this.config.isMultipleSelect) {
            this.answerValue.answerIndexes.splice(0);
            this.answerValue.selectAnswer.forEach(x => {
                let indexOf = this.config.options.findIndex(c => c.values[this.$store.state.selectedLanguage] == x.values[this.$store.state.selectedLanguage]);
                this.answerValue.answerIndexes.push(indexOf);
            })
        }
    }

}
</script>

<style scoped>

</style>
