<template>
    <div class="switch" :class="{ checked: inverse ? !localValue : localValue }" @click="toggle">
        <span class="slider round"></span>
    </div>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class Toggler extends mixins(WithModel) {

    @Prop() inverse: boolean;
    
    toggle(){
        this.emitModel(!this.localValue);
    }

}
</script>

<style scoped>

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch.mini {
        width: 30px;
        height: 18px;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        background-color: #ffacc6;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 5%;
        bottom: 10%;
        background-color: white;
        transition: .4s;
    }
    
    .switch.mini .slider:before {
        height: 16px;
        width: 16px;
        left: 3%;
        bottom: 5%;
    }

    .switch.checked .slider {
        background-color: #009342;
    }
    .switch.checked .slider:before {
        transform: translateX(100%);
        background-color: #ffffff;
    }

    .switch.checked.mini .slider:before {
        transform: translateX(77%);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
        background-color: #ffffff;
    }

</style>
