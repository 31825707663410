<template>
    <div class="monthNavigator">
        <div class="left">
            <button type="button" class="btn btn-hollow" @click="prevMonth">
                <img src="@/img/common/chevron_left.svg" />
            </button>
        </div>
        <div class="center">{{$filters.date(localValue, 'MMMM YYYY')}}</div>
        <div class="right">
            <button type="button" class="btn btn-hollow" @click="nextMonth">
                <img src="@/img/common/chevron_right.svg" />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import WithModel from "@/mixins/withModel";
import * as OM from '@/model';
import moment from "moment";
import { mixins, Options } from "vue-class-component";

@Options({})
export default class monthNavigator extends mixins(WithModel) {

    declare localValue: string;

    prevMonth(){
        this.localValue = moment(this.localValue).subtract(1, 'month').startOf('month').toISOString();
        this.emitModel(this.localValue);
    }

    nextMonth(){
        this.localValue = moment(this.localValue).add(1, 'month').startOf('month').toISOString();
        this.emitModel(this.localValue);
    }

};
</script>