import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/doctors',
        name: 'doctors',
        meta: { sideMenuLabel: "Dottori" },
        component: () => import("./views/doctors.vue")
    },
    {
        path: '/doctors/:identifier',
        name: 'doctorsDetail',
        meta: { classes: "" },
        component: () => import("./views/doctorsDetail.vue")
    }
];

export default routes;