<template>
    <div v-if="itemList">
        <button class="btn btn-primary me-1" @click="prevItem" :disabled="!canPrev">
            <localized-text localizedKey="Precedente" text="Precedente"></localized-text>
        </button>
        {{currentIndex + 1}} / {{itemList.length}}
        <button class="btn btn-primary ms-1" @click="nextItem" :disabled="!canNext">
            <localized-text localizedKey="Successivo" text="Successivo"></localized-text>
        </button>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';

@Options({})
export default class itemDetailRouteNavigator extends Vue {

    itemList: VM.DetailRouteItem[] = [];
    canPrev: boolean = false;
    canNext: boolean = false;
    currentIndex: number = 0;

    created(){
        this.itemList = StorageServices.getDetailRouteItems();
        if(!this.itemList)
            return;

        this.currentIndex = this.itemList.findIndex(x => x.identifier == this.$route.params.identifier);
        if(this.currentIndex == 0)
            this.canPrev = false;
        else
            this.canPrev = true;

        if(this.currentIndex == this.itemList.length - 1)
            this.canNext = false;
        else
            this.canNext = true;

        store.state.beforeNavCbs.unshift((to, from) => {
            store.state.routeBuster = true;
        })
    }
    
    prevItem(){
        if(!this.canPrev)
            return;

        let prevItem = this.itemList[this.currentIndex - 1];

        this.$router.push({ 
            name: prevItem.routeName ? prevItem.routeName : this.$router.currentRoute.value.name,
            params: {
                identifier: prevItem.identifier
            }
        })
    }
    
    nextItem(){
        if(!this.canNext)
            return;

        let prevItem = this.itemList[this.currentIndex + 1];

        this.$router.push({ 
            name: prevItem.routeName ? prevItem.routeName : this.$router.currentRoute.value.name,
            params: {
                identifier: prevItem.identifier
            }
        })
    }


}

</script>
