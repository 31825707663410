import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b43de738"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "_nodeTreeContainer" }
const _hoisted_2 = {
  class: "_nodeTree",
  ref: "tree"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node = _resolveComponent("node")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_node, {
        node: _ctx.rootNode,
        class: "_singleNode",
        open: true,
        isCommerciale: _ctx.isCommerciale,
        allowNavigation: _ctx.allowNavigation,
        showFilters: _ctx.showFilters
      }, null, 8, ["node", "isCommerciale", "allowNavigation", "showFilters"])
    ], 512)
  ]))
}