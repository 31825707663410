import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "scrollListContainer" }
const _hoisted_5 = { class: "text-cyan" }
const _hoisted_6 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_ctx.provvigioniConfig.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Qualifica provvigioni",
              text: "Qualifica provvigioni"
            })
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProvvigioni) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSelectedProvvigioniChange && _ctx.onSelectedProvvigioniChange(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provvigioniConfig, (item, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: item
              }, _toDisplayString(item.qualificationCode), 9, _hoisted_3))
            }), 128))
          ], 544), [
            [_vModelSelect, _ctx.selectedProvvigioni]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_localized_text, {
            localizedKey: "Storico qualifiche",
            text: "Storico qualifiche"
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue.qualificationHistory, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "scrollListItem d-flex justify-content-start",
              key: i
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$filters.date(item.date, "YYYY-MM-DD HH:mm")), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(item.value.from.qualificationCode) + " -> " + _toDisplayString(item.value.to.qualificationCode), 1)
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}