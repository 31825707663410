<template>
    <div class="_nodeContainer" :style="node.offset">
        <div class="_upperAnchor"></div>
        <div class="_node" @click.stop.prevent="onNodeClick" :class="node.role" >
            <div>
                <div class="_nodeImage">
                    <img :src="node.imageUrl" v-if="node.imageUrl" key="img" />
                    <img src="@/img/defaultprofile.jpg" v-else key="def" />
                    <div class="_nodeImageText" :class="{ 'text-orange': node.type == 'Customer' || node.type == 'Lead', 'text-green': node.type == 'Operator' }">
                        {{node.role}}
                    </div>
                </div>
                <div class="_nodeBody">
                    <div>{{node.completeName}}</div>
                    <div><span class="text-cyan">Qualifica:</span> <b>{{node.qualification.qualificationCode}}</b></div>
                    <div><span class="text-cyan">Punti:</span> <b>{{node.qualification.totalPoints}}</b></div>
                    <!-- <div><span class="text-cyan">Clienti: </span><b>{{node.totaleClienti}}</b></div> -->
                    <div><span class="text-cyan">Ref: </span><b>{{node.referralCode}}</b></div>
                </div>
            </div>
            <div class="_nodeFooter">
                <mini-spinner v-if="loading"></mini-spinner>
                <template v-else-if="showFilters">
                    <div class="filter">
                        <div><toggler class="mini" v-model="showLead" @click.stop.prevent></toggler></div>
                        <span class="fs-10">Mostra lead</span>
                    </div>
                    <div class="filter ms-2">
                        <div><toggler class="mini" v-model="showClienti" @click.stop.prevent></toggler></div>
                        <span class="fs-10">Mostra clienti</span>
                    </div>
                </template>
            </div>
        </div>
        <div class="_nodeChildren" :class="{ _shown: children.length > 0 }">
            <node v-for="(item, i) in children" :key="i" :node="item" 
                :class="{ 'disabled': item.hide, '_singleNode': children.length == 1 }"
                :showClienti="showClienti" :showLead="showLead" :showFilters="showFilters"
                @closed="showSiblings" @opened="hideSiblings(item)" :allowNavigation="allowNavigation"></node>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewmodel';
import { NetworkClient } from '@/services/Services';

@Options({})
export default class Node extends Vue {

    @Prop() showFilters: boolean;

    @Prop({
        type: [Object, VM.NetworkUserVM]
    }) node: VM.NetworkUserVM;

    @Prop({
        default: true
    }) allowNavigation: boolean;

    children: VM.NetworkUserVM[] = [];
    childrenLoaded: boolean = false;
    
    showClienti: boolean = true;
    showLead: boolean = true;

    loading: boolean = false;

    @Prop({
        default: false
    }) open: boolean;

    @Watch('node.hide')
    onHideChange(next, prev){
        this.childrenLoaded = false;
        this.children = [];
    }

    @Watch('isCommerciale')
    @Watch('showClienti')
    @Watch('showLead')
    onIsCommercialeChange(next, prev){
        this.fetchChildren();
    }

    created(){
        this.init();
    }

    init(){
        if(this.open){
            this.toggleChildren();
        }
    }

    showSiblings(){
        this.children.forEach(x => {
            x.hide = false;
        })
    }

    hideSiblings(item: OM.NetworkUserVm){
        this.children.forEach(x => {
            if(x.identifier != item.identifier){
                x.hide = true;
            }
        })
    }

    onNodeClick(){
        if(!this.allowNavigation)
            return;

        this.toggleChildren();
    }

    toggleChildren(){
        this.node.hide = false;
        if(this.childrenLoaded){
            this.children = [];
            this.childrenLoaded = false;
            this.$emit('closed');
            return;
        }
        this.fetchChildren();
    }

    fetchChildren(){
        this.loading = true;
        let prom = NetworkClient.getFirstLineOf(this.node.identifier, this.showClienti, this.showLead, false);
        prom
        .then(x => {
            this.children = x;
            this.childrenLoaded = true;

            let adder = 174;
            let counter = -Math.floor(this.children.length / 2); //0;
            let isPair = this.children.length % 2 == 0 ? 1 : 0;
            if(isPair){
                counter += 0.5;
                //adder = adder * -1;
            }
            this.children.forEach((x, i) => {
                x.hide = false;
                x.offset = 'left: ' + (counter * adder) + 'px;'
                counter++;
                // if((i + isPair) % 2 == 0)
                //     counter = Math.abs(counter) + 1;
                // else
                //     counter *= -1;
            })
            //ordinamento dei nodi per essere messi belli nel dom
            let reg = /[0-9,-]/g;
            this.children.sort((a, b) => {
                let aOffset = parseInt(a.offset.match(reg).join(''));
                let bOffset = parseInt(b.offset.match(reg).join(''));
                if(aOffset > bOffset)
                    return 1;
                else
                    return -1;
            })
            this.$emit('opened');
        })
        .finally(() => this.loading = false);
    }
}

</script>

<style scoped>

._nodeContainer {
    text-align: center;
    position: absolute;
}
._node {
    display: inline-block;
    background-color: #fafafa;
    text-align: center;
    padding: 10px 10px;
    border: 1px solid #2196F3;
    margin: 30px 10px;
    width: 154px;
    min-height: 100px;
    border-radius: 8px;
    box-shadow: 0px 0px 18px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
}
._node.Cliente {
    border-color: #F2921A;
    background-color: hsla(33, 89%, 53%, 0.15);
}
._node.Collaboratore {
    border-color: #228f59;
    background-color: hsla(150, 62%, 35%, 0.15);
}
._node.Manager {
    border-color: #2196F3;
    background-color: hsla(207, 90%, 54%, 0.15);
}
._nodeTitle {
    width: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 10px;
    padding: 4px 0;
}
._nodeBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    font-size: 10px;
    text-align: left;
    padding-left: 4px;
}
._nodeFooter {
    padding-top: 10px;
    display: flex;
    align-items: center;
}
._nodeBody ._nodeBodyLower {
    display: flex;
    margin: 4px -10px;
    padding: 2px 10px;
    background-color: whitesmoke;
    font-size: 12px;
    justify-content: space-around;
    align-items: center;
    letter-spacing: -0.2px;
}
._nodeChildren {
    display: flex;
    position: relative;
}
._nodeContainer.disabled {
    opacity: 0.2;
}
._nodeContainer.disabled ._nodeContainer {
    display: none;
}
._nodeImage {
    flex-shrink: 0;
}
._nodeImage img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
._nodeImage ._nodeImageText {
    font-size: 8px;
}
._nodeContainer:before {
    content: '';
    background-color: #2196F3;
    position: absolute;
    height: 2px;
    top: 0;
    width: 100%;
    right: 0%;
}
._nodeContainer:first-of-type:before {
    width: 50%;
    top: -2px;
    right: 0;
    transform-origin: bottom;
    transform: rotateX(180deg);
}
._nodeContainer:last-of-type:before {
    width: 50%;
    left: 0;
}
._nodeContainer._singleNode:before {
    content: none;
}
._upperAnchor {
    height: 31px;
    width: 2px;
    background-color: #2196F3;
    position: absolute;
    left: calc(50% - 1px);
    top: 0px;
}

._nodeChildren._shown:after {
    content: '';
    width: 2px;
    height: 30px;
    background-color: #2196F3;
    position: absolute;
    margin: auto;
    left: 0px;
    right: 0;
    top: -30px;
}



</style>
