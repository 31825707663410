<template>
    <div class="opSelect" ref="root">
        <div class="opOption selected" @click="isOpen = !isOpen">
            <slot :item="getItemText(localValue)">{{getItemText(localValue)}}</slot>
        </div>
        <div class="opOptionsContainer" v-if="isOpen">
            <div class="opOption" v-for="(item, i) in options" :key="i" @click="setOption(item)">
                <slot :item="getItemText(item)">{{getItemText(item)}}</slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins, Options, Vue } from "vue-class-component";
import WithModel from "@/mixins/withModel";

@Options({})
export default class OpSelect extends mixins(WithModel) {

    @Prop({
        default: []
    }) options: any[];
    
    @Prop() emptyText: string;

    isOpen: boolean = false;

    mounted(){
        document.addEventListener("click", this.closeSelect);
    }

    beforeUnmount(){
        document.removeEventListener("click", this.closeSelect);
    }

    closeSelect(ev){
        let parent = ev.target.parentElement
        while(parent && parent != this.$refs.root){
            parent = parent.parentElement
        }
        if(!parent)
            this.isOpen = false;
    }

    getItemText(item){
        if(!item)
            return this.emptyText;
        return item;
    }

    setOption(value){
        this.isOpen = false;
        this.emitModel(value);
    }

}
</script>

<style scoped>

.opSelect {
    background: white;
    display: block;
    position: relative;
    text-align: left;
}

.opOptionsContainer {
    position: absolute;
    width: 100%;
    left: 0;
    top: 22px;
    overflow-y: auto;
    background: white;
    z-index: 2;
    max-height: 140px;
    border: 1px solid gray;
    border-top: none;
}

.opOption {
    position: relative;
    padding: 2px 18px 2px 8px;
    cursor: pointer;
}

.opOption :slotted(img) {
    max-height: 18px;
}

.opOption.selected {
    border: 1px solid gray;
}

.opOption.selected:after {
  position: absolute;
  content: "";
  top: 8px;
  right: 6px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: #06101f transparent transparent transparent;
}

.opOption:not(.selected):hover {
    background-color: #4361a3;
    color: white;
}

</style>