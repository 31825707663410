import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "modalHeader" }
const _hoisted_3 = { class: "modalBody" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "modalFooter text-end" }
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_date = _resolveComponent("op-date")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Crea piano alimentare dalle settimane di default",
            text: "Crea piano alimentare dalle settimane di default"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Data di inizio",
              text: "Data di inizio"
            })
          ]),
          _createVNode(_component_op_date, {
            class: "form-control",
            modelValue: _ctx.startDate,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event)),
              _ctx.getWeekNumber
            ]
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          _createElementVNode("b", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Numero settimana",
              text: "Numero settimana"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.weekNumber), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Tipo di dieta",
              text: "Tipo di dieta"
            })
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDiet) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dietTypes, (item, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: item
              }, _toDisplayString(item), 9, _hoisted_6))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.selectedDiet]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Quante settimane?",
              text: "Quante settimane?"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "number",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.howManyWeeks) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelText, _ctx.howManyWeeks]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-link text-danger",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Annulla",
            text: "Annulla"
          })
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
          disabled: !_ctx.canSave
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Salva",
            text: "Salva"
          })
        ], 8, _hoisted_9)
      ])
    ])
  ]))
}