import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/commissions',
        name: 'commissions',
        meta: { sideMenuLabel: "Commissioni" },
        component: () => import("./views/commissions.vue")
    },
];

export default routes;