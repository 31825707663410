import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Nome",
          text: "Nome"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.name) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Cognome",
          text: "Cognome"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.surname) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.surname]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Sesso",
          text: "Sesso"
        })
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-link", { active: _ctx.localValue.sesso == 'M'}]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.localValue.sesso = 'M'))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Maschio",
            text: "Maschio"
          })
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-link", { active: _ctx.localValue.sesso == 'F'}]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.localValue.sesso = 'F'))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Femmina",
            text: "Femmina"
          })
        ], 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Url immagine profilo",
          text: "Url immagine profilo"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localValue.profilePicture) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.profilePicture]
      ])
    ])
  ]))
}