import { CommonServices } from "@/services/CommonServices";
import { apiurl } from '@/config';
import * as VM from '@/viewmodel'
import * as OM from '@/model'

class _UserPaymentRequestServices {

    richiediPagamento(vm: VM.RichiediPagamentoUserVm){
        return CommonServices.uploadFileToUrl<string>(apiurl + "api/UserPaymentRequest/RichiediPagamento", vm)
    }

}

export let UserPaymentRequestServices = new _UserPaymentRequestServices();
