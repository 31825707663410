<template>
<div class="opModal">
    <div class="header">
        <h3>
            <localized-text localizedKey="Escludi orari di prenotazione dall'agenda" text="Escludi orari di prenotazione dall'agenda"></localized-text>
        </h3>
    </div>
    <div class="form-group">
        <label>
            <localized-text localizedKey="Seleziona professione" text="Seleziona professione"></localized-text>
        </label>
        <select class="form-control" v-model="selectedProfessione">
            <option v-for="(item, i) in professioni" :value="item">{{item.name}}</option>
        </select>
    </div>
    <hr/>
    <div class="body" v-if="selectedProfessione">
        <table class="table table-striped" style="min-width: 720px;">
            <thead>
                <tr>
                    <td>
                        <localized-text localizedKey="Giorno" text="Giorno"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="Fasce orarie" text="Fasce orarie"></localized-text>
                    </td>
                    <td class="text-end">
                        <button type="button" class="btn btn-success" @click="addEsclusione">
                            <localized-text localizedKey="+ aggiungi esclusione" text="+ aggiungi esclusione"></localized-text>
                        </button>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in esclusioni" :key="i">
                    <td>
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Giorno" text="Giorno"></localized-text>
                            </label>
                            <input type="date" class="form-control" v-model="item.date" v-date="item.date" />
                        </div>
                    </td>
                    <td style="vertical-align: middle;">
                        <template v-for="(exclItem, ii) in item.timeTable">
                            <div class="text-center">
                                <button type="button" class="btn btn-sm btn-success" @click="tuttoIlGiorno(exclItem)">
                                    <localized-text localizedKey="Tutto il giorno" text="Tutto il giorno"></localized-text>
                                </button>
                            </div>
                            <div class="text-center">
                                <input type="time" v-model="exclItem.from" />
                                <input type="time" v-model="exclItem.to" />
                            </div>
                        </template>
                    </td>
                    <td class="text-end" style="vertical-align: middle;">
                        <button type="button" class="btn btn-danger" @click="removeEsclusione(item)">
                            <localized-text localizedKey="- rimuovi giorno" text="- rimuovi giorno"></localized-text>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-5 text-center mw-700">
            <button type="button" class="btn btn-success" @click="ok">
                <localized-text localizedKey="OK" text="OK"></localized-text>
            </button>
            <button type="button" class="ms-4 btn btn-danger" @click="$emit('close')">
                <localized-text localizedKey="Annulla" text="Annulla"></localized-text>
            </button>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model'
import { JsonClone } from '@/utils';
import { BaseUserClient, TimeTableExclusionClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class esclusioneOrariModal extends Vue {

    @Prop() userIdentifier: string;

    esclusioni: OM.TimeTableExclusion[] = [];
    selectedProfessione: OM.NameIdentifier = null;
    professioni: OM.NameIdentifier[] = [];
    user: OM.Operator = null;


    created(){
        BaseUserClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
        })
    }
    
    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione)
            this.init();
    }

    init(){
        TimeTableExclusionClient.getByConsulente(this.userIdentifier, this.selectedProfessione.identifier)
        .then(x => {
            this.esclusioni = x;
        })
    }

    addEsclusione(){
        let toPush = new OM.TimeTableExclusion();
        toPush.userIdentifier = this.userIdentifier;
        toPush.profession = this.selectedProfessione;
        this.esclusioni.unshift(toPush);
    }

    removeEsclusione(item: OM.TimeTableExclusion){
        if(item.identifier){
            TimeTableExclusionClient.deleteById(item.identifier)
            .then(x => {
                let index = this.esclusioni.findIndex(x => x == item);
                this.esclusioni.splice(index, 1);
            });
        } else {
            let index = this.esclusioni.findIndex(x => x == item);
            this.esclusioni.splice(index, 1);
        }
    }

    ok(){
        TimeTableExclusionClient.save(this.esclusioni)
        .then(x => {
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Salvato", "Salvato"));
            this.$emit('close');
        })
    }

    tuttoIlGiorno(item: OM.TimeTableItem){
        item.from = "00:00";
        item.to = "23:59";
    }

}
</script>
<style scoped>

</style>