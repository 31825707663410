import { RouteRecordRaw } from 'vue-router';
import Dashboard from './dashboard.vue';


let routes: RouteRecordRaw[] = [
    {
        path: '/dashboard',
        meta: { sideMenuLabel: "Dashboard" },
        component: () => import("./views/dashboard.vue"),
    },
    {
        path: '/dashboard/:userIdentifier',
        meta: { title: "Dashboard utente", routeBuster: true },
        component: () => import("./views/dashboard.vue"),
    }
];

export default routes;