<template>
    <div v-if="user">
        <div class="d-flex justify-content-between">
            <div class="midTitle">
                <localized-text localizedKey="Prenotazioni" text="Prenotazioni"></localized-text>
            </div>
            <button class="btn btn-primary" :disabled="bookingVouchers.length == 0 || !$store.state.selectedProject" @click="bookWithVoucher">
                <localized-text localizedKey="Nuova prenotazione" text="Nuova prenotazione"></localized-text>
                (<localized-text localizedKey="Ticket rimasti: {{ticket}}" text="Ticket rimasti: {{ticket}}" :object="{ ticket: bookingVouchers.length }"></localized-text>)
            </button>
        </div>
        <!-- <div class="form-group" v-if="!project">
            <label>
                <localized-text localizedKey="Progetto" text="Progetto"></localized-text>
            </label>
            <select class="form-control" v-model="selectedProject">
                <option v-for="item in projects" :value="item">
                    {{item}}
                </option>
            </select>
        </div> -->
        <div class="opTableScroller">
            <table class="opTable table table-striped">
                <thead>
                    <tr>
                        <td>
                            <localized-text localizedKey="Progetto" text="Progetto"></localized-text>
                        </td>
                        <td>
                            <localized-text localizedKey="Tipo" text="Tipo"></localized-text>
                        </td>
                        <td>
                            <localized-text localizedKey="Data" text="Data"></localized-text>
                        </td>
                        <td>
                            <localized-text localizedKey="Orario" text="Orario"></localized-text>
                        </td>
                        <td>
                            <localized-text localizedKey="Dottore" text="Dottore"></localized-text>
                        </td>
                        <td>
                            <localized-text localizedKey="Professione" text="Professione"></localized-text>
                        </td>
                        <td>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in bookings" :key="i">
                        <td>
                            {{item.project}}
                        </td>
                        <td>
                            {{item.visitType}}
                        </td>
                        <td>
                            {{$filters.date(item.data, 'DD-MM-YYYY HH:mm')}}
                        </td>
                        <td>
                            {{$filters.timespan(item.fasciaOraria.from)}} - {{$filters.timespan(item.fasciaOraria.to)}}
                        </td>
                        <td>
                            {{item.user && item.user.name}} 
                        </td>
                        <td>
                            {{item.professione && item.professione.name}} 
                        </td>
                        <td>
                            <router-link class="btn btn-primary" :to="'/bookingDetail/' + item.identifier">
                                <localized-text localizedKey="Dettaglio" text="Dettaglio"></localized-text>
                            </router-link>
                            <button class="btn btn-warning ms-2" @click="modifyBooking(item)">
                                <localized-text localizedKey="Sposta" text="Sposta"></localized-text>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import { BookingClient, BookingVoucherClient } from '@/services/Services';
import { Prop, Watch } from 'vue-property-decorator';
import makeBookingFromVoucherModal from './makeBookingFromVoucherModal.vue';
import store from '@/store';

@Options({})
export default class customerBookings extends Vue {
 
    @Prop() user: OM.Lead;

    projects: string[] = [];
    country: string = ""
    surveyAnswers: OM.SurveyAnswers[] = [];

    bookingVouchers: OM.BookingVoucher[] = [];
    bookings: OM.BookingForListVm[] = [];

    created(){
        this.projects = Object.keys(this.user.projectCountryPairs);
        this.init();
    }

    init(){
        this.bookingVouchers = [];
        this.bookings = [];
        if(!this.$store.state.selectedProject || this.projects.indexOf(this.$store.state.selectedProject) == -1)
            return;

        BookingVoucherClient.getAvailableBookingVoucher(this.$store.state.selectedProject, this.user.identifier).then(x => this.bookingVouchers = x)
        BookingClient.getByClienteByProject(this.$store.state.selectedProject, this.user.identifier).then(x => this.bookings = x)
    }

    @Watch('$store.state.selectedProject')
    projectChange(){
        if(this.$store.state.selectedProject){
            this.init();
        }
    }

    
    bookWithVoucher(){
        this.$opModal.show(makeBookingFromVoucherModal, {
            customer: this.user,
            voucher: this.bookingVouchers[0],
        }, () => {
            BookingClient.getByCliente(this.user.identifier).then(x => this.bookings = x)
        })
    }

    modifyBooking(item: OM.BookingForListVm){
        this.$opModal.show(makeBookingFromVoucherModal, {
            customer: this.user,
            voucher: this.bookingVouchers[0],
            booking: item
        }, () => {
            BookingClient.getByCliente(this.user.identifier).then(x => this.bookings = x)
        })
    }

}

</script>
