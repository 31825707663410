<template>
    <tiny-editor v-model="localValue" @update:modelValue="emitModel"></tiny-editor>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({})
export default class TinyEditor extends mixins(WithModel) {

    @Watch('modelValue')
    ensureIsString(){
        if(!this.modelValue){
            this.localValue = "";
        }
    }

}

</script>
<style scoped>

</style>
