<template>
    <div class="opModal">
        <project-country-selector v-model:project="surveyAnswers.project" v-model:country="surveyAnswers.country"></project-country-selector>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Professione" text="Professione"></localized-text>
            </label>
            <select class="form-control" v-model="surveyAnswers.profession">
                <option v-for="item in professioni" :value="item">{{item.name}}</option>
            </select>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Dottore" text="Dottore"></localized-text>
            </label>
            <select class="form-control" v-model="surveyAnswers.doctor" @change="surveyAnswers.surveyType = null" :disabled="!surveyAnswers.profession">
                <option v-for="item in doctors" :value="item">{{item.name}}</option>
            </select>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Tipo questionario" text="Tipo questionario"></localized-text>
            </label>
            <select class="form-control" v-model="surveyAnswers.surveyType" @change="init" :disabled="!surveyAnswers.doctor">
                <option v-for="item in surveyTypes" :value="item">{{item}}</option>
            </select>
        </div>
        <template v-if="questionario">
            <div class="title text-center mt-3 mb-2">
                <localized-text localizedKey="Questionario interno" text="Questionario interno"></localized-text>
            </div>
            <div class="lowTitle text-center mb-3">
                <localized-text localizedKey="Domanda numero:" text="Domanda numero:"></localized-text> {{currentQuestion + 1}} / {{questionario.questions.length}}
            </div>
            <answer-value :config="currentDomandaConfig" :answer-value="tempAnswerValue"></answer-value>
            <div class="text-center d-flex justify-content-around mt-4">
                <button type="button" class="btn btn-primary me-1" @click="prev" :disabled="currentQuestion == 0">
                    <localized-text localizedKey="Indietro" text="Indietro"></localized-text>
                </button>
                <button type="button" class="btn btn-primary ms-1" @click="next" :disabled="!canNext">
                    <localized-text localizedKey="Avanti" text="Avanti" v-if="currentQuestion < questionario.questions.length - 1"></localized-text>
                    <localized-text localizedKey="Fine" text="Fine" v-else></localized-text>
                </button>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as CONST from '@/const';
import { DoctorClient, ProfessionConfigurationClient, SurveyAnswersClient, SurveyClient } from '@/services/Services';
import { JsonClone } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class surveyAnswersInputModal extends Vue {

    @Prop() customer: OM.BaseUser;
    @Prop() existingSurveyAnswers: OM.SurveyAnswers;
    
    questionario: OM.Survey = null;
    doctors: OM.NameIdentifier[] = [];
    professioni: OM.NameIdentifier[] = [];
    surveyTypes: string[] = CONST.SurveyType.GetAll();
    tempAnswerValue: OM.AnswerValue = new OM.AnswerValue();
    surveyAnswers: OM.SurveyAnswers = null;
    currentQuestion: number = 0;
    selectedProfessione: OM.NameIdentifier = null;

    created(){
        ProfessionConfigurationClient.getAllAsNameIdentifier()
        .then(x => {
            this.professioni = x;
        })
        DoctorClient.getNameIdentifiers()
        .then(x => {
            this.doctors = x;
        })
        if(this.existingSurveyAnswers) {
            this.surveyAnswers = this.existingSurveyAnswers;
            this.currentQuestion = this.surveyAnswers.answerValues.length - 1;
        } else {
            this.surveyAnswers = new OM.SurveyAnswers();
            this.surveyAnswers.customer = {
                identifier: this.customer.identifier,
                name: this.customer.personalData.completeName,
            }
            this.surveyAnswers.profession = this.selectedProfessione;
            if(!this.surveyAnswers.isCompleted){
                this.currentQuestion = this.surveyAnswers.answerValues.length - 1;
            }
        }
        this.tempAnswerValue = this.surveyAnswers.answerValues[this.currentQuestion];
        this.init();
    }

    init(){
        if(!this.surveyAnswers.profession || !this.surveyAnswers.doctor || !this.surveyAnswers.surveyType)
            return;

        let prom;
        if(this.surveyAnswers.surveyType == CONST.SurveyType.Assessment){
            prom = SurveyClient.getActiveAssessment(this.surveyAnswers.project, this.surveyAnswers.country, this.surveyAnswers.profession.identifier, this.surveyAnswers.doctor.identifier)
        } else {
            prom = SurveyClient.getActiveInternal(this.surveyAnswers.project, this.surveyAnswers.country, this.surveyAnswers.profession.identifier, this.surveyAnswers.doctor.identifier)
        }

        prom
        .then(x => {
            if(!x){
                ModalServices.alertModal(LocalizationServices.GetLocalizedValue("Errore", "Errore"), LocalizationServices.GetLocalizedValue("Non è stato trovato un questionario", "Non è stato trovato un questionario"))
            }
            this.questionario = x;
            this.currentQuestion = 0;
        })
    }

    @Watch('surveyAnswers.profession')
    professionChange(){
        if(this.surveyAnswers.profession){
            DoctorClient.getDoctorsWithProfession(this.surveyAnswers.profession.identifier)
            .then(x => {
                this.doctors = x;
            })
        }
    }

    @Watch('currentQuestion')
    onCurrentQuestionChange(){
        this.tempAnswerValue = this.surveyAnswers.answerValues[this.currentQuestion] || new OM.AnswerValue();
    }

    prev(){
        this.currentQuestion--;

        while(this.currentDomandaConfig.hasParentQuestion){
            if(!this.surveyAnswers.answerValues[this.currentDomandaConfig.parentQuestionConfig.parentQuestionIndex].answerIndexes.some(x => x == this.currentDomandaConfig.parentQuestionConfig.parentAnswerIndex)){
                this.surveyAnswers.answerValues[this.currentQuestion] = new OM.AnswerValue();
                this.currentQuestion--;
            } else {
                break;
            }
        }
    }

    get canNext(){
        if(!this.currentDomandaConfig.required)
            return true;

        if(this.tempAnswerValue.selectAnswer.length == 0 && !this.tempAnswerValue.selectAnswer[0] && !this.tempAnswerValue.textAnswer){
            return false;
        }

        return true;
    }

    get currentDomandaConfig(){
        return this.questionario.questions[this.currentQuestion];
    }

    next(){
        if(!this.canNext)
            return;

        this.surveyAnswers.answerValues[this.currentQuestion] = JsonClone(this.tempAnswerValue);
        this.tempAnswerValue = new OM.AnswerValue();
        this.surveyAnswers.isCompleted = this.surveyAnswers.answerValues.length == this.questionario.questions.length;
        SurveyAnswersClient.saveRisposte(this.surveyAnswers)
        .then(x => {
            this.surveyAnswers.identifier = x;
            if(this.currentQuestion == this.questionario.questions.length - 1){
                ModalServices.alertModal(
                    '',
                    LocalizationServices.GetLocalizedValue("Questionario completato!", "Questionario completato!"),
                    LocalizationServices.GetLocalizedValue("Chiudi", "Chiudi")
                )
                this.$emit('close');
            } else {
                this.currentQuestion++;

                while(this.currentDomandaConfig.hasParentQuestion){
                    if(!this.surveyAnswers.answerValues[this.currentDomandaConfig.parentQuestionConfig.parentQuestionIndex].answerIndexes.some(x => x == this.currentDomandaConfig.parentQuestionConfig.parentAnswerIndex)){
                        this.surveyAnswers.answerValues[this.currentQuestion] = new OM.AnswerValue();
                        this.currentQuestion++;
                    } else {
                        break;
                    }
                }
            }
        })
    }



}
</script>

<style scoped>

</style>
