import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/img/fakeDoughnut.png'


const _withScopeId = (n: any) => (_pushScopeId("data-v-4e3895e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "fakeDoughnut",
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  class: "centerText"
}
const _hoisted_4 = { class: "centerLabel" }
const _hoisted_5 = { class: "centerNumber" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _mergeProps({ ref: "chart" }, _ctx.$attrs), null, 16),
    (_ctx.showFakeDoughnut)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.centerLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.centerLabel), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$filters.currency(_ctx.centerNumber)), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}