import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-group"
}
const _hoisted_2 = {
  key: 0,
  value: ""
}
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  class: "form-group"
}
const _hoisted_5 = {
  key: 0,
  value: ""
}
const _hoisted_6 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.hideProject)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Progetto",
              text: "Progetto"
            })
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localProject) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:project', _ctx.localProject)))
          }, [
            (_ctx.showAllOption)
              ? (_openBlock(), _createElementBlock("option", _hoisted_2, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Tutti", "Tutti")), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (item, i) => {
              return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item), 9, _hoisted_3))
            }), 256))
          ], 544), [
            [_vModelSelect, _ctx.localProject]
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hideCountry)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Nazione",
              text: "Nazione"
            })
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localCountry) = $event)),
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:country', _ctx.localCountry)))
          }, [
            (_ctx.showAllOption)
              ? (_openBlock(), _createElementBlock("option", _hoisted_5, _toDisplayString(_ctx.$localizationService.GetLocalizedValue("Tutte", "Tutte")), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (item, i) => {
              return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item), 9, _hoisted_6))
            }), 256))
          ], 544), [
            [_vModelSelect, _ctx.localCountry]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}