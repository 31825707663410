<template>
<div class="opModal">
    <div class="midTitle text-center">
        <localized-text localizedKey="Errore" text="Errore"></localized-text>        
    </div>
    <div class="body text-center mt-2">
        <div class="mb-4" v-for="(value, key) in errors">
            <ul>
                {{key}}:
                <li v-for="(item, i) in value" :key="i">
                    {{item}}
                </li>
            </ul>
        </div>
        <div class="mt-4 mw-700">
            <button type="button" ref="okButton" class="btn btn-block btn-primary" 
                @click="$emit('close')" @keydown.space="$emit('close')" @keydown.enter="$emit('close')">
                <span>
                    <localized-text localizedKey="Chiudi" text="Chiudi"></localized-text>        
                </span>
            </button>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class validationErrorModal extends Vue {

    @Prop() errors: { [key: string]: [] }[];

}
</script>
<style scoped>

</style>