<template>
    <div class="opModal">
        <h4>
            <localized-text localizedKey="Risposte questionario" text="Risposte questionario"></localized-text>
        </h4>
        <div class="row" v-if="answers">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Progetto" text="Progetto"></localized-text>
                    </label>
                    <span class="form-control">{{answers.project}}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Nazione" text="Nazione"></localized-text>
                    </label>
                    <span class="form-control">{{answers.country}}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Dottore" text="Dottore"></localized-text>
                    </label>
                    <span class="form-control">{{answers.doctor.name}}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Professione" text="Professione"></localized-text>
                    </label>
                    <span class="form-control">{{answers.profession.name}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Cliente" text="Cliente"></localized-text>
            </label>
            <span class="form-control">{{answers.customer.name}}</span>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Completato" text="Completato"></localized-text>
            </label>
            <span class="form-control">
                <localized-text localizedKey="Si" text="Si" v-if="answers.isCompleted"></localized-text>
                <localized-text localizedKey="No" text="No" v-else></localized-text>
            </span>
        </div>
        <op-table class="table table-striped" :items="answers.answerValues" v-slot:default="opData" :filtersFn="filtersFn">
            <thead>
                <tr>
                    <td filter="domanda.name" sort="domanda.name"><localized-text localizedKey="Domanda" text="Domanda"></localized-text></td>
                    <td filter="answerFilter"><localized-text localizedKey="Risposte" text="Risposte"></localized-text></td>
                </tr>
            </thead>
            <tbody>
                <tr v-if="opData.items.length == 0">
                    <td colspan="99">
                        <localized-text localizedKey="Nessun elemento trovato" text="Nessun elemento trovato"></localized-text>
                    </td>
                </tr>
                <tr v-for="(item, i) in (opData.items as OM.AnswerValue[])" :key="i">
                    <td>{{item.questionText.values[$store.state.selectedLanguage]}}</td>
                    <td>
                        <template v-if="item.selectAnswer.length > 0">
                            {{item.selectAnswer.map(x => x.values[$store.state.selectedLanguage]).join(' - ')}}
                        </template>
                        <template v-else-if="item.textAnswer">
                            {{item.textAnswer}}
                        </template>
                    </td>
                </tr>
            </tbody>
        </op-table>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class surveyAnswersViewerModal extends Vue {
 
    @Prop() answers: OM.SurveyAnswers;

    filtersFn = {
        answerFilter: (item: OM.AnswerValue, input) => {
            return item.selectAnswer.some(x => x.values[this.$store.state.selectedLanguage].toLowerCase().indexOf(input.toLowerCase()) > -1) ||
            item.textAnswer.indexOf(input.toLowerCase()) > -1; 
        }
    }

}

</script>
