import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/ricettario',
        component: () => import("./views/ricettarioNodesConfigurator.vue"),
        meta: { sideMenuLabel: "Ricettario" },
    },
];

export default routes;