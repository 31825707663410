<template>
    <div v-if="user">
        <div class="box">
            <div class="boxTitle">
                <localized-text localizedKey="Stato per progetto" text="Stato per progetto"></localized-text>
            </div>
            <project-statuses-pairs :user="user"></project-statuses-pairs>
        </div>
        <div class="box mt-4">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label>
                            <localized-text localizedKey="Ruolo" text="Ruolo"></localized-text>
                        </label>
                        <select class="form-control" v-model="user.role">
                            <option v-for="(item, i) in roles" :value="item">{{item}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label>
                            <localized-text localizedKey="Lista" text="Lista"></localized-text>
                        </label>
                        <select class="form-control" v-model="user.list">
                            <option v-for="item in lists" :value="item.value">{{item.value}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label>
                            <localized-text localizedKey="Fonte" text="Fonte"></localized-text>
                        </label>
                        <input type="text" class="form-control" v-model="user.source" />
                    </div>
                </div>
                <div class="col-sm-1">
                    <div class="form-group">
                        <label>
                            <localized-text localizedKey="Lavorato" text="Lavorato"></localized-text>
                        </label>
                        <input type="checkbox" class="form-control" v-model="user.lavorato" />
                    </div>
                </div>
                <div class="col-sm-1">
                    <div class="form-group">
                        <label>
                            <localized-text localizedKey="Chiamato" text="Chiamato"></localized-text>
                        </label>
                        <input type="checkbox" class="form-control" v-model="chiamato" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <accordion class="box mt-4">
                    <template #title>
                        <localized-text class="boxTitle" localizedKey="Anagrafica" text="Anagrafica"></localized-text>
                    </template>
                    <div>
                        <personal-data v-model="user.personalData"></personal-data>
                    </div>
                </accordion>
            </div>
            <div class="col-md-6">
                <accordion class="box mt-4">
                    <template #title>
                        <localized-text class="boxTitle" localizedKey="Dati accesso" text="Dati accesso"></localized-text>
                    </template>
                    <div>
                        <auth-data :user="user" v-model="user.authData"></auth-data>
                    </div>
                </accordion>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <accordion class="box mt-4">
                    <template #title>
                        <localized-text class="boxTitle" localizedKey="Contatto" text="Contatto"></localized-text>
                    </template>
                    <div>
                        <contact-data v-model="user.contactData"></contact-data>
                    </div>
                </accordion>
            </div>
            <div class="col-md-6">
                <accordion class="box mt-4">
                    <template #title>
                        <localized-text class="boxTitle" localizedKey="Fiscali" text="Fiscali"></localized-text>
                    </template>
                    <div>
                        <fiscal-data v-model="user.fiscalData"></fiscal-data>
                    </div>
                </accordion>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <accordion class="box mt-4">
                    <template #title>
                        <localized-text class="boxTitle" localizedKey="Network" text="Network"></localized-text>
                    </template>
                    <div>
                        <network-user-selector v-model="user.networkParentNode" :userIdentifier="user.identifier"></network-user-selector>
                    </div>
                    <div>
                        <parent-node-history v-model="user.networkParentNodeHistory"></parent-node-history>
                    </div>
                </accordion>
            </div>
            <div class="col-md-6">
                <accordion class="box mt-4">
                    <template #title>
                        <localized-text class="boxTitle" localizedKey="Qualifica" text="Qualifica"></localized-text>
                    </template>
                    <div>
                        <qualification v-model="user"></qualification>
                    </div>
                </accordion>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <accordion class="box mt-4">
                    <template #title>
                        <localized-text class="boxTitle" localizedKey="Commenti" text="Commenti"></localized-text>
                    </template>
                    <div>
                        <commenti :user="user"></commenti>
                    </div>
                </accordion>
            </div>
            <div class="col-md-6">
                <accordion class="box mt-4">
                    <template #title>
                        <localized-text class="boxTitle" localizedKey="Note" text="Note"></localized-text>
                    </template>
                    <div>
                        <note :user="user"></note>
                    </div>
                </accordion>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="box mt-4 mb-3" v-if="$store.state.isAdmin()">
                    <accordion>
                        <template #title>
                            <div class="boxTitle">
                                <localized-text localizedKey="Professionisti assegnati" text="Professionisti assegnati"></localized-text>
                            </div>
                        </template>
                        <div class="form-group">
                            <professionisti-assegnati v-model="user.professionistiAssegnati"></professionisti-assegnati>
                        </div>
                    </accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import { BackendRoleConfigClient, LeadClient, ProjectConfigClient } from '@/services/Services';
import store from '@/store';
import { ModalServices } from '@/services/ModalServices';
import { Prop } from 'vue-property-decorator';
import qualification from '@/components/user/components/qualification.vue';

@Options({
    components: {
        qualification
    }
})
export default class baseUserData extends Vue {
 
    @Prop() user: OM.Lead;
    
    projects: string[] = CONST.ProjectNames.GetAll();
    statuses: { [key: string] :string[] } = {};
    subStatuses: { [key: string] :string[] } = {};
    countries: { [key: string] :string[] } = {};
    lists: OM.LeadList[] = [];
    roles: string[] = [];

    created(){
        BackendRoleConfigClient.getRoles()
        .then(x => {
            this.roles = x;
        })
        LeadClient.getLists()
        .then(x => {
            this.lists = x;
        })

        this.projects.forEach(proj => {
            ProjectConfigClient.getProjectConfig(proj)
            .then(x => {
                this.statuses[proj] = x.leadStatuses;
                this.subStatuses[proj] = x.leadSubStatuses;
                this.countries[proj] = x.countryConfigList.map(x => x.alpha2Code);
            })
        })
    }


    get chiamato(){
        return !!this.user.chiamato;
    }
    set chiamato(value){
        if(value){
            this.user.chiamato = new Date().toISOString();
        } else {
            this.user.chiamato = null;
        }
    }

    addProjectToPairs(project: string){
        if(!this.user.projectCountryPairs[project]){
            this.user.projectCountryPairs[project] = "";
        }
        if(!this.user.projectStatusesPair[project]){
            this.user.projectStatusesPair[project] = new OM.Statuses();
        }
    }

}

</script>
