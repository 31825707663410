<template>
<div class="opModal">
    <div class="header" v-if="title">
        <h4 v-html="title"></h4>
    </div>
    <div class="body">
        <div class="text-center">
            <span v-html="text"></span>
        </div>
        <div class="mt-2">
            <input type="text" ref="inputEl" class="form-control" :placeholder="placeholder" v-model="input" />
        </div>
        <div class="mt-5 mw-700">

            <button type="button" ref="okButton" class="btn w-100 btn-primary" 
                @click="ok" @keydown.space="ok" @keydown.enter="ok">
                <span v-html="okText"></span>
            </button>

            <button type="button" class="btn w-100 btn-hollow mt-2" @click="ko">
                <span v-html="koText"></span>
            </button>

        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class ConfirmModal extends Vue {

    @Prop() title: string;
    @Prop() text: string;
    @Prop({
        default: 'OK'
    }) okText: string;
    @Prop({
        default: 'Annulla'
    }) koText: string;
    @Prop({
        default: 'Scrivi qui'
    }) placeholder: string;
    @Prop() value: string;
    @Prop() okCallback: (input: string) => Promise<any>;
    @Prop() koCallback: () => Promise<any>;

    input: string = "";

    created(){
        if(this.value){
            this.input = this.value;
        }
    }

    async ok(){
        if(this.okCallback){
            await this.okCallback(this.input);
        }
        this.$emit('close');
    }

    async ko(){
        if(this.koCallback){
            await this.koCallback();
        }
        this.$emit('close');
    }

    mounted(){
        (<HTMLButtonElement>this.$refs.inputEl).focus();
    }

}
</script>
<style scoped>

</style>