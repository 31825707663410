import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store';
import layoutRoutes from '@/views/layout/routes';
import loginRoutes from '@/views/login/routes';



let _routes: RouteRecordRaw[] = [];
_routes = _routes.concat(loginRoutes);
_routes = _routes.concat(layoutRoutes);





let _router = createRouter({
	history: createWebHashHistory(),
	routes: _routes,
	scrollBehavior: (to, from, savedPosition) => {
		window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
	}
});

_router.beforeEach((to, from, next) => {
	if(store.state.beforeNavCbs.length == 0){
		next();
	} else {
		store.state.beforeNavCbs.forEach(x => {
			x(to, from);
		});
		store.state.beforeNavCbs = [];
		next();
	}
})

_router.afterEach((to, from) => {
	store.state.afterNavCbs.forEach(x => {
		x(to, from);
	});
	store.state.afterNavCbs = [];
})

export default _router;