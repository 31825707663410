import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/smsSentLog',
        name: 'smsSentLog',
        meta: { sideMenuLabel: "Log sms" },
        component: () => import("./views/smsSentLog.vue")
    },
];

export default routes;