import { CommonServices } from "@/services/CommonServices";
import { apiurl} from '@/config';
import * as OM from '@/model';
import * as VM from '@/viewmodel';

class _UploadServices {

    UploadImage(file: File){
        return CommonServices.uploadFileToUrl<OM.Attachment>(apiurl + "api/Upload/UploadImage", {
            file: file
        })
    }

    UploadUserImage(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl(apiurl + 'api/Upload/UploadUserImage', vm);
    }

    UploadFile(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<OM.Attachment>(apiurl + "api/Upload/UploadFile", vm)
    }

}

export let UploadServices = new _UploadServices();
