import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/purchases',
        name: 'purchases',
        meta: { sideMenuLabel: "Acquisti" },
        component: () => import("./views/purchases.vue")
    },
    {
        path: '/purchaseDetail/:identifier',
        name: 'purchaseDetail',
        component: () => import("./views/purchaseDetail.vue")
    },
];

export default routes;