import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "title text-center mt-3 mb-2" }
const _hoisted_11 = { class: "lowTitle text-center mb-3" }
const _hoisted_12 = { class: "text-center d-flex justify-content-around mt-4" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_project_country_selector = _resolveComponent("project-country-selector")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_answer_value = _resolveComponent("answer-value")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_project_country_selector, {
      project: _ctx.surveyAnswers.project,
      "onUpdate:project": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.surveyAnswers.project) = $event)),
      country: _ctx.surveyAnswers.country,
      "onUpdate:country": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.surveyAnswers.country) = $event))
    }, null, 8, ["project", "country"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Professione",
          text: "Professione"
        })
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surveyAnswers.profession) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.professioni, (item) => {
          return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item.name), 9, _hoisted_3))
        }), 256))
      ], 512), [
        [_vModelSelect, _ctx.surveyAnswers.profession]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Dottore",
          text: "Dottore"
        })
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.surveyAnswers.doctor) = $event)),
        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.surveyAnswers.surveyType = null)),
        disabled: !_ctx.surveyAnswers.profession
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doctors, (item) => {
          return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item.name), 9, _hoisted_6))
        }), 256))
      ], 40, _hoisted_5), [
        [_vModelSelect, _ctx.surveyAnswers.doctor]
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Tipo questionario",
          text: "Tipo questionario"
        })
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.surveyAnswers.surveyType) = $event)),
        onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.init && _ctx.init(...args))),
        disabled: !_ctx.surveyAnswers.doctor
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.surveyTypes, (item) => {
          return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item), 9, _hoisted_9))
        }), 256))
      ], 40, _hoisted_8), [
        [_vModelSelect, _ctx.surveyAnswers.surveyType]
      ])
    ]),
    (_ctx.questionario)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_localized_text, {
              localizedKey: "Questionario interno",
              text: "Questionario interno"
            })
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_localized_text, {
              localizedKey: "Domanda numero:",
              text: "Domanda numero:"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.currentQuestion + 1) + " / " + _toDisplayString(_ctx.questionario.questions.length), 1)
          ]),
          _createVNode(_component_answer_value, {
            config: _ctx.currentDomandaConfig,
            "answer-value": _ctx.tempAnswerValue
          }, null, 8, ["config", "answer-value"]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary me-1",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args))),
              disabled: _ctx.currentQuestion == 0
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "Indietro",
                text: "Indietro"
              })
            ], 8, _hoisted_13),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary ms-1",
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
              disabled: !_ctx.canNext
            }, [
              (_ctx.currentQuestion < _ctx.questionario.questions.length - 1)
                ? (_openBlock(), _createBlock(_component_localized_text, {
                    key: 0,
                    localizedKey: "Avanti",
                    text: "Avanti"
                  }))
                : (_openBlock(), _createBlock(_component_localized_text, {
                    key: 1,
                    localizedKey: "Fine",
                    text: "Fine"
                  }))
            ], 8, _hoisted_14)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}