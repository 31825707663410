import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "scrollListContainer",
  ref: "scrollListContainer"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "scrollListItem",
          key: i
        }, [
          _createElementVNode("div", null, [
            (i < _ctx.localValue.length - 1)
              ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_ctx.localValue[i + 1].value.assigned.name) + " → ", 1))
              : _createCommentVNode("", true),
            _createElementVNode("b", null, _toDisplayString(item.value.assigned && item.value.assigned.name), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_localized_text, {
                localizedKey: item.value.reason,
                text: item.value.reason
              }, null, 8, ["localizedKey", "text"])
            ])
          ]),
          _createElementVNode("div", null, _toDisplayString(_ctx.$filters.date(item.date, "DD/MM/yyyy HH:mm")), 1)
        ]))
      }), 128))
    ], 512)
  ]))
}