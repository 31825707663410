import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "boxTitle" }
const _hoisted_4 = { class: "box mt-4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-sm-3" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "col-sm-3" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "col-sm-3" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "col-sm-1" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "col-sm-1" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-md-6" }
const _hoisted_20 = { class: "col-md-6" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-md-6" }
const _hoisted_23 = { class: "col-md-6" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-md-6" }
const _hoisted_26 = { class: "col-md-6" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-md-6" }
const _hoisted_29 = { class: "col-md-6" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-md-6" }
const _hoisted_32 = {
  key: 0,
  class: "box mt-4 mb-3"
}
const _hoisted_33 = { class: "boxTitle" }
const _hoisted_34 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_project_statuses_pairs = _resolveComponent("project-statuses-pairs")!
  const _component_personal_data = _resolveComponent("personal-data")!
  const _component_accordion = _resolveComponent("accordion")!
  const _component_auth_data = _resolveComponent("auth-data")!
  const _component_contact_data = _resolveComponent("contact-data")!
  const _component_fiscal_data = _resolveComponent("fiscal-data")!
  const _component_network_user_selector = _resolveComponent("network-user-selector")!
  const _component_parent_node_history = _resolveComponent("parent-node-history")!
  const _component_qualification = _resolveComponent("qualification")!
  const _component_commenti = _resolveComponent("commenti")!
  const _component_note = _resolveComponent("note")!
  const _component_professionisti_assegnati = _resolveComponent("professionisti-assegnati")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_localized_text, {
              localizedKey: "Stato per progetto",
              text: "Stato per progetto"
            })
          ]),
          _createVNode(_component_project_statuses_pairs, { user: _ctx.user }, null, 8, ["user"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Ruolo",
                    text: "Ruolo"
                  })
                ]),
                _withDirectives(_createElementVNode("select", {
                  class: "form-control",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.role) = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (item, i) => {
                    return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item), 9, _hoisted_8))
                  }), 256))
                ], 512), [
                  [_vModelSelect, _ctx.user.role]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Lista",
                    text: "Lista"
                  })
                ]),
                _withDirectives(_createElementVNode("select", {
                  class: "form-control",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.list) = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, (item) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: item.value
                    }, _toDisplayString(item.value), 9, _hoisted_11))
                  }), 256))
                ], 512), [
                  [_vModelSelect, _ctx.user.list]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Fonte",
                    text: "Fonte"
                  })
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.source) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.user.source]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Lavorato",
                    text: "Lavorato"
                  })
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.lavorato) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _ctx.user.lavorato]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", null, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Chiamato",
                    text: "Chiamato"
                  })
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.chiamato) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _ctx.chiamato]
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_accordion, { class: "box mt-4" }, {
              title: _withCtx(() => [
                _createVNode(_component_localized_text, {
                  class: "boxTitle",
                  localizedKey: "Anagrafica",
                  text: "Anagrafica"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_personal_data, {
                    modelValue: _ctx.user.personalData,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.personalData) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_accordion, { class: "box mt-4" }, {
              title: _withCtx(() => [
                _createVNode(_component_localized_text, {
                  class: "boxTitle",
                  localizedKey: "Dati accesso",
                  text: "Dati accesso"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_auth_data, {
                    user: _ctx.user,
                    modelValue: _ctx.user.authData,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.authData) = $event))
                  }, null, 8, ["user", "modelValue"])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_accordion, { class: "box mt-4" }, {
              title: _withCtx(() => [
                _createVNode(_component_localized_text, {
                  class: "boxTitle",
                  localizedKey: "Contatto",
                  text: "Contatto"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_contact_data, {
                    modelValue: _ctx.user.contactData,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.contactData) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_accordion, { class: "box mt-4" }, {
              title: _withCtx(() => [
                _createVNode(_component_localized_text, {
                  class: "boxTitle",
                  localizedKey: "Fiscali",
                  text: "Fiscali"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_fiscal_data, {
                    modelValue: _ctx.user.fiscalData,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.user.fiscalData) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_accordion, { class: "box mt-4" }, {
              title: _withCtx(() => [
                _createVNode(_component_localized_text, {
                  class: "boxTitle",
                  localizedKey: "Network",
                  text: "Network"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_network_user_selector, {
                    modelValue: _ctx.user.networkParentNode,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.user.networkParentNode) = $event)),
                    userIdentifier: _ctx.user.identifier
                  }, null, 8, ["modelValue", "userIdentifier"])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_parent_node_history, {
                    modelValue: _ctx.user.networkParentNodeHistory,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.user.networkParentNodeHistory) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_accordion, { class: "box mt-4" }, {
              title: _withCtx(() => [
                _createVNode(_component_localized_text, {
                  class: "boxTitle",
                  localizedKey: "Qualifica",
                  text: "Qualifica"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_qualification, {
                    modelValue: _ctx.user,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createVNode(_component_accordion, { class: "box mt-4" }, {
              title: _withCtx(() => [
                _createVNode(_component_localized_text, {
                  class: "boxTitle",
                  localizedKey: "Commenti",
                  text: "Commenti"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_commenti, { user: _ctx.user }, null, 8, ["user"])
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_accordion, { class: "box mt-4" }, {
              title: _withCtx(() => [
                _createVNode(_component_localized_text, {
                  class: "boxTitle",
                  localizedKey: "Note",
                  text: "Note"
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_note, { user: _ctx.user }, null, 8, ["user"])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            (_ctx.$store.state.isAdmin())
              ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                  _createVNode(_component_accordion, null, {
                    title: _withCtx(() => [
                      _createElementVNode("div", _hoisted_33, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Professionisti assegnati",
                          text: "Professionisti assegnati"
                        })
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_professionisti_assegnati, {
                          modelValue: _ctx.user.professionistiAssegnati,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.user.professionistiAssegnati) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}