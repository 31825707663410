<template>
    <div class="opModal">
        <div class="midTitle mb-2">
            <localized-text localizedKey="Nuova azione" text="Nuova azione"></localized-text>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Data" text="Data"></localized-text>
            </label>
            <op-date class="form-control" v-model="vm.startDate"></op-date>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Ora" text="Ora"></localized-text>
            </label>
            <op-time class="form-control" v-model="startTime"></op-time>
        </div>
        <div class="my-4 d-flex justify-content-around">
            <button class="btn btn-hollow" @click="vm.type = 'Whatsapp'" :class="{ 'btn-primary': vm.type == 'Whatsapp' }">
                <localized-text localizedKey="Whatsapp" text="Whatsapp"></localized-text>
            </button>
            <button class="btn btn-hollow" @click="vm.type = 'Chiamata'" :class="{ 'btn-primary': vm.type == 'Chiamata' }">
                <localized-text localizedKey="Chiamata" text="Chiamata"></localized-text>
            </button>
            <button class="btn btn-hollow" @click="vm.type = 'Check'" :class="{ 'btn-primary': vm.type == 'Check' }">
                <localized-text localizedKey="Check" text="Check"></localized-text>
            </button>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Tipo azione" text="Tipo azione"></localized-text>
            </label>
            <input type="text" class="form-control" v-model="vm.type" />
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Note e allegati" text="Note e allegati"></localized-text>
            </label>
            <new-comment-form v-model="addCommentVm">
                <span></span>
            </new-comment-form>
        </div>

        <div class="text-end mt-4">
            <button class="btn btn-primary" @click="save" :disabled="!canSave">
                <localized-text localizedKey="Salva" text="Salva"></localized-text>
            </button>
            <button class="btn btn-danger ms-2" @click="$emit('close')">
                <localized-text localizedKey="Chiudi" text="Chiudi"></localized-text>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model'
import * as VM from '@/viewmodel'
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient } from '@/services/Services';
import { BaseUserServices } from '@/services/BaseUserServices';

@Options({})
export default class newToDoModal extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() project: string;
    @Prop() onDone: (x: OM.ToDo) => void; 

    vm: VM.SaveToDoVm = new VM.SaveToDoVm();
    startTime: string = "";
    addCommentVm: VM.AddCommentVm = new VM.AddCommentVm();

    created(){
        this.vm.leadIdentifier = this.user.identifier;
        this.vm.project = this.project;
    }

    get canSave(){
        return this.vm.startDate && this.vm.type;
    }

    @Watch('startTime')
    startTimeChange(){
        if(!this.startTime)
            return;
        let splitted = this.startTime.split(':');
        if(splitted.length < 2)
            return;

        let tempDate = new Date(this.vm.startDate)
        tempDate.setHours(parseInt(splitted[0]));
        tempDate.setMinutes(parseInt(splitted[1]));
        tempDate.setSeconds(0);
        this.vm.startDate = tempDate.toISOString();
    }

    save(){
        this.vm.text = this.addCommentVm.text;
        this.vm.allegati = this.addCommentVm.fileList;
        BaseUserServices.saveToDo(this.vm)
        .then(x => {
            this.onDone(x);
            this.$emit('close');
        })
    }

}
</script>
<style scoped>

</style>
