import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/defaultPlanWeeks',
        name: 'defaultPlanWeeks',
        meta: { sideMenuLabel: "Settimane Piani alimentari" },
        component: () => import("./views/defaultPlanWeeks.vue")
    },
    {
        path: '/defaultPlanWeekDetail/:identifier',
        name: 'defaultPlanWeekDetail',
        component: () => import("./views/defaultPlanWeekDetail.vue")
    },
];

export default routes;