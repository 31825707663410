<template>
    <div class="fs-22 text-center mb-2">
        <localized-text localizedKey="Menu giornaliero" text="Menu giornaliero"></localized-text>
    </div>
    <op-table class="table table-striped" :items="items" v-slot:default="opData">
        <thead>
            <tr>
                <td filter="numeroGiornata" sort="numeroGiornata" width="140"><localized-text localizedKey="N° Giornata" text="N° Giornata"></localized-text></td>
                <td filter="note" sort="note" width="180"><localized-text localizedKey="Note" text="Note"></localized-text></td>
                <td></td>
                <td width="100" class="align-bottom text-right" v-if="$store.state.isDoctor() || $store.state.isAdmin()">
                    <button type="button" class="btn btn-success" @click="addItem">
                        Aggiungi
                    </button>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr v-if="opData.items.length == 0">
                <td colspan="99">
                    <localized-text localizedKey="Nessun elemento trovato" text="Nessun elemento trovato"></localized-text>
                </td>
            </tr>
            <tr v-for="(item, i) in (opData.items as OM.MenuStep[])" :key="i">
                <td>
                    <div>{{item.dayNumber}}</div>
                    <div v-if="readonly">{{$filters.date(item.date, 'dddd DD MMM')}}</div>
                    <div v-else>
                        <op-date v-model="item.date"></op-date>
                    </div>
                </td>
                <td>
                    <span v-if="readonly" class="form-control">{{item.note}}</span>
                    <input v-else type="text" class="form-control" v-model="item.note" />
                </td>
                <td>
                    <div class="d-flex justify-content-around">
                        <menu-entry class="w-25" :readonly="readonly" v-for="(entry, ii) in item.entries" :key="i" :entry="entry" @remove="removeThis(ii, item)"></menu-entry>
                        <div class="p-3">
                            <button class="btn btn-success" @click="addEntry(item)">
                                +
                            </button>
                        </div>
                    </div>
                </td>
                <td class="text-right" v-if="$store.state.isDoctor() || $store.state.isAdmin()">
                    <button v-if="!readonly" type="button" class="btn btn-sm btn-danger" @click="removeItem(i)">
                        Rimuovi
                    </button>
                </td>
            </tr>
        </tbody>
    </op-table>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';

@Options({})
export default class menuStepTable extends Vue {

    @Prop() items: OM.MenuStep[];
    @Prop() readonly: boolean;

    addItem(){
        let toPush = new OM.MenuStep();
        toPush.dayNumber = this.items.length + 1;
        toPush.entries = [
            { dayPeriod: 'Colazione', htmlText: '' },
            { dayPeriod: 'MetaMattina', htmlText: '' },
            { dayPeriod: 'Pranzo', htmlText: '' },
            { dayPeriod: 'Merenda', htmlText: '' },
            { dayPeriod: 'Cena', htmlText: '' },
            { dayPeriod: 'SpuntinoSerale', htmlText: '' },
        ]

        if(this.items[this.items.length - 1] && this.items[this.items.length - 1].date){
            toPush.date = moment(this.items[this.items.length - 1].date).add(1, 'day').toISOString();
        }

        this.items.push(toPush);
    }

    removeItem(index: number){
        this.items.splice(index, 1);
    }

    removeThis(i: number, item: OM.MenuStep){
        item.entries.splice(i, 1);
    }

    addEntry(item: OM.MenuStep){
        item.entries.push(
            { dayPeriod: 'NUOVO', htmlText: '' }
        )
    }

}

</script>
<style scoped>
</style>
