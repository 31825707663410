<template>
    <td>
        <localized-text v-if="$store.state.user.identifier == item.dottore.identifier"
            localizedKey="Hai ricevuto una nuova prenotazione! Cliente: <b>{{nomeCliente}}</b>"
            text="Hai ricevuto una nuova prenotazione! Cliente: <b>{{nomeCliente}}</b>"
            :object="{ nomeCliente: item.cliente.name }">
        </localized-text>
        <localized-text v-else
            localizedKey="Il cliente {{nomeCliente}} ha prenotato con {{nomeDottore}}"
            text="Il cliente {{nomeCliente}} ha prenotato con {{nomeDottore}}"
            :object="{ nomeCliente: item.cliente.name, nomeDottore: item.dottore.name }">
        </localized-text>
    </td>
    <td class="text-end">
        <router-link class="btn btn-link" :to="'/bookingDetail/' + item.prenotazioneIdentifier">
            <localized-text localizedKey="Vedi prenotazione" text="Vedi prenotazione"></localized-text>
        </router-link>
    </td>
</template>
<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BackendNotificationClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class prenotazioneRicevuta extends Vue {

    @Prop() item: OM.PrenotazioneRicevuta;

}

</script>
