import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/qualficationConfigs',
        name: 'qualficationConfigs',
        meta: { sideMenuLabel: "Config. Qualifiche" },
        component: () => import("./views/qualficationConfigs.vue")
    },
];

export default routes;