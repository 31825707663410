import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "comment" }
const _hoisted_2 = { class: "commentBody" }
const _hoisted_3 = { class: "commentAuthor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_comment_text = _resolveComponent("comment-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.comment.author.name), 1),
      _createVNode(_component_comment_text, { comment: _ctx.comment }, null, 8, ["comment"]),
      (_ctx.canRemove)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "commentRemover",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeComment && _ctx.removeComment(...args)), ["stop"]))
          }, " x "))
        : _createCommentVNode("", true)
    ])
  ]))
}