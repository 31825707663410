import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/messagesOfTheDay',
        name: 'messagesOfTheDay',
        meta: { sideMenuLabel: "Messaggi del giorno" },
        component: () => import("./views/messagesOfTheDay.vue")
    },
];

export default routes;