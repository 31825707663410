<template>
    <div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Nome" text="Nome"></localized-text>
            </label>
            <input type="text" class="form-control" v-model="localValue.name" />
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Cognome" text="Cognome"></localized-text>
            </label>
            <input type="text" class="form-control" v-model="localValue.surname" />
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Sesso" text="Sesso"></localized-text>
            </label>
            <div>
                <button class="btn btn-link" :class="{ active: localValue.sesso == 'M'}" @click="localValue.sesso = 'M'">
                    <localized-text localizedKey="Maschio" text="Maschio"></localized-text>
                </button>
                <button class="btn btn-link" :class="{ active: localValue.sesso == 'F'}" @click="localValue.sesso = 'F'">
                    <localized-text localizedKey="Femmina" text="Femmina"></localized-text>
                </button>
            </div>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Url immagine profilo" text="Url immagine profilo"></localized-text>
            </label>
            <input type="text" class="form-control" v-model="localValue.profilePicture" />
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model'
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class personalData extends mixins(WithModel) {

    declare localValue: OM.PersonalData;
    @Prop() readonly: boolean;

    isMaschio: boolean = false;

}
</script>
<style scoped>
</style>
