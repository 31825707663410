<template>
    <div class="comments">
        <comment :comment="item" v-for="(item, i) in comments" :key="i" @remove="remove" :removable="removable" :always-removable="alwaysRemovable"></comment>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Comments extends Vue {

    @Prop() comments: OM.Comment[];
    @Prop() leadIdentifier: string;
    @Prop() removable: boolean;
    @Prop() alwaysRemovable: boolean;
    @Prop() removeCb: (item: OM.Comment) => void;

    remove(item: OM.Comment){
        if(this.removeCb){
            this.removeCb(item);
        }
    }

}

</script>

<style scoped>
</style>