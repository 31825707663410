import { RouteRecordRaw } from 'vue-router';

export default [
    {
        path: '/',
        name: 'login',
        component: () => import("./views/login.vue")
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import("./views/register.vue")
    // },
    {
        path: '/confirmEmail/:userIdentifier/:code/:project',
        name: 'confirmEmail',
        component: () => import("./views/confirmEmail.vue")
    },
    {
        path: '/requestResetPassword',
        name: 'requestResetPassword',
        component: () => import("./views/requestResetPassword.vue")
    },
    {
        path: '/resetPassword/:userEmail/:token/:project',
        name: 'resetPassword',
        component: () => import("./views/resetPassword.vue")
    },
];