<template>
    <div>
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Professione" text="Professione"></localized-text>
                </label>
                <select class="form-control" v-model="tempProfessione" @change="setProfessione" v-if="!localValue.professione || !localValue.professione.name">
                    <option v-for="(professione, i) in availableProfessioni" :key="i" :value="professione">{{professione.name}}</option>
                </select>
                <span class="form-control" v-else>
                    {{localValue.professione.name}}
                    <button type="button" class="btn btn-sm btn-danger" @click="localValue.professione = null"> X </button>
                </span>
            </div>
        <template v-if="localValue.professione && localValue.professione.identifier">
            <localized-value v-slot:default="slotData">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Qualifica professionale" text="Qualifica professionale"></localized-text>
                    </label>
                    <input type="text" class="form-control" v-model="localValue.qualificaProfessionale.values[slotData.selectedLanguage]" />
                </div>
            </localized-value>
            <localized-value v-slot:default="slotData">
                <div class="form-group">
                    <label>
                        <localized-text localizedKey="Descrizione" text="Descrizione"></localized-text>
                    </label>
                    <auto-textarea class="form-control" v-model="localValue.description.values[slotData.selectedLanguage]"></auto-textarea>
                </div>
            </localized-value>
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Numero ordine" text="Numero ordine"></localized-text>
                </label>
                <input type="text" class="form-control" v-model="localValue.numeroOrdine" />
            </div>
            <polizza-data :value="localValue.datiPolizza"></polizza-data>

            <div class="form-group">
                <label>
                    <localized-text localizedKey="Immagine firma" text="Immagine firma"></localized-text>
                </label>
                <div v-if="!user.identifier">
                    <localized-text localizedKey="Salvare una volta prima di poter caricare l'immagine" text="Salvare una volta prima di poter caricare l'immagine"></localized-text>
                </div>
                <file-previewer v-else :preview="localValue.firma && localValue.firma.publicUrl" v-model="fileFirma"></file-previewer>
                <div class="text-center">
                    <button type="button" class="btn btn-secondary mt-1" :disabled="!fileFirma" @click="uploadFirma">
                        <localized-text localizedKey="Carica immagine (ricordati di salvare dopo)" text="Carica immagine (ricordati di salvare dopo)"></localized-text>
                    </button>
                </div>  
            </div>
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Immagine timbro" text="Immagine timbro"></localized-text>
                </label>
                <div v-if="!user.identifier">
                    <localized-text localizedKey="Salvare una volta prima di poter caricare l'immagine" text="Salvare una volta prima di poter caricare l'immagine"></localized-text>
                </div>
                <file-previewer v-else :preview="localValue.timbro && localValue.timbro.publicUrl" v-model="fileTimbro"></file-previewer>
                <div class="text-center">
                    <button type="button" class="btn btn-secondary mt-1" :disabled="!fileTimbro" @click="uploadTimbro">
                        <localized-text localizedKey="Carica immagine (ricordati di salvare dopo)" text="Carica immagine (ricordati di salvare dopo)"></localized-text>
                    </button>
                </div>  
            </div>
        </template>
        
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { mixins, Options, Vue } from 'vue-class-component';
import { ProfessionConfigurationClient } from '@/services/Services';
import { UploadServices } from '@/services/UploadServices';
import WithModel from '@/mixins/withModel';

@Options({})
export default class dottoreData extends mixins(WithModel) {

    @Prop() user: OM.Doctor;

    declare localValue: OM.DottoreData;

    fileFirma: File = null;
    fileTimbro: File = null;
    professioni: OM.NameIdentifier[] = [];
    tempProfessione: OM.NameIdentifier = null;;

    created(){
        ProfessionConfigurationClient.getAllAsNameIdentifier()
        .then(x => {
            this.professioni = x;
        })
    }

    uploadFirma(){
        UploadServices.UploadFile({
            file: this.fileFirma,
            entityIdentifier: this.user.identifier
        })
        .then(x => {
            this.localValue.firma = x;
            this.fileFirma = null;
        })
    }
    uploadTimbro(){
        UploadServices.UploadFile({
            file: this.fileTimbro,
            entityIdentifier: this.user.identifier
        })
        .then(x => {
            this.localValue.timbro = x;
            this.fileTimbro = null;
        })
    }
    
    get availableProfessioni(){
        return this.professioni.filter(x => !this.user.dottoreDatas.map(x => x.professione).some(c => c && c.identifier == x.identifier));
    }

    setProfessione(){
        this.localValue.professione = this.tempProfessione;
        this.tempProfessione = null;
    }
    
}
</script>
<style scoped>
</style>
