<template>
    <input type="text" placeholder="hh:mm" v-model="localValue" @blur="parseAndEmit" />
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { mixins, Options, Vue } from "vue-class-component";
import WithModel from "@/mixins/withModel";

@Options({})
export default class opTime extends mixins(WithModel) {

    declare localValue: string;

    parseAndEmit(){
        let ora = "00";
        let minuti = "00";

        if (this.localValue.length > 4) {
            this.localValue = this.localValue.replace(/[^0-9]+/g, ":");
            let splitted = this.localValue.split(":");
            if(splitted.length > 2){
                ora = "00";
                minuti = "00";
            } else {
                ora = splitted[0];
                minuti = splitted[1];
            }
        } else {
            if (this.localValue.length <= 2) {
                if (this.localValue.length == 1) {
                    this.localValue = "0" + this.localValue;
                }
                ora = this.localValue.substring(0, 2);
            } else {
                minuti = this.localValue.substring(this.localValue.length - 2);
                ora = this.localValue.substring(0, this.localValue.length - 2);
            }
        }
            
        this.emitModel(ora + ":" + minuti);
    }

}
</script>

<style scoped>

</style>