<template>
    <div class="form-group">
        <slot :selectedLanguage="selectedLanguage"  />
        <div class="d-flex align-items-center mt-1">
            <b class="me-1">Translations:</b>
            <button type="button" class="btn btn-small btn-link me-1" :class="{ active: selectedLanguage == lang }"
                v-for="(lang, i) in languages" :key="i" @click="selectedLanguage = lang">
                {{lang}}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';
import { Prop, Watch } from 'vue-property-decorator';
import { insertAllLanguages } from '@/utils';
import store from '@/store';
import { LocalizedClient } from '@/services/Services';
import WithModel from '@/mixins/withModel';

@Options({})
export default class localizedValue extends mixins(WithModel) {

    @Prop() language: string;

    declare localValue: OM.LocalizedValue
    
    languages: string[] = [];
    selectedLanguage: string = "";
    saving: boolean = false;

    @Watch('language')
    onLanguageChange(next, prev){
        this.selectedLanguage = this.language;
    }

    created(){
        if(this.language){
            this.selectedLanguage = this.language;
        }
        insertAllLanguages(this.localValue);
        this.languages = store.state.consts.enabledLanguages;
        this.selectedLanguage = this.languages[0];
    }

}
</script>
<style scoped>
</style>