<template>
    <td>
        <localized-text localizedKey="Ti è stato assegnato un ticket: <b>{{oggettoTicket}}</b>" 
            text="Ti è stato assegnato un ticket: <b>{{oggettoTicket}}</b>"
            :object="{ oggettoTicket: item.ticket.name }">
        </localized-text>
    </td>
    <td class="text-end">
        <router-link class="btn btn-link" :to="'/helpTicket/detail/' + item.ticket.identifier">
            <localized-text localizedKey="Vedi ticket" text="Vedi ticket"></localized-text>
        </router-link>
    </td>
</template>

<script lang="ts">
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import { BackendNotificationClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class ticketRicevuto extends Vue {

    @Prop() item: OM.TicketRicevuto;

}

</script>
