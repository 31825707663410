<template>
    <div class="form-group">
        <label v-if="label">{{label}}</label>
        <select class="form-control" v-model="roleFilter">
            <option value="">Filtra per ruolo</option>
            <option v-for="item in roles" :key="item">{{item}}</option>
        </select>
        <input type="text" class="form-control" placeholder="Filtra per nome" v-model="search" />
        <div class="scrollListContainer" v-if="localValue">
            <div class="scrollListItem selected d-flex justify-content-between">
                <span>{{localValue.name}}</span>
                <span class="pointer fs-10" @click="scrollIntoView"><i>(trova nella lista)</i></span>
            </div>
        </div>
        <div class="scrollListContainer" ref="scrollListContainer">
            <div class="scrollListItem pointer" v-for="(item, i) in filteredUsers" :key="i" :ref="item.identifier"
                :class="{ selected: localValue && localValue.identifier == item.identifier }" @click="emitModel(item)">
                {{item.name}}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model'
import { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel'
import { BackendRoleConfigClient, BaseUserClient } from '@/services/Services';

@Options({})
export default class networkUserSelector extends mixins(WithModel) {

    @Prop() label: string;
    @Prop() userIdentifier: string;

    users: OM.NameIdentifier[] = [];
    search: string = "";
    roleFilter: string = "";
    roles: string[] = [];
    scrollListContainer: HTMLDivElement = null;

    created(){
        BackendRoleConfigClient.getRoles()
        .then(x => {
            this.roles = x;
        })
        BaseUserClient.getListForNetwork(this.roleFilter, this.userIdentifier)
        .then(x => {
            this.users = x;
            setTimeout(() => {
                if(this.localValue){
                    let activeElement = this.$refs[this.localValue.identifier][0];
                    this.scrollListContainer.scrollTop = activeElement.offsetTop;
                    this.$refs[this.localValue.identifier][0].scrollIntoView();
                }
                // var rect = activeElement.getBoundingClientRect();
                // var offset = { 
                //     top: rect.top + window.scrollY, 
                //     left: rect.left + window.scrollX, 
                // };
                // console.log(offset);

                // let topPos = offset.top + activeElement.parent().scrollTop() - activeElement.offsetParent().offset().top;
                // console.log(topPos);
                // this.scrollListContainer.scrollTo({top: topPos });
            }, 50);
        })
    }

    mounted(){
        this.scrollListContainer = this.$refs.scrollListContainer as any;
    }

    scrollIntoView(){
        this.$refs[this.localValue.identifier][0].scrollIntoView();
    }

    get filteredUsers(){
        return this.users.filter(x => x.name && x.name.toLowerCase().indexOf(this.search) > -1);
    }

    @Watch('roleFilter')
    onSearchChange(next, prev){
        BaseUserClient.getListForNetwork(this.roleFilter, this.userIdentifier)
        .then(x => {
            this.users = x;
        })
    }

}
</script>
<style scoped>
</style>
