import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "midTitle text-center" }
const _hoisted_3 = { class: "body text-center mt-2" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = { class: "mt-4 mw-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        localizedKey: "Errore",
        text: "Errore"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (value, key) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("ul", null, [
            _createTextVNode(_toDisplayString(key) + ": ", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (item, i) => {
              return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(item), 1))
            }), 128))
          ])
        ]))
      }), 256)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          ref: "okButton",
          class: "btn btn-block btn-primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
          onKeydown: [
            _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('close')), ["space"])),
            _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('close')), ["enter"]))
          ]
        }, [
          _createElementVNode("span", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Chiudi",
              text: "Chiudi"
            })
          ])
        ], 544)
      ])
    ])
  ]))
}