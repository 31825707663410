<template>
    <div class="opModal">
        <div class="midTitle text-center mb-2">
            <localized-text v-if="booking" localizedKey="Sposta prenotazione:" text="Sposta prenotazione:"></localized-text>
            <localized-text v-else localizedKey="Prenota per:" text="Prenota per:"></localized-text>
            {{customer.personalData.completeName}}
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Professione" text="Professione"></localized-text>
            </label>
            <span class="form-control">
                {{voucher.profession.name}}
            </span>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Seleziona dottore" text="Seleziona dottore"></localized-text>
            </label>
            <select class="form-control" v-model="vm.user">
                <option v-for="(item, i) in doctors" :key="i" :value="item">{{item.name}}</option>
            </select>
        </div>

        <div class="buttonsSelector my-4" v-if="$store.state.user.identifier == vm.user.identifier || $store.state.isAdmin()">
            <div class="buttonsTitle">
                <localized-text localizedKey="Modalità prenotazione" text="Modalità prenotazione"></localized-text>
            </div>
            <div class="buttons">
                <button class="button" @click="bookingMode = 'slot'" :class="{ selected: bookingMode == 'slot' }">
                    <localized-text localizedKey="Usa gli slot" text="Usa gli slot"></localized-text>
                </button>
                <button class="button" @click="bookingMode = 'free'" :class="{ selected: bookingMode == 'free' }">
                    <localized-text localizedKey="Data e ora liberi" text="Data e ora liberi"></localized-text>
                </button>
            </div>
        </div>

        <calendar-appuntamenti v-if="bookingMode == 'slot' && vm.user && vm.user.identifier" 
            :doctorIdentifier="vm.user.identifier" :professionIdentifier="voucher.profession.identifier"
            :customer="customer" :hideNewBookingButton="true" :voucher="voucher"
            :booking="booking">
        </calendar-appuntamenti>

        <div v-if="bookingMode == 'free'">
            <div class="form-group">
                <label>
                    <localized-text localizedKey="Data" text="Data"></localized-text>
                </label>
                <op-date class="form-control" v-model="vm.date"></op-date>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>
                            <localized-text localizedKey="Orario inizio" text="Orario inizio"></localized-text>
                        </label>
                        <input type="time" class="form-control" v-model="vm.fasciaOraria.from"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>
                            <localized-text localizedKey="Orario fine" text="Orario fine"></localized-text>
                        </label>
                        <input type="time" class="form-control" v-model="vm.fasciaOraria.to"/>
                    </div>
                </div>
            </div>
            <div class="text-center mt-4">
                <button type="button" class="btn btn-success" @click="makePrenotazione">
                    <localized-text localizedKey="Conferma" text="Conferma"></localized-text>
                </button>
            </div>
        </div>

        <hr/>
        <div class="text-end mt-4">
            <button type="button" class="btn btn-primary" @click="$emit('close')">
                <localized-text localizedKey="Chiudi" text="Chiudi"></localized-text>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model'
import { Options, Vue } from 'vue-class-component';
import { BookingClient, DoctorClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class makeBookingFromVoucherModal extends Vue {

    @Prop() customer: OM.BaseUser;
    @Prop() voucher: OM.BookingVoucher;
    @Prop() booking: OM.BookingForListVm;

    doctors: OM.NameIdentifier[] = [];
    vm: OM.MakePrenotazioneVm = new OM.MakePrenotazioneVm();
    bookingMode: string = "slot";

    created(){
        this.vm.ticketPrenotazioneIdentifier = this.voucher.identifier;
        this.vm.cliente = { identifier: this.customer.identifier, name: this.customer.personalData.completeName };

        if(this.booking){
            this.doctors = [this.booking.user];
            this.vm.user = this.doctors[0];
            this.vm.prenotazioneIdentifier = this.booking.identifier;
        } else {
            DoctorClient.getDoctorsWithProfession(this.voucher.profession.identifier)
            .then(x => {
                this.doctors = x;
            })
        }
    }

    // @Watch('vm.user')
    // onUserChange(){
    //     if(this.vm.user){
    //         BookingCalendarConfigClient.getAvailabilitiesOfUser(this.vm.user.identifier, )
    //     }
    // }

    makePrenotazione(){
        BookingClient.makePrenotazione(this.vm)
        .then(x => {
            this.$opModal.closeAll();
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Prenotazione salvata!", "Prenotazione salvata!"));
        })
    }

}
</script>
<style scoped>

</style>
