import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, vModelText as _vModelText } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "fw-normal d-flex pointer" }
const _hoisted_6 = ["value", "onInput"]
const _hoisted_7 = { class: "fw-normal d-flex pointer" }
const _hoisted_8 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "mb-3",
      "aria-label": _ctx.config.text.values[_ctx.$store.state.selectedLanguage]
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("b", null, _toDisplayString(_ctx.config.text.values[_ctx.$store.state.selectedLanguage]), 1)
      ]),
      (_ctx.config.isMultipleSelect)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("i", null, [
              _createTextVNode(" - "),
              _createVNode(_component_localized_text, {
                localizedKey: "Risposta multipla",
                text: "Risposta multipla"
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.config.isSingleSelect)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("i", null, [
              _createTextVNode(" - "),
              _createVNode(_component_localized_text, {
                localizedKey: "Risposta singola",
                text: "Risposta singola"
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.config.required)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("i", null, [
              _createTextVNode(" - "),
              _createVNode(_component_localized_text, {
                localizedKey: "Risposta obbligatoria",
                text: "Risposta obbligatoria"
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    (_ctx.config.isSingleSelect)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.config.options, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "form-group",
            key: i
          }, [
            _createElementVNode("label", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                class: "form-control",
                value: item,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answerValue.selectAnswer[0]) = $event)),
                onInput: ($event: any) => (_ctx.setIndex(i))
              }, null, 40, _hoisted_6), [
                [_vModelRadio, _ctx.answerValue.selectAnswer[0]]
              ]),
              _createTextVNode(" " + _toDisplayString(item.values[_ctx.$store.state.selectedLanguage]), 1)
            ])
          ]))
        }), 128))
      : (_ctx.config.isMultipleSelect)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.config.options, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "form-group",
              key: i
            }, [
              _createElementVNode("label", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  class: "form-control",
                  value: item,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.answerValue.selectAnswer) = $event))
                }, null, 8, _hoisted_8), [
                  [_vModelCheckbox, _ctx.answerValue.selectAnswer]
                ]),
                _createTextVNode(" " + _toDisplayString(item.values[_ctx.$store.state.selectedLanguage]), 1)
              ])
            ]))
          }), 128))
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.answerValue.textAnswer) = $event))
          }, null, 512)), [
            [_vModelText, _ctx.answerValue.textAnswer]
          ])
  ]))
}