import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Aggiungi professione",
          text: "Aggiungi professione"
        })
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tempProfessione) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableProfessioni, (item, i) => {
          return (_openBlock(), _createElementBlock("option", {
            key: i,
            value: item
          }, _toDisplayString(item.name), 9, _hoisted_2))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.tempProfessione]
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue, (item, i) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createTextVNode(_toDisplayString(item.professione && item.professione.name) + " - ", 1),
          _createElementVNode("span", {
            class: "text-danger pointer",
            onClick: ($event: any) => (_ctx.removeLine(i))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Elimina riga",
              text: "Elimina riga"
            })
          ], 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": ($event: any) => ((item.user) = $event)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.dottori as OM.NameIdentifier[]), (item, i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i,
                value: item
              }, _toDisplayString(item.name), 9, _hoisted_7))
            }), 128))
          ], 8, _hoisted_6), [
            [_vModelSelect, item.user]
          ]),
          (item.user)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-danger btn-sm",
                onClick: ($event: any) => (item.user = null)
              }, " x ", 8, _hoisted_8))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 256))
  ]))
}