<template>
    <div class="commentsContainer">
        <template v-if="modify">
            <new-comment-form v-model="vm" @add="$emit('add', $event)"></new-comment-form>
            <!-- <div class="addCommentContainer">
                <div class="commentAttachmentDropArea droparea" @drop="onDrop">
                    <localized-text localizedKey="Rilascia qui per allegare" text="Rilascia qui per allegare"></localized-text>
                </div>
                <auto-textarea class="form-control" placeholder="Scrivi qui" v-model="commentText" :maxHeight="150"></auto-textarea>
                <label class="btn btn-link">
                    <img src="@/img/common/icon_clip.svg" />
                    <file-input class="hidden" v-model="tempFile" @update:modelValue="addFile"></file-input>
                </label>
                <button type="button" class="btn btn-success" :disabled="!canSaveComment" @click="addComment">
                    <localized-text localizedKey="Salva" text="Salva"></localized-text>
                </button>
            </div>
            <div class="addCommentAttachment" v-if="filesToUpload.length > 0">
                <div v-for="item in filesToUpload" :key="item.name">
                    <localized-text localizedKey="Allegato:" text="Allegato:"></localized-text> {{item.name}}
                    <button type="button" class="btn btn-danger btn-sm" @click="removeFile(item)">
                        x
                    </button>
                </div>
            </div> -->
        </template>
        <div class="scrollListContainer">
            <div class="scrollListItem" v-if="comments.length == 0">
                <localized-text localizedKey="Nessun elemento inserito" text="Nessun elemento inserito"></localized-text>
            </div>
            <template v-else>
                <div class="scrollListItem comment" v-for="(item, i) in comments" :key="i">
                    <div class="top">
                        <div class="date">{{$filters.date(item.createdOn, 'DD-MM-YYYY HH:mm')}}</div>
                        <div class="author">{{item.author.name}}</div>
                        <div class="deleteComment" v-if="$store.state.isAdmin()" @click="deleteComment(item)">
                            <button type="button" class="btn btn-mini btn-danger">x</button>
                        </div>
                    </div>
                    <div class="bottom">
                        <comment-text :comment="item"></comment-text>
                        <!-- <div>{{item.text}}</div> -->
                        <template v-if="item.attachments.length > 0">
                            <div v-for="(x, i) in item.attachments" :key="i" class="fs-12">
                                <a class="text-orange" :href="x.publicUrl" target="_blank">
                                    {{x.fileName}}
                                </a>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import Component, { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class commentsContainer extends Vue {

    @Prop() comments: OM.Comment[];
    @Prop({
        type: Boolean,
        default: true
    }) modify: boolean;


    vm: VM.AddCommentVm = new VM.AddCommentVm();

    commentText: string = "";

    deleteComment(item: OM.Comment){
        this.$emit('deleteComment', item);
    }

}

</script>

<style scoped>

.hidden {
    left: 0;
}


</style>