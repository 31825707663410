import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/customers',
        name: 'customers',
        meta: { sideMenuLabel: "Clienti" },
        component: () => import("./views/customers.vue")
    },
    {
        path: '/customers/:identifier',
        name: 'customersDetail',
        meta: { classes: "" },
        component: () => import("./views/customersDetail.vue")
    },
    {
        path: '/foodPlans/:identifier',
        name: 'foodPlanDetail',
        component: () => import("./views/foodPlanDetail.vue")
    },
    {
        path: '/foodPlanVouchers/:clienteIdentifier',
        name: 'foodPlanVouchers',
        component: () => import("./views/foodPlanVouchers.vue")
    },
];

export default routes;