import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "float-end" }
const _hoisted_3 = { class: "midTitle" }
const _hoisted_4 = {
  filter: "createdOn",
  filterType: "date",
  sort: "createdOn"
}
const _hoisted_5 = {
  filter: "profession.name",
  sort: "profession.name"
}
const _hoisted_6 = {
  filter: "doctor.name",
  sort: "doctor.name"
}
const _hoisted_7 = {
  filter: "surveyType",
  sort: "surveyType"
}
const _hoisted_8 = {
  filter: "isCompleted",
  filterType: "checkbox",
  sort: "isCompleted"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { colspan: "99" }
const _hoisted_11 = {
  key: 0,
  class: "fs-22"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.newSurveyAnswers && _ctx.newSurveyAnswers(...args)))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Nuovo questionario",
              text: "Nuovo questionario"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_localized_text, {
            localizedKey: "Questionari",
            text: "Questionari"
          })
        ]),
        _createVNode(_component_op_table, {
          class: "table table-striped",
          items: _ctx.surveyAnswers
        }, {
          default: _withCtx((opData) => [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_4, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Data creazione",
                    text: "Data creazione"
                  })
                ]),
                _createElementVNode("td", _hoisted_5, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Professione",
                    text: "Professione"
                  })
                ]),
                _createElementVNode("td", _hoisted_6, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Dottore",
                    text: "Dottore"
                  })
                ]),
                _createElementVNode("td", _hoisted_7, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Tipo",
                    text: "Tipo"
                  })
                ]),
                _createElementVNode("td", _hoisted_8, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Completato",
                    text: "Completato"
                  })
                ])
              ])
            ]),
            _createElementVNode("tbody", null, [
              (opData.items.length == 0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                    _createElementVNode("td", _hoisted_10, [
                      _createVNode(_component_localized_text, {
                        localizedKey: "Nessun elemento trovato",
                        text: "Nessun elemento trovato"
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((opData.items as OM.SurveyAnswers[]), (item, i) => {
                return (_openBlock(), _createElementBlock("tr", { key: i }, [
                  _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.createdOn, "YYYY-MM-DD HH:mm")), 1),
                  _createElementVNode("td", null, _toDisplayString(item.profession && item.profession.name), 1),
                  _createElementVNode("td", null, _toDisplayString(item.doctor && item.doctor.name), 1),
                  _createElementVNode("td", null, _toDisplayString(item.surveyType), 1),
                  _createElementVNode("td", null, [
                    (item.isCompleted)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, "✓"))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, [
                    (item.isCompleted)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-primary btn-sm",
                          onClick: ($event: any) => (_ctx.showAnswers(item))
                        }, [
                          _createVNode(_component_localized_text, {
                            localizedKey: "Vedi risposte",
                            text: "Vedi risposte"
                          })
                        ], 8, _hoisted_12))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "btn btn-primary btn-sm",
                          onClick: ($event: any) => (_ctx.continueSurveyAnswers(item))
                        }, [
                          _createVNode(_component_localized_text, {
                            localizedKey: "Continua",
                            text: "Continua"
                          })
                        ], 8, _hoisted_13))
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["items"])
      ]))
    : _createCommentVNode("", true)
}