import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/dashboardDocuments',
        name: 'dashboardDocuments',
        meta: { sideMenuLabel: "Documenti dashboard" },
        component: () => import("./views/dashboardDocuments.vue")
    },
];
export default routes;