import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("td", null, [
      (_ctx.$store.state.user.identifier == _ctx.item.dottore.identifier)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            localizedKey: "Hai ricevuto una nuova prenotazione! Cliente: <b>{{nomeCliente}}</b>",
            text: "Hai ricevuto una nuova prenotazione! Cliente: <b>{{nomeCliente}}</b>",
            object: { nomeCliente: _ctx.item.cliente.name }
          }, null, 8, ["object"]))
        : (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            localizedKey: "Il cliente {{nomeCliente}} ha prenotato con {{nomeDottore}}",
            text: "Il cliente {{nomeCliente}} ha prenotato con {{nomeDottore}}",
            object: { nomeCliente: _ctx.item.cliente.name, nomeDottore: _ctx.item.dottore.name }
          }, null, 8, ["object"]))
    ]),
    _createElementVNode("td", _hoisted_1, [
      _createVNode(_component_router_link, {
        class: "btn btn-link",
        to: '/bookingDetail/' + _ctx.item.prenotazioneIdentifier
      }, {
        default: _withCtx(() => [
          _createVNode(_component_localized_text, {
            localizedKey: "Vedi prenotazione",
            text: "Vedi prenotazione"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ], 64))
}