import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_ctx.itemList)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "btn btn-primary me-1",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevItem && _ctx.prevItem(...args))),
          disabled: !_ctx.canPrev
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Precedente",
            text: "Precedente"
          })
        ], 8, _hoisted_2),
        _createTextVNode(" " + _toDisplayString(_ctx.currentIndex + 1) + " / " + _toDisplayString(_ctx.itemList.length) + " ", 1),
        _createElementVNode("button", {
          class: "btn btn-primary ms-1",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextItem && _ctx.nextItem(...args))),
          disabled: !_ctx.canNext
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Successivo",
            text: "Successivo"
          })
        ], 8, _hoisted_3)
      ]))
    : _createCommentVNode("", true)
}