import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/importOperations',
        name: 'importOperations',
        meta: { sideMenuLabel: "Importazione database" },
        component: () => import("./views/importOperations.vue")
    },
];

export default routes;