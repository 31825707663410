import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "form-control"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "form-group"
}
const _hoisted_5 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_tiny_editor = _resolveComponent("tiny-editor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Periodo giornata",
          text: "Periodo giornata"
        })
      ]),
      (_ctx.readonly)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.entry.dayPeriod), 1))
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entry.dayPeriod) = $event))
          }, null, 512)), [
            [_vModelText, _ctx.entry.dayPeriod]
          ])
    ]),
    (!_ctx.edit)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "form-group",
          innerHTML: _ctx.entry.htmlText
        }, null, 8, _hoisted_3))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_tiny_editor, {
            modelValue: _ctx.entry.htmlText,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entry.htmlText) = $event))
          }, null, 8, ["modelValue"])
        ])),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-primary btn-sm",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.edit = !_ctx.edit))
          }, [
            (!_ctx.edit)
              ? (_openBlock(), _createBlock(_component_localized_text, {
                  key: 0,
                  localizedKey: "Modifica",
                  text: "Modifica"
                }))
              : (_openBlock(), _createBlock(_component_localized_text, {
                  key: 1,
                  localizedKey: "Chiudi",
                  text: "Chiudi"
                }))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "btn btn-danger btn-sm ms-2",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args)))
          }, [
            (!_ctx.edit)
              ? (_openBlock(), _createBlock(_component_localized_text, {
                  key: 0,
                  localizedKey: "Rimuovi",
                  text: "Rimuovi"
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}