<template>
<div class="opModal">
    <div class="header">
        <h3>
            <localized-text localizedKey="Includi orari di prenotazione all'agenda" text="Includi orari di prenotazione all'agenda"></localized-text>
        </h3>
    </div>
    <div class="form-group">
        <label>
            <localized-text localizedKey="Seleziona professione" text="Seleziona professione"></localized-text>
        </label>
        <select class="form-control" v-model="selectedProfessione">
            <option v-for="(item, i) in professioni" :value="item">{{item.name}}</option>
        </select>
    </div>
    <hr/>
    <div class="body" v-if="selectedProfessione">
        <table class="table table-striped" style="min-width: 720px;">
            <thead>
                <tr>
                    <td>
                        <localized-text localizedKey="Giorno" text="Giorno"></localized-text>
                    </td>
                    <td>
                        <localized-text localizedKey="Fasce orarie" text="Fasce orarie"></localized-text>
                    </td>
                    <td class="text-end">
                        <button type="button" class="btn btn-success" @click="addInclusione">
                            <localized-text localizedKey="+ aggiungi inclusione" text="+ aggiungi inclusione"></localized-text>
                        </button>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in inclusioni" :key="item.identifier">
                    <td :class="{ 'bg-warning': !item.identifier }">
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Giorno" text="Giorno"></localized-text>
                                <template v-if="isDataDuplicata(item)">
                                    <img class="ms-2" src="@/img/exclamation.svg" /> data duplicata
                                </template>
                                <template v-if="!item.identifier">
                                    <localized-text class="ms-1" localizedKey="(Da salvare)" text="(Da salvare)"></localized-text>
                                </template>
                            </label>
                            <op-date class="form-control" v-model="item.date"></op-date>
                        </div>
                    </td>
                    <td style="vertical-align: middle;">
                        <div class="text-center">
                            <button type="button" class="btn btn-sm btn-success" @click="addFascia(item)">
                                <localized-text localizedKey="Aggiungi fascia oraria" text="Aggiungi fascia oraria"></localized-text>
                            </button>
                        </div>
                        <div class="text-center" v-for="(ora, i) in item.timeTable" :key="i">
                            <input type="time" v-model="ora.from" />
                            <input type="time" v-model="ora.to" />
                        </div>
                    </td>
                    <td class="text-end" style="vertical-align: middle;">
                        <button type="button" class="btn btn-danger" @click="removeInclusione(item)">
                            <localized-text localizedKey="- rimuovi giorno" text="- rimuovi giorno"></localized-text>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-5 text-center mw-700">
            <button type="button" class="btn btn-success" @click="ok">
                <localized-text localizedKey="OK" text="OK"></localized-text>
            </button>
            <button type="button" class="ms-4 btn btn-danger" @click="$emit('close')">
                <localized-text localizedKey="Annulla" text="Annulla"></localized-text>
            </button>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { TimeTableInclusionClient, BaseUserClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class inclusioneOrariModal extends Vue {

    @Prop() userIdentifier: string;

    inclusioni: OM.TimeTableInclusion[] = [];
    selectedProfessione: OM.NameIdentifier = null;
    professioni: OM.NameIdentifier[] = [];

    created(){
        BaseUserClient.getProfessioniOfUser(this.userIdentifier)
        .then(x => {
            this.professioni = x;
            if(this.professioni.length == 1){
                this.selectedProfessione = this.professioni[0];
            }
        })
    }
    
    @Watch('selectedProfessione')
    onSelectedProfessioneChange(){
        if(this.selectedProfessione)
            this.init();
    }

    init(){
        TimeTableInclusionClient.getByConsulente(this.userIdentifier, this.selectedProfessione.identifier)
        .then(x => {
            this.inclusioni = x;
        })
    }

    addInclusione(){
        let toPush = new OM.TimeTableInclusion();
        toPush.userIdentifier = this.userIdentifier;
        toPush.profession = this.selectedProfessione;
        this.inclusioni.unshift(toPush);
    }

    isDataDuplicata(item: OM.TimeTableInclusion){
        return this.inclusioni.some(x => x != item && x.date == item.date);
    }

    removeInclusione(item: OM.TimeTableInclusion){
        if(item.identifier){
            TimeTableInclusionClient.deleteById(item.identifier)
            .then(x => {
                let index = this.inclusioni.findIndex(x => x == item);
                this.inclusioni.splice(index, 1);
            });
        } else {
            let index = this.inclusioni.findIndex(x => x == item);
            this.inclusioni.splice(index, 1);
        }
    }

    ok(){
        TimeTableInclusionClient.save(this.inclusioni)
        .then(x => {
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Salvato", "Salvato"));
            this.$emit('close');
        })
    }

    addFascia(item: OM.TimeTableInclusion){
        item.timeTable.push({
            from: "",
            to: ""
        })
    }

}
</script>
<style scoped>

</style>