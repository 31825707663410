import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/deferredPayments',
        name: 'deferredPayments',
        meta: { sideMenuLabel: "Bonifici" },
        component: () => import("./views/deferredPayments.vue")
    },
    {
        path: '/deferredPaymentDetail/:identifier',
        name: 'deferredPaymentDetail',
        component: () => import("./views/deferredPaymentDetail.vue")
    },
];

export default routes;