<template>
    <div class="opModal">
        <div class="midTitle text-center mb-2">
            <localized-text localizedKey="Dati di contatto" text="Dati di contatto"></localized-text>
        </div>
        <!-- <op-address :readonly="true" v-model="centro.address"></op-address> -->
        <contact-data v-model="centro" :readonly="true"></contact-data>
        <div class="text-center">
            <button type="button" class="btn btn-primary" @click="$emit('close')">Chiudi</button>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model'
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class centroDetailModal extends Vue {

    @Prop() centro: OM.ContactData;

}
</script>
<style scoped>

.opModal {
    width: 580px;
}

</style>
