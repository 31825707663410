<template>
    <textarea ref="tinyEditor"></textarea>
</template>

<script lang="ts">
// Import TinyMCE
import 'tinymce/tinymce';

// A theme is also required
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/icons/default';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { Watch } from 'vue-property-decorator';


declare var tinymce;
@Options({})
export default class TinyEditor extends mixins(WithModel) {

    editor: any;
    isTyping: boolean = false;
    checkerTimeout: any;

    @Watch('modelValue')
    onValueChange(next, prev){
        if(!this.isTyping && this.editor){
            this.editor.setContent(next);
        }
    }

    created(){
        if(this.modelValue == null || this.modelValue == undefined){
            this.localValue = "";
        }
    }

    mounted(){
        tinymce.init({
            target: this.$refs.tinyEditor,
            height: 500,
            forced_root_block : '',
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
            setup: (editor) => {
                editor.on('keyUp', (e) => {
                    this.submitNewContent();
                });
                editor.on('change', (e) => {
                    if(this.editor.getContent() !== this.localValue){
                        this.submitNewContent();
                    }
                    this.$emit('editorChange', e);
                });
            }
        })
        .then(editors => {
            this.editor = editors[0];
            this.editor.setContent(this.localValue);
        });
    }
    submitNewContent(){
        this.isTyping = true;
        clearTimeout(this.checkerTimeout);
        this.checkerTimeout = setTimeout(()=>{
            this.isTyping = false;
        }, 100);

        this.emitModel(this.editor.getContent() || "")
    }

}

</script>
<style scoped>

</style>
