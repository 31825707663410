let _apiurl = process.env.VUE_APP_APIURL;
let _wsurl = process.env.VUE_APP_WSURL;
let _appurl = process.env.VUE_APP_APPURL;
let _backofficeurl = process.env.VUE_APP_BACKOFFICEURL;
let _mode = process.env.VUE_APP_MODE;


export let apiurl = _apiurl;
export let wsurl = _wsurl;
export let appurl = _appurl;
export let backofficeurl = _backofficeurl;
export let mode = _mode;