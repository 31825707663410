<template>
    <div class="form-group" v-if="!hideProject">
        <label>
            <localized-text localizedKey="Progetto" text="Progetto"></localized-text>
        </label>
        <select class="form-control" v-model="localProject" @change="$emit('update:project', localProject)">
            <option value="" v-if="showAllOption">
                {{$localizationService.GetLocalizedValue("Tutti", "Tutti")}}
            </option>
            <option v-for="(item, i) in projects" :value="item">{{item}}</option>
        </select>
    </div>
    <div class="form-group" v-if="!hideCountry">
        <label>
            <localized-text localizedKey="Nazione" text="Nazione"></localized-text>
        </label>
        <select class="form-control" v-model="localCountry" @change="$emit('update:country', localCountry)">
            <option value="" v-if="showAllOption">
                {{$localizationService.GetLocalizedValue("Tutte", "Tutte")}}
            </option>
            <option v-for="(item, i) in countries" :value="item">{{item}}</option>
        </select>
    </div>
</template>

<script lang="ts">
import * as CONST from '@/const';
import { ProjectConfigClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class projectCountrySelector extends Vue {
 
    @Prop() project: string;
    @Prop() hideProject: string;
    @Prop() country: string;
    @Prop() hideCountry: string;
    @Prop() showAllOption: boolean;

    projects: string[] = CONST.ProjectNames.GetAll();
    countries: string[] = [];
    localProject: string = "";
    localCountry: string = "";

    created(){
        this.localProject = this.project;
        this.localCountry = this.country;
        this.loadCountries();
    }

    @Watch('project')
    projectChange(){
        this.localProject = this.project;
    }

    @Watch('country')
    countryChange(){
        this.localCountry = this.country;
    }

    @Watch('localProject')
    loadCountries(){
        if(this.localProject){
            ProjectConfigClient.getEnabledCountries(this.localProject)
            .then(x => this.countries = x.map(x => x.alpha2Code))
        } else {
            this.countries = [];
        }
    }

}

</script>
