import { CommonServices } from "@/services/CommonServices";
import { apiurl} from '@/config';
import * as OM from '@/model';
import * as VM from '@/viewmodel';

class _BaseUserServices {

    addCommento(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(apiurl + "api/BaseUser/AddCommento", vm);
    }

    addNota(vm: VM.AddCommentVm){
        return CommonServices.uploadFileToUrl<OM.Comment>(apiurl + 'api/BaseUser/AddNota', vm);
    }

    saveToDo(vm: VM.SaveToDoVm){
        return CommonServices.uploadFileToUrl<OM.ToDo>(apiurl + 'api/BaseUser/SaveToDo', vm);
    }

}

export let BaseUserServices = new _BaseUserServices();
