<template>
    <div ref="appContainer" @dragenter="setHightlights" @dragover="setHightlights" 
        @dragend="unsetHighlights" @drop="unsetHighlights">
    <transition name="fade">
        <div class="spinnerContainer" v-show="showSpinner">
            <div class="spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </transition>
    <template v-if="showApp">
        <div class="appMode" v-if="appMode != 'production'">Modalità: {{appMode}}</div>
        <router-view/>
    </template>
    <op-modal-container></op-modal-container>
</div>
</template>

<script lang="ts">
import store from '@/store';
import { ModalServices } from '@/services/ModalServices'
import { StorageServices } from './services/StorageServices';
import { LocalizedClient, LoginClient } from './services/Services';
import { Options, Vue } from 'vue-class-component';
import { LoginServices } from './services/LoginServices';
import { mode } from './config';

@Options({})
export default class App extends Vue {
    
    get showSpinner(){
        return store.state.showSpinner > 0;
    }
    showApp: boolean = false;
    appMode: string = "";
    
    created(){
        this.appMode = mode;

        ModalServices.setOpModal(this.$opModal);
        
        let proms: any[] = [
            LocalizedClient.getAll(),
            LocalizedClient.getEnabledLanguages(),
        ];
        Promise.all(proms)
        .then(xs => {
            store.state.consts.localizedValues = xs[0];
            store.state.consts.enabledLanguages = xs[1];
            if(!store.state.selectedLanguage){
                store.state.selectedLanguage = xs[1][0];
            }
            
            if(store.state.loginData){
                LoginClient.refreshLogin()
                .then(x => {
                    LoginServices.loginCallback(x)
                    .then(() => this.showApp = true);
                })
                .catch(() => this.showApp = true)
            } else {
                this.showApp = true;
            }
            
        })
    }
    
    setHightlights(event){
        event.preventDefault();
        (this.$refs.appContainer as HTMLElement).classList.add('highlightDrags');
    }
    unsetHighlights(event){
        event.preventDefault();
        (this.$refs.appContainer as HTMLElement).classList.remove('highlightDrags');
    }
}

</script>
