<template>
    <comments-container :comments="user.note" @add="addNota" :modify="!readonly" @deleteComment="onDeleteComment"></comments-container>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewmodel';
import { BaseUserClient } from '@/services/Services';
import EventBus from '@/services/EventBus';
import { BaseUserServices } from '@/services/BaseUserServices';

@Options({})
export default class note extends Vue {

    @Prop() user: OM.Lead;
    @Prop() readonly: boolean;

    addNota(vm: VM.AddCommentVm){
        vm.entityIdentifier = this.user.identifier;
        BaseUserServices.addNota(vm)
        .then(x => {
            this.user.note.unshift(x);
            EventBus.$emit('newcomment');
        })
    }

    onDeleteComment(item: OM.Comment){
        if(!confirm("Confermi la rimozione?")){
            return;
        }
        
        BaseUserClient.removeNota(item.identifier, this.user.identifier)
        .then(x => {
            let index = this.user.note.findIndex(x => x.identifier == item.identifier);
            this.user.note.splice(index, 1);
        })
    }

}
</script>
<style scoped>
</style>
