import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/videoDellaSettimana',
        name: 'videoDellaSettimana',
        meta: { sideMenuLabel: "Video della settimana" },
        component: () => import("./views/videoDellaSettimana.vue")
    },
    {
        path: '/videoDellaSettimana/:identifier',
        name: 'videoDellaSettimanaDetail',
        component: () => import("./views/videoDellaSettimanaDetail.vue")
    },
];

export default routes;