<template>
    <div v-if="user">
        <div v-if="Object.keys(user.projectStatusesPair).length == 0">
            <localized-text localizedKey="Ancora nessun progetto abilitato" text="Ancora nessun progetto abilitato"></localized-text>
        </div>
        <div class="tabsContainer mt-3">
            <div class="tabs">
                <div class="tabItem" v-for="(project, i) in projects" :key="i" @click="selectedTab = project" 
                    :class="{ selected: selectedTab == project, 'bg-success text-white': !(!user.projectStatusesPair[project] || user.projectCountryPairs[project] == undefined) }">
                    <div>
                        <b>{{project}}</b>
                    </div>
                </div>
            </div>
            <div class="tabContent" v-if="selectedTab && !user.projectStatusesPair[selectedTab]">
                <localized-text class="fs-16" localizedKey="Progetto non attivo su questo cliente" text="Progetto non attivo su questo cliente"></localized-text>
                <button class="btn btn-sm btn-success ms-2" @click.stop="addProjectToPairs(selectedTab)" v-if="!user.projectStatusesPair[selectedTab] || user.projectCountryPairs[selectedTab] == undefined">
                    <localized-text localizedKey="Attiva" text="Attiva"></localized-text>
                </button>
            </div>
            <div class="tabContent" v-if="selectedTab && user.projectStatusesPair[selectedTab]">
                <div class="row" v-if="user.projectStatusesPair[selectedTab] && user.projectCountryPairs[selectedTab] != undefined">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Stato" text="Stato"></localized-text>
                            </label>
                            <select class="form-control" v-model="user.projectStatusesPair[selectedTab].status">
                                <option v-for="item in statuses[selectedTab]" :value="item">{{item}}</option>
                            </select>
                        </div>
                        <div class="scrollListContainer" ref="scrollListContainer">
                            <div class="scrollListItem" v-for="(item, i) in user.projectStatusesPair[selectedTab].statusHistory" :key="i">
                                <div>
                                    <b>{{item.value.from}} → {{item.value.to}}</b>
                                </div>
                                <div>
                                    <!-- <span class="text-primary">{{item.value.author && item.value.author.name}}</span> -->
                                </div>
                                <div>
                                    {{$filters.date(item.date, "DD/MM/yyyy HH:mm")}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Substato" text="Substato"></localized-text>
                            </label>
                            <select class="form-control" v-model="user.projectStatusesPair[selectedTab].subStatus">
                                <option v-for="item in subStatuses[selectedTab]" :value="item">{{item}}</option>
                            </select>
                        </div>
                        <div class="scrollListContainer" ref="scrollListContainer">
                            <div class="scrollListItem" v-for="(item, i) in user.projectStatusesPair[selectedTab].subStatusHistory" :key="i">
                                <div>
                                    <b>{{item.value.from}} → {{item.value.to}}</b>
                                </div>
                                <div>
                                    <!-- <span class="text-primary">{{item.value.author && item.value.author.name}}</span> -->
                                </div>
                                <div>
                                    {{$filters.date(item.date, "DD/MM/yyyy HH:mm")}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Product level" text="Product level"></localized-text>
                            </label>
                            <input type="number" class="form-control" v-model="user.projectStatusesPair[selectedTab].productLevel" />
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Nazione" text="Nazione"></localized-text>
                            </label>
                            <select class="form-control" v-model="user.projectCountryPairs[selectedTab]">
                                <option v-for="item in countries[selectedTab]" :value="item">{{item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Tipo dieta" text="Tipo dieta"></localized-text>
                            </label>
                            <select class="form-control" v-model="user.projectStatusesPair[selectedTab].dietType">
                                <option v-for="item in dietTypes[selectedTab]" :value="item">{{item}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mt-4" v-if="user.projectStatusesPair[selectedTab] && user.projectCountryPairs[selectedTab] != undefined">
                    <div class="col-sm-6">
                        <div class="d-flex">
                            <div class="boxTitle">
                                <localized-text localizedKey="Scadenze" text="Scadenze"></localized-text>
                            </div>
                            <div class="ms-2">
                                <button class="btn btn-primary btn-sm" @click="addToDo">
                                    <localized-text localizedKey="Nuova" text="Nuova"></localized-text>
                                </button>
                            </div>
                        </div>
                        <div class="scrollListContainer" ref="scrollListContainer">
                            <div class="scrollListItem" v-if="user.projectStatusesPair[selectedTab].scadenze.length == 0">
                                <localized-text localizedKey="Nessuna scadenza inserita" text="Nessuna scadenza inserita"></localized-text>
                            </div>
                            <div class="scrollListItem" v-for="(item, i) in user.projectStatusesPair[selectedTab].scadenze" :key="i">
                                <div class="d-flex justify-content-between">
                                    <b>{{item.author.name}}</b>
                                    <div>
                                        <span class="text-primary">{{item.toDoType}}</span>
                                        -
                                        <b>{{$filters.date(item.startDate, "DD/MM/yyyy HH:mm")}}</b>
                                    </div>
                                </div>
                                <div>
                                    {{item.text}}
                                </div>
                                <div>
                                    <template v-if="item.attachments.length > 0">
                                        <div v-for="(x, i) in item.attachments" :key="i" class="fs-12">
                                            <a class="text-primary" :href="x.publicUrl" target="_blank">
                                                {{x.fileName}}
                                            </a>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <button class="btn float-end btn-sm btn-danger ms-2" @click.stop="removeProjectFromPairs(selectedTab)" v-if="!(!user.projectStatusesPair[selectedTab] || user.projectCountryPairs[selectedTab] == undefined)">
                            <localized-text localizedKey="Disattiva progetto" text="Disattiva progetto"></localized-text>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { mixins, Options, Vue } from 'vue-class-component';
import { ProjectConfigClient } from '@/services/Services';
import store from '@/store';
import { LocalizationServices } from '@/services/LocalizationServices';
import { ModalServices } from '@/services/ModalServices';
import { Prop } from 'vue-property-decorator';
import newToDoModal from './newToDoModal.vue';

@Options({})
export default class projectStatusesPairs extends Vue {

    @Prop() user: OM.BaseUser;
    
    projects: string[] = CONST.ProjectNames.GetAll();
    statuses: { [key: string] :string[] } = {};
    subStatuses: { [key: string] :string[] } = {};
    countries: { [key: string] :string[] } = {};
    lists: string[] = [];
    selectedTab: string = "";
    dietTypes: string[] = [];

    created(){
        this.sortProjects();
        this.projects.forEach(proj => {
            ProjectConfigClient.getProjectConfig(proj)
            .then(x => {
                this.statuses[proj] = x.leadStatuses;
                this.subStatuses[proj] = x.leadSubStatuses;
                this.countries[proj] = x.countryConfigList.map(x => x.alpha2Code);
                this.dietTypes[proj] = x.dietTypes;
            })
        })
        this.selectedTab = store.state.selectedProject;
    }

    addProjectToPairs(project: string){
        if(!this.user.projectCountryPairs[project]){
            this.user.projectCountryPairs[project] = "";
        }
        if(!this.user.projectStatusesPair[project]){
            this.user.projectStatusesPair[project] = new OM.Statuses();
        }
        
        this.sortProjects();
    }

    sortProjects(){
        this.projects = this.projects.sort((a, b) => {
            // Controlla prima se a o b esistono in projectStatusesPair
            const aHasStatus = !!this.user.projectStatusesPair[a];
            const bHasStatus = !!this.user.projectStatusesPair[b];

            if (aHasStatus && !bHasStatus) {
                return -1;
            } else if (!aHasStatus && bHasStatus) {
                return 1;
            } else {
                // Se entrambi hanno (o non hanno) lo status, ordina alfabeticamente
                return a.localeCompare(b);
            }
        });
    }

    removeProjectFromPairs(project){
        ModalServices.confirmModal(
            LocalizationServices.GetLocalizedValue("Attenzione", "Attenzione"),
            LocalizationServices.GetLocalizedValue("Una volta salvato, l'operazione non è reversibile. Confermi?", "Una volta salvato, l'operazione non è reversibile. Confermi?"),
            LocalizationServices.GetLocalizedValue("SI", "SI"),
            LocalizationServices.GetLocalizedValue("NO", "NO"),
            () => {
                delete this.user.projectCountryPairs[project];
                delete this.user.projectStatusesPair[project];
                this.sortProjects();
            }
        )
    }

    addToDo(){
        this.$opModal.show(newToDoModal, {
            user: this.user,
            project: this.selectedTab,
            onDone: (x: OM.ToDo) => {
                this.user.projectStatusesPair[this.selectedTab].scadenze.unshift(x);
            }
        })
    }

}

</script>
