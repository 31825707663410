import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/vouchers',
        name: 'vouchers',
        meta: { sideMenuLabel: "Voucher" },
        component: () => import("./views/vouchers.vue")
    },
];

export default routes;