<template>
    <div class="position-relative">
        <canvas ref="chart" v-bind="$attrs"></canvas>
        <img class="fakeDoughnut" src="@/img/fakeDoughnut.png" v-if="showFakeDoughnut" />
        <div class="centerText" v-if="centerLabel">
            <div class="centerLabel">{{centerLabel}}</div>
            <div class="centerNumber">{{$filters.currency(centerNumber)}}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import Chart from 'chart.js'
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class DoughnutChart extends Vue {
    
    @Prop() value: number[];
    @Prop() label: string[];
    @Prop({
        default: '#7FD6DC'
    }) color: string[];
    @Prop() maxy: number;
    @Prop() centerLabel: string;
    @Prop() centerNumber: string;

    showFakeDoughnut: boolean = false;

    chart: any;
    mounted(){
        this.chartIt();
    }

    @Watch('value')
    onDataChange(next, prev){
        this.chart.destroy();
        this.chartIt();
    }

    chartIt(){
        this.showFakeDoughnut = this.value.every(x => x == 0);

        let ctx: any = (this.$refs.chart as any).getContext('2d');
        this.chart = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: this.label,
                datasets: [{
                    data: this.value,
                    backgroundColor: this.color,
                }]
            },
            options: {
                layout: {
                    padding: 0
                },
                aspectRatio: 1.45,
                cutoutPercentage: 70, 
                legend: {
                    display: false
                },
                showAllTooltips: true,
                animation: false,
                tooltips: {
                    backgroundColor: '#fff',
                    bodyFontColor: '#000',
                    displayColors: false,
                }
            }
        });
    }

}

</script>

<style scoped>

.centerText {
    position: absolute;
    margin: auto;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.centerLabel {
    font-weight: 500;
    font-size: 12px;
    line-height: 6px;
    text-align: center;
}
.centerNumber {
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -2px;
}
img.fakeDoughnut {
    position: absolute;
    top: 0;
    filter: brightness(0.9);
    left: 0;
    right: 0;
    margin: auto;
    max-height: 100%;
    max-width: 400px;
}


</style>

