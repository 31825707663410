import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/newsFeed',
        name: 'newsFeed',
        meta: { sideMenuLabel: "News" },
        component: () => import("./views/newsFeed.vue")
    },
    {
        path: '/newsFeed/:identifier',
        name: 'newsFeedDetail',
        component: () => import("./views/newsFeedDetail.vue")
    },
];

export default routes;