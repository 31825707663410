import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 1,
  class: "form-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Username",
          text: "Username"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue.username) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localValue.username]
      ])
    ]),
    _createElementVNode("label", {
      class: "text-warning pointer",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showPasswordChange && _ctx.showPasswordChange(...args)))
    }, [
      (!_ctx.showPassword)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            localizedKey: "Clicca per modificare la password",
            text: "Clicca per modificare la password"
          }))
        : (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            localizedKey: "Annulla",
            text: "Annulla"
          }))
    ]),
    (_ctx.showPassword)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-sm btn-success ms-2",
          disabled: !_ctx.localValue.password,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.savePasswordChange && _ctx.savePasswordChange(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Salva",
            text: "Salva"
          })
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Password",
          text: "Password"
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        class: "form-control",
        disabled: !_ctx.showPassword,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue.password) = $event))
      }, null, 8, _hoisted_4), [
        [_vModelText, _ctx.localValue.password]
      ])
    ]),
    (_ctx.$store.state.isAdmin())
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Login abilitata",
              text: "Login abilitata"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localValue.loginEnabled) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelCheckbox, _ctx.localValue.loginEnabled]
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}