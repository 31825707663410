import * as OM from '@/model';
import store from './store';
import CodiceFiscale from 'codice-fiscale-js';

export function JsonClone<T>(item: T){
    return <T>JSON.parse(JSON.stringify(item));
}

export function getUrlParameter(sParam: string): any
{
    var url = window.location.search.substring(1), urlvariables = url.split('&'), parameterName, i;
    for (i = 0; i < urlvariables.length; i++)
    {
        parameterName = urlvariables[i].split('=');
        if (parameterName[0].toLowerCase() === sParam.toLowerCase()) {
            return parameterName[1] === undefined ? true : decodeURIComponent(parameterName[1]);
        }
    }
}


export function getQueryParams(url) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    })
    return params;
}

export function guid() { 
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) { 
        const r = Math.random() * 16 | 0,  
            v = c == 'x' ? r : (r & 0x3 | 0x8); 
        return v.toString(16); 
    }); 
}


export function groupBy<T>(collection: T[], property) {
    var i = 0, val, index,
        values = [], result: { [key: string]: T[] } = {};
    for (; i < collection.length; i++) {
        val = getValueByProp(collection[i], property);
        index = values.indexOf(val);
        if (index > -1)
            result[val].push(collection[i]);
        else {
            values.push(val);
            result[val] = [];
            result[val].push(collection[i]);
        }
    }
    return result;
}
function getValueByProp(input: any, propString: string){
    let props = propString.split('.');
    let ris = input;
    props.forEach(x => {
        if(ris != null)
            ris = ris[x];
    })
    return ris;
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized){}
        localized = new OM.LocalizedValue();

    if(!localized.values)
        localized.values = {};
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    var keys = Object.keys(localized.values);
    enabledLanguages.forEach( lang => {
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
}


export function setSelectionByIndex(editableElement, start, end) {
    if (editableElement.nodeName === "TEXTAREA" || editableElement.nodeName === "INPUT") {
        editableElement.setSelectionRange(start, end);
        editableElement.focus();
    } else {
        let currentPos = 0;
        let startNode = null, endNode = null;
        let startOffset = 0, endOffset = 0;

        function traverseNodes(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                let textLength = node.textContent.length;

                if (startNode === null && currentPos + textLength >= start) {
                    startNode = node;
                    startOffset = start - currentPos;
                }

                if (endNode === null && currentPos + textLength >= end) {
                    endNode = node;
                    endOffset = end - currentPos;
                }

                currentPos += textLength;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                node.childNodes.forEach(traverseNodes);
            }
        }

        traverseNodes(editableElement);

        if (startNode && endNode) {
            let range = document.createRange();
            range.setStart(startNode, startOffset);
            range.setEnd(endNode, endOffset);

            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
}

export function getSelectionIndexes(editableElement) {
    if (editableElement.nodeName === "TEXTAREA" || editableElement.nodeName === "INPUT") {
        return {
            start: editableElement.selectionStart,
            end: editableElement.selectionEnd
        };
    }

    let selection = window.getSelection();
    if (selection.rangeCount === 0) return { start: -1, end: -1 };

    let range = selection.getRangeAt(0);
    let startNode = range.startContainer;
    let endNode = range.endContainer;
    let startOffset = range.startOffset;
    let endOffset = range.endOffset;
    let startIndex = 0, endIndex = 0;

    function calculateIndex(node, offset) {
        let currentPos = 0;
        let found = false;

        function traverseNodes(currentNode) {
            if (currentNode === node) {
                found = true;
                return currentPos + offset;
            }

            if (currentNode.nodeType === Node.TEXT_NODE) {
                currentPos += currentNode.textContent.length;
            } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                currentNode.childNodes.forEach(traverseNodes);
            }
        }

        traverseNodes(editableElement);
        return found ? currentPos : -1;
    }

    startIndex = calculateIndex(startNode, startOffset);
    endIndex = calculateIndex(endNode, endOffset);

    return { start: startIndex, end: endIndex };
}


export function validateEmail(input){
    return String(input)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function checkIfRichiestaPagamentoPossible(user: OM.PaymentUser){
    return user.fiscalData.fiscalCode && 
        user.fiscalData.dataNascita &&
        user.fiscalData.luogoNascita &&
        user.fiscalData.fiscalAddress.citta &&
        user.fiscalData.fiscalAddress.provincia &&
        user.fiscalData.fiscalAddress.cap &&
        user.fiscalData.fiscalAddress.via &&
        user.fiscalData.fiscalAddress.civico &&
        user.fiscalData.fiscalAddress.nazione &&
        user.paymentData.bankAccountData.iban &&
        user.paymentData.bankAccountData.intestatario
}


type Gender = "F" | "M";
export function CalcolaCodFisc(name: string, surname: string, gender: Gender, day: number, month: number, year: number, birthplace, birthplaceProvincia){
    let cf = new CodiceFiscale({
        name: name,
        surname: surname,
        gender: gender,
        day: day,
        month: month,
        year: year,
        birthplace: birthplace,
        birthplaceProvincia: birthplaceProvincia
    });
    return cf;
}

export function CodFiscInverso(input: string){
    return CodiceFiscale.computeInverse(input);
}