<template>
    <!-- <div class="row">
        <div class="col-sm-3" v-if="!readonly">
            <div class="form-group">
                <label>Lista</label>
                <select class="form-control" v-model="user.list">
                    <option :value="null">Seleziona lista</option>
                    <option v-for="(item, i) in lists" :key="i" :value="item">{{item}}</option>
                </select>
            </div>
        </div>
        <div class="col-sm-3" v-if="!readonly">
            <div class="form-group">
                <label>Lavorato</label>
                <input type="checkbox" class="form-control" v-model="user.lavorato" />
            </div>
        </div>
        <div class="col-sm-3" v-if="!readonly">
            <div class="form-group">
                <label>Provvigioni cassaforte</label>
                <input type="checkbox" class="form-control" v-model="user.provvigioniCassaforte" />
            </div>
        </div>

        <div class="col-sm-6 mb-3" v-if="!readonly">
            <div class="box mb-3">
                <accordion>
                    <template #title>
                        <div class="boxTitle">Dati di accesso</div>
                    </template>
                    <auth-data :value="user.authData"></auth-data>
                    <div class="form-group">
                        <label>Product level</label>
                        <input type="number" class="form-control" v-model="user.productLevel" />
                    </div>
                </accordion>
            </div>
            <div class="box mb-3">
                <accordion>
                    <template #title>
                        <div class="boxTitle">Social networks</div>
                    </template>
                    <social-networks-data :value="user.socialNetworksData" :readonly="readonly"></social-networks-data>
                </accordion>
            </div>
            <div class="box mb-3">
                <accordion>
                    <template #title>
                        <div class="boxTitle">Commenti ({{user.comments.length}})</div>
                    </template>
                    <commenti :user="user" :readonly="$store.state.isDottore()"></commenti>
                </accordion>
            </div>
            <div class="box mb-3">
                <accordion>
                    <template #title>
                        <div class="boxTitle">Cronologia scadenze ({{user.scadenze.length}})</div>
                    </template>
                    <cronologia-scadenze :user="user"></cronologia-scadenze>
                </accordion>
            </div>
        </div>
        <div class="col-sm-6 mb-3">
            <div class="box mb-3">
                <accordion>
                    <template #title>
                        <div class="boxTitle">Note ({{user.note.length}})</div>
                    </template>
                    <note :user="user" :readonly="$store.state.isManager()"></note>
                </accordion>
            </div>
            <div class="box mb-3">
                <accordion>
                    <template #title>
                        <div class="boxTitle">Stati</div>
                    </template>
                    <div class="form-group">
                        <label>Stato</label>
                        <span v-if="readonly" class="form-control">{{user.status}}</span>
                        <select v-else class="form-control" v-model="user.status">
                            <option v-for="(item, i) in statuses" :key="i">{{item}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Sub stato</label>
                        <span v-if="readonly" class="form-control">{{user.subStatus}}</span>
                        <select v-else class="form-control" v-model="user.subStatus">
                            <option v-for="(item, i) in subStatuses" :key="i">{{item}}</option>
                        </select>
                    </div>
                </accordion>
            </div>
            <div class="box mb-3">
                <accordion>
                    <template #title>
                        <div class="boxTitle">Cronologia stati ({{user.cronologiaStati.length}})</div>
                    </template>
                    <cronologia-stati :user="user"></cronologia-stati>
                </accordion>
            </div>
            <div class="box mb-3" v-if="!readonly && $store.state.isAdmin()">
                <accordion>
                    <template #title>
                        <div class="boxTitle">
                            <localized-text localizedKey="Professionisti assegnati" text="Professionisti assegnati"></localized-text>
                        </div>
                    </template>
                    <div class="form-group">
                        <label>
                            <localized-text localizedKey="Professionisti assegnati" text="Professionisti assegnati"></localized-text>
                        </label>
                        <professionisti-assegnati v-model="user.professionistiAssegnati"></professionisti-assegnati>
                    </div>
                </accordion>
            </div>
            <div class="box mb-3" v-if="readonly">
                <accordion>
                    <template #title>
                        <div class="boxTitle">Commenti ({{user.comments.length}})</div>
                    </template>
                    <commenti :user="user" :readonly="$store.state.isDottore()"></commenti>
                </accordion>
            </div>
        </div>
    </div> -->
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewmodel';
// import * as Enum from '@/enum';
// import { CollaboratoreClient, LeadClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class datiLead extends Vue {
 
    // @Prop() user: OM.Lead;
    // @Prop() readonly: boolean;

    // lists: string[] = [];
    // statuses: string [] = Enum.LeadStatus.GetAll();
    // subStatuses: string [] = [];
    // dottori: OM.NameIdentifier[] = [];

    // created(){
    //     LeadClient.getLists()
    //     .then(x => {
    //         this.lists = x;
    //     })
    //     CollaboratoreClient.getListDottori()
    //     .then(x => {
    //         this.dottori = x;
    //     })
        
    //     this.subStatuses = Enum.LeadSubStatus.GetAll();
    //     if(!store.state.isAdmin()){
    //         this.subStatuses = this.subStatuses.filter(x => x != Enum.LeadSubStatus.ConclusionePositiva);
    //     }
    // }

}

</script>
