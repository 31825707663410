// src/moduleLoader.ts

function loadComponents(app) {
    
    const ComponentContext = require.context('./components', true, /\.vue$/i);
    ComponentContext.keys().forEach(fileName => {
        var component = ComponentContext(fileName);
        let componentName = fileName.split('/').pop().split('.')[0];
        app.component(componentName, (<any>component).default || component);
    })

}

export default loadComponents;
