<template>
    <div>
        <div class="form-group">
            <label>Numero menu</label>
            <input type="text" class="form-control" v-model="localValue.dayNumber" />
        </div>
        <div class="form-group">
            <label>Note (opzionale)</label>
            <auto-textarea class="form-control" v-model="localValue.note" />
        </div>
        <div class="d-flex">
            <menu-entry v-for="(item, i) in localValue.entries" :key="i" :modelValue="item"></menu-entry>
        </div>
    </div>
</template>

<script lang="ts">
import WithModel from '@/mixins/withModel';
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/model';

@Options({})
export default class menuStep extends mixins(WithModel) {

    declare localValue: OM.MenuStep;

}

</script>
<style scoped>
</style>
