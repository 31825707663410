<template>
    <div class="mt-2">
        <button type="button" class="btn btn-link" @click="openAction('Fissa chiamata 1', 'call', '', true, '1° chiamata')">
            <div><i class="fa fa-phone fa-2x"></i></div>
            <div>
                <localized-text localizedKey="1° chiamata" text="1° chiamata"></localized-text>
            </div>
        </button>
        <button type="button" class="btn btn-link" @click="openAction('Fissa chiamata 2', 'call', '', true, '2° chiamata')">
            <div><i class="fa fa-phone fa-2x"></i></div>
            <div>
                <localized-text localizedKey="2° chiamata" text="2° chiamata"></localized-text>
            </div>
        </button>
        <button type="button" class="btn btn-link" @click="openAction('Fissa chiamata 3', 'call', '', true, '3° chiamata')">
            <div><i class="fa fa-phone fa-2x"></i></div>
            <div>
                <localized-text localizedKey="3° chiamata" text="3° chiamata"></localized-text>
            </div>
        </button>
        <button type="button" class="btn btn-link" @click="openAction('Fissa chiamata X', 'call', '', true, 'Altre chiamate')">
            <div><i class="fa fa-phone fa-2x"></i></div>
            <div>
                <localized-text localizedKey="Altre chiamate" text="Altre chiamate"></localized-text>
            </div>
        </button>
    </div>
    <div class="mt-2">
        <button type="button" class="btn btn-link" @click="openAction('Fissa WhatsApp 1', 'whatsapp', '', true, '1° WhatsApp')">
            <div><i class="fa fa-whatsapp fa-2x"></i></div>
            <div>
                <localized-text localizedKey="1° WhatsApp" text="1° WhatsApp"></localized-text>
            </div>
        </button>
        <button type="button" class="btn btn-link" @click="openAction('Fissa WhatsApp 2', 'whatsapp', '', true, '2° WhatsApp')">
            <div><i class="fa fa-whatsapp fa-2x"></i></div>
            <div>
                <localized-text localizedKey="2° WhatsApp" text="2° WhatsApp"></localized-text>
            </div>
        </button>
        <button type="button" class="btn btn-link" @click="openAction('Fissa WhatsApp 3', 'whatsapp', '', true, '3° WhatsApp')">
            <div><i class="fa fa-whatsapp fa-2x"></i></div>
            <div>
                <localized-text localizedKey="3° WhatsApp" text="3° WhatsApp"></localized-text>
            </div>
        </button>
        <button type="button" class="btn btn-link" @click="openAction('Fissa WhatsApp X', 'whatsapp', '', true, 'Altre WhatsApp')">
            <div><i class="fa fa-whatsapp fa-2x"></i></div>
            <div>
                <localized-text localizedKey="Altre WhatsApp" text="Altre WhatsApp"></localized-text>
            </div>
        </button>
        <div class="mt-2">
            <div class="ml-3 d-flex align-items-center">
                <input type="checkbox" class="checkBig" v-model="isChiamato" @change="setChiamato" :disabled="isChiamato && !$store.state.isAdmin()" />
                <span class="ml-1">Chiamato</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient, ProjectConfigClient } from '@/services/Services';
import store from '@/store';
import ActionModal from './actionModal.vue';

@Options({})
export default class leadActions extends Vue {
 
    @Prop() user: OM.Lead;

    lists: string[] = [];

    openAction(title: string, commentType: string, status: string, showDate: boolean, nota: string){
        this.$opModal.show(ActionModal, {
            title: title,
            commentType: commentType,
            showDate: showDate,
            status: status,
            leadIdentifier: this.user.identifier,
            nota: nota
        }, () => {
            this.$emit('reload');
        })
    }

    get isChiamato(){
        return !!this.user.chiamato;
    }
    set isChiamato(value: boolean){
        if(value)
            this.user.chiamato = new Date().toISOString();
        else
            this.user.chiamato = null;
    }
    
    setChiamato(){
        if(!confirm("Confermi di aver chiamato il lead?")){
            this.user.chiamato = null;
            return;
        }

        BaseUserClient.setChiamato(this.user.identifier, !!this.user.chiamato);
    }

}

</script>
