 <template>
    <div>
        <div class="previewContainer" v-if="localPreview">
            <video class="objectPreview" controls @loadeddata="emitData" v-if="previewType == 'video'">
                <source :src="localPreview" />
            </video>
            <audio class="objectPreview" controls @loadeddata="emitData" v-else-if="previewType == 'audio'">
                <source :src="localPreview" />
            </audio>
            <img class="objectPreview" :src="localPreview" v-else-if="previewType == 'image'" />
            <object :data="localPreview" v-else class="objectPreview"></object>

            <button type="button" class="btn btn-primary" @click="empty">Modifica</button>
        </div>
        <div v-else>
            <file-dropper v-model="imageFile" @update:modelValue="emitModel"></file-dropper>
        </div>
    </div>
</template>

<script lang="ts">
import Component, { mixins, Options } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class filePreviewer extends mixins(WithModel) {

    @Prop() preview: any;
    
    localPreview: any = null;
    previewType: string = "";
    imageFile: File = null;

    created(){
        this.localPreview = this.preview;
    }

    empty() {
        this.localPreview = null;
        this.emitModel(null);
    }

    emitData(ev){
        this.$emit('duration', ev.target.duration);
    }

    @Watch('preview')
    onPreviewChange(next, prev){
        if(typeof(next) == 'string'){
            this.localPreview = next;
        } else {
            this.localPreview = URL.createObjectURL(next);
        }
    }

    @Watch('imageFile')
    onModelChange(next, prev){
        if(!next){
            URL.revokeObjectURL(this.localPreview);
            return;
        }
        this.previewType = '';
        if(typeof(next) == 'string'){
            this.localPreview = next;
        } else {
            if(next.type.indexOf('video') > -1){
                this.previewType = 'video';
            } else if(next.type.indexOf('image') > -1){
                this.previewType = 'image';
            } else if(next.type.indexOf('audio') > -1){
                this.previewType = 'audio';
            }
            this.localPreview = URL.createObjectURL(next);
        }
    }

    beforeUnmount(){
        URL.revokeObjectURL(this.localPreview);
    }

}
</script>

<style scoped>

.objectPreview {
    max-width: 400px;
    height: auto;
    width: 95%;
    background: white;
}
.previewContainer {
    text-align: center;
    position: relative;
}
.previewContainer .btn {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 200px;
    top: 120px;
}


</style>
