import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/chatRooms',
        name: 'chatRooms',
        meta: { sideMenuLabel: "Chat" },
        component: () => import("./views/chatRooms.vue")
    },
    {
        path: '/chatRoomDetail/:identifier',
        name: 'chatRoomDetail',
        component: () => import("./views/chatRoomDetail.vue")
    },
];

export default routes;