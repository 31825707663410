import * as OM from '@/model';
import * as VM from '@/viewmodel';

class _StorageServices  {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    setOpTableFilters(key: string, filters: any){
        window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
    }
    getOpTableFilters(key: string){
        return this.readFromLocalStorage<any>('_op_' + key);
    }

    getSelectedLanguage() {
        return this.readFromLocalStorage<string>('SelectedLanguage');
    }
    setSelectedLanguage(SelectedLanguage: string) {
        window.localStorage.setItem('SelectedLanguage', JSON.stringify(SelectedLanguage));
    }
    
    getLoginData() {
        return this.readFromLocalStorage<OM.LoginData>('LoginData');
    }
    setLoginData(LoginData: OM.LoginData) {
        window.localStorage.setItem('LoginData', JSON.stringify(LoginData));
    }
    
    getCustomerTaskFilter() {
        return this.readFromLocalStorage<string>('customerTaskFilter');
    }
    setCustomerTaskFilter(filter: string) {
        window.localStorage.setItem('customerTaskFilter', JSON.stringify(filter));
    }
    
    getEstateId() {
        return this.readFromLocalStorage<string>('EstateId');
    }
    setEstateId(daysFilter: string) {
        window.localStorage.setItem('EstateId', JSON.stringify(daysFilter));
    }
    
    getQueryParameters() {
        return this.readFromLocalStorage<string>('queryParameters');
    }
    setQueryParameters(parameters: { [key: string]: any }) {
        window.localStorage.setItem('queryParameters', JSON.stringify(parameters));
    }
    
    getSelectedProject() {
        return this.readFromLocalStorage<string>('SelectedProject');
    }
    setSelectedProject(project) {
        window.localStorage.setItem('SelectedProject', JSON.stringify(project));
    }
    
    getProjectColors() {
        return this.readFromLocalStorage<OM.ProjectColors>('ProjectColors');
    }
    setProjectColors(value) {
        window.localStorage.setItem('ProjectColors', JSON.stringify(value));
    }
    
    getClipboard<T = any>() {
        return this.readFromLocalStorage<T>('ClipBoard');
    }
    setClipboard(value) {
        window.localStorage.setItem('ClipBoard', JSON.stringify(value));
    }
    
    getDetailRouteItems() {
        return this.readFromLocalStorage<any>('DetailRouteItems');
    }
    setDetailRouteItems(value: VM.DetailRouteItem[]) {
        window.localStorage.setItem('DetailRouteItems', JSON.stringify(value));
    }
    
}

export let StorageServices = new _StorageServices();