import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-control" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-control" }
const _hoisted_8 = {
  filter: "domanda.name",
  sort: "domanda.name"
}
const _hoisted_9 = { filter: "answerFilter" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { colspan: "99" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, [
      _createVNode(_component_localized_text, {
        localizedKey: "Risposte questionario",
        text: "Risposte questionario"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Professione",
          text: "Professione"
        })
      ]),
      _withDirectives(_createElementVNode("select", {
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProfessione) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.professioni, (item, i) => {
          return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item.name), 9, _hoisted_3))
        }), 256))
      ], 512), [
        [_vModelSelect, _ctx.selectedProfessione]
      ])
    ]),
    (_ctx.risposte)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Cliente",
                text: "Cliente"
              })
            ]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.risposte.customer.name), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Completato",
                text: "Completato"
              })
            ]),
            _createElementVNode("span", _hoisted_7, [
              (_ctx.risposte.isCompleted)
                ? (_openBlock(), _createBlock(_component_localized_text, {
                    key: 0,
                    localizedKey: "Si",
                    text: "Si"
                  }))
                : (_openBlock(), _createBlock(_component_localized_text, {
                    key: 1,
                    localizedKey: "No",
                    text: "No"
                  }))
            ])
          ]),
          _createVNode(_component_op_table, {
            class: "table table-striped",
            items: _ctx.risposte.answerValues,
            filtersFn: _ctx.filtersFn
          }, {
            default: _withCtx((opData) => [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_8, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Domanda",
                      text: "Domanda"
                    })
                  ]),
                  _createElementVNode("td", _hoisted_9, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Risposte",
                      text: "Risposte"
                    })
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (opData.items.length == 0)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                      _createElementVNode("td", _hoisted_11, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Nessun elemento trovato",
                          text: "Nessun elemento trovato"
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((opData.items as OM.AnswerValue[]), (item, i) => {
                  return (_openBlock(), _createElementBlock("tr", { key: i }, [
                    _createElementVNode("td", null, _toDisplayString(item.questionText.values[_ctx.$store.state.selectedLanguage]), 1),
                    _createElementVNode("td", null, [
                      (item.selectAnswer.length > 0)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(item.selectAnswer.map(x => x.values[_ctx.$store.state.selectedLanguage]).join(' - ')), 1)
                          ], 64))
                        : (item.questionText)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(item.questionText), 1)
                            ], 64))
                          : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["items", "filtersFn"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}