<template>
    <div style="position: relative;">
        <div class="d-flex justify-content-between align-items-center" v-if="chat.identifier && isTicket">
            <div class="d-flex">
                <h4>
                    <localized-text localizedKey="Oggetto Ticket:" text="Oggetto Ticket:"></localized-text>
                    <b>{{chat.title}}</b>
                </h4>
                <div class="form-group ms-3" v-if="!readonly && canEdit">
                    <select class="form-control" v-model="chat.assignedToUser" @change="assignToUser">
                        <option :value="undefined">
                            {{$localizationService.GetLocalizedValue('Assegna ticket a un utente', 'Assegna ticket a un utente')}}
                        </option>
                        <option v-for="(item, i) in descendants" :key="i" :value="item.identifier">{{item.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div>
            <div>
                <div class="d-flex align-items-center mb-2" v-if="isTicket && !readonly && ($store.state.isAdmin() || $store.state.isDoctor() || $store.state.isOperator())">
                    <localized-text localizedKey="Stato ticket" text="Stato ticket"></localized-text> <toggler class="ms-2" v-model="chat.disabled" @update:modelValue="chiudiTicket" :inverse="true"></toggler>
                </div>
                <!-- <button type="button" class="btn btn-success" @click="chiudiTicket" v-if="isTicket && !readonly">
                    Chiudi ticket
                </button> -->
                <div v-if="chat.disabled" class="disabledChatLabel">{{isTicket ? 'Ticket chiuso' : 'Stanza disabilitata'}}</div>
            </div>
            <div class="chatMessageContainer">
                <chat-message v-for="(item, i) in messages" :key="i" 
                    @dblclick.native.stop.prevent="onDblClick(item)" 
                    :message="item"
                    :canRemove="canEdit"
                    @removeMessage="toggleRemoveMessage"
                    :messages-to-remove="messagesToRemove">
                </chat-message>
            </div>
        </div>
        <transition name="fade">
            <div class="newChatMessages" v-if="showNuoviMessaggi" @click="goToBottom">
                <span><i class="fa fa-arrow-down"></i> <localized-text localizedKey="nuovi messaggi" text="nuovi messaggi"></localized-text> <i class="fa fa-arrow-down"></i></span>
            </div>
        </transition>
        <div class="chatWriter">
            <div class="quotedMessage" v-if="quotedMessage">
                <button class="cancel" type="button" @click="cancelQuote">X</button>
                <p class="sender">{{quotedMessage.sender}}</p>
                <span class="ellipsis" v-html="quotedMessage.text"></span>   
            </div>
            <div class="chatInput" v-if="!readonly">
                <div class="chatInputTop" v-if="imgPreview">
                    <img :src="imgPreview" />
                    <button type="button" class="btn btn-primary ms-1" @click="sendImg">
                        <localized-text localizedKey="Invia" text="Invia"></localized-text>
                    </button>
                </div>
                <template v-else>
                    <div class="chatInputTop">
                        <textarea v-model="messageText" class="form-control" @keypress.prevent.shift.enter="addReturn"
                            @keydown.enter.exact="sendMessage">
                        </textarea>
                        <button class="btn btn-danger" v-if="messagesToRemove.length > 0" @click="removeMessages">
                            <localized-text localizedKey="Rimuovi messaggi selezionati" text="Rimuovi messaggi selezionati"></localized-text>
                        </button>
                        <label class="imageUploader btn btn-primary ms-1" :class="{ 'disabled': fileToUpload }">
                            <localized-text localizedKey="Allega immagine" text="Allega immagine"></localized-text>
                            <file-input v-model="fileToUpload" @update:modelValue="uploadFile" class="d-none" v-if="fileToUpload == null" />
                        </label>
                    </div>
                    <div class="chatInputBottom">
                        <button type="button" class="btn btn-primary btn-block" :class="{'disabled': !messageText.trim() }" 
                            @click="sendMessage">
                            <localized-text localizedKey="Invia" text="Invia"></localized-text>
                        </button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewmodel';
import store from '@/store';
import router from '@/router';
import { ChatRoomServices } from '@/services/ChatRoomServices';
import { UploadServices } from '@/services/UploadServices';
import linkifyHtml from "linkify-html";
import ChatMessage from './chatMessage.vue';
import FileSelect from '@/components/fileSelect/fileSelect.vue';
import { ChatRoomClient, BaseUserClient, HelpTicketClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class chat extends Vue {

    @Prop() chatIdentifier: string;
    @Prop() readTicket: boolean;

    intervalId: any;
    chat: OM.ChatRoomVm = new OM.ChatRoomVm();
    messages: OM.ChatMessage[] = [];
    messagesToRemove: OM.ChatMessage[] = [];
    messageText: string = "";
    quotedMessage: OM.QuotedMessage = null;
    userId: string = store.state.loginData.userIdentifier;
    messageContainer: Element;
    scrollListenerOn: boolean;
    showNuoviMessaggi: boolean = false;
    isTicket: boolean = false;
    ws: WebSocket;
    fetchingData: boolean = false;
    doneFetching: boolean;
    take: number = 20;

    readonly: boolean = false;
    descendants: OM.NameIdentifier[] = []

    get canEdit(){
        return store.state.isAdmin()
    }

    created(){
        this.readonly = this.readTicket;
        BaseUserClient.getAllOperatorsNameIdentifier(false)
        .then(x => this.descendants = x);
    }

    mounted(){
        this.init();
    }

    @Watch('chatIdentifier')
    onChatIdentifierChange(next, prev){
        this.init();
    }

    init(){
        this.messageContainer = document.querySelector('.chatMessageContainer') as Element;
        ChatRoomClient.getVmById(this.chatIdentifier)
        .then( x => {
            this.chat = x;
            if(x.ticketNumber){
                this.isTicket = true;
            }
            ChatRoomClient.getChatMessages(this.chatIdentifier, this.messages.length, this.take, true)
            .then(msgs => {
                this.messages = msgs;
                this.$emit('loadedmessages', this.messages.length);
                this.$nextTick( () => {
                    this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
                    this.messageContainer.addEventListener('scroll', this.onTopScroll);
                });
                let webSocket = WebSocket; // || MozWebSocket;
                ChatRoomServices.ConnectSocket(this.chatIdentifier, this.chat.title, store.state.loginData.userIdentifier);
                ChatRoomServices.OnMessage(this.onMessage, this.onDisable, this.onRemove);
            })
        });
    }

    imgPreview: string = '';
    fileToUpload: File = null;
    uploadFile(){
        if(!this.fileToUpload)
            return;

        this.imgPreview = URL.createObjectURL(this.fileToUpload);
    }

    onDblClick(val: OM.ChatMessage){
        this.quotedMessage = {
            sender: val.sender,
            text: val.text,
            fromBackend: val.fromBackend
        };
    }
    cancelQuote(){
        this.quotedMessage = null;
    }

    assignToUser(){
        HelpTicketClient.assignToUser(this.chatIdentifier, this.chat.assignedToUser)
        .then(x => {
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Ticket assegnato", "Ticket assegnato"));
        })
    }
    
    onTopScroll(){
        if(!this.isAtTop())
            return;
        if(this.fetchingData || this.doneFetching)
            return;
        this.fetchingData = true;
        ChatRoomClient.getChatMessages(this.chatIdentifier, this.messages.length, this.take, false)
        .then(msgs => {
            this.fetchingData = false;
            let scrollBottom = this.messageContainer.scrollHeight - this.messageContainer.scrollTop;
            this.messages.unshift(...msgs);
            this.$emit('loadedmessages', this.messages.length);
            this.$nextTick(() => {
                this.messageContainer.scrollTop = this.messageContainer.scrollHeight - scrollBottom;
            });
            if(msgs.length == 0){
                this.doneFetching = true;
                this.messageContainer.removeEventListener('scroll', this.onTopScroll);
            }
        })
    }
    
    isAtTop(){
        return this.messageContainer.scrollTop == 0;
    }
    

    chiudiTicket(){
        if(!this.isTicket){
            return;
        }
        HelpTicketClient.chiudiTicket(this.chatIdentifier, this.chat.disabled)
        .then( x => {
            ChatRoomServices.SendDisableMessage(this.chatIdentifier, this.chat.disabled);
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Stato cambiato", "Stato cambiato"));
        })
    }
    destroyed(){
        ChatRoomServices.Disconnect();
    }
    onMessage(message: OM.ChatMessage){
        this.messages.push(message);
        this.$emit('loadedmessages', this.messages.length);
        if(this.isAtBottom()){
            setTimeout( () => {
                this.goToBottom();
            }, 0);
        } else {
            //nuovi messaggi
            this.showNuoviMessaggi = true;
            this.scrollListenerOn = true;
            this.messageContainer.addEventListener('scroll', this.scrollListener);
        }
        this.$emit('message');
    }
    onDisable(data: VM.WsDisableMessage){
        this.chat.disabled = data.disable;
    }
    onRemove(data: VM.WsRemoveMessage){
        let index = this.messages.findIndex(x => x.identifier == data.messageIdentifier);
        this.messages.splice(index, 1);
        this.$emit('messageremove');
        this.$emit('loadedmessages', this.messages.length);
    }
    sendImg(){
        UploadServices.UploadImage(this.fileToUpload)
        .then(x => {
            let newMessage: VM.SendMessageVm = {
                fromBackend: true,
                chatRoomIdentifier: this.chatIdentifier,
                text: `<div class="_chatImg" style="background-image: url(` + x.publicUrl + `);"></div>`,
                senderIdentifier: store.state.user.identifier,
                senderName: store.state.user.personalData.completeName,
                quotedMessage: this.quotedMessage,
                clienteIdentifier: this.chat.clienteIdentifier
            }
            ChatRoomServices.SendMessage(newMessage);
            this.messageText = "";
            this.imgPreview = null;
            this.quotedMessage = null;
            this.fileToUpload = null;
            this.goToBottom();
        }).catch(err => this.fileToUpload = null);
    }
    sendMessage(){
        if(!this.messageText.trim()) {
            return;
        }
        let parsed = linkifyHtml(this.messageText);
        let newMessage: VM.SendMessageVm = {
            fromBackend: true,
            chatRoomIdentifier: this.chatIdentifier,
            text: parsed,
            senderIdentifier: store.state.user.identifier,
            senderName: store.state.user.personalData.completeName,
            quotedMessage: this.quotedMessage,
            clienteIdentifier: this.chat.clienteIdentifier
        }
        ChatRoomServices.SendMessage(newMessage);
        this.messageText = "";
        this.quotedMessage = null;
        this.goToBottom();
    }
    
    addReturn(){
        this.messageText += `
`;
    }
    
    isAtBottom(){
        return Math.abs(Math.floor(this.messageContainer.clientHeight - this.messageContainer.scrollHeight)) == Math.floor(this.messageContainer.scrollTop);
    }
    scrollListener(){
        if(this.isAtBottom()){
            this.showNuoviMessaggi = false;
            this.scrollListenerOn = false;
            this.messageContainer.removeEventListener('scroll', this.scrollListener);
        }
    }
    goToBottom(){
        this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    }

    toggleRemoveMessage(item: OM.ChatMessage){
        let index = this.messagesToRemove.indexOf(item);
        if(index > -1){
            this.messagesToRemove.splice(index, 1);
        } else {
            this.messagesToRemove.push(item);
        }
    }

    removeMessages(){
        if(confirm("Confermi?")){
            ChatRoomClient.removeMessageMultiple({ 
                chatRoomIdentifier: this.chatIdentifier,
                messagesIdentifier: this.messagesToRemove.map(x => x.identifier)
            })
            .then( x => {
                this.messagesToRemove.forEach(c => {
                    ChatRoomServices.SendRemoveMessage(this.chatIdentifier, c.identifier);
                })
                this.messagesToRemove = [];
            })
        }
    }
}

</script>

