<template>
    <div class="opColorPicker">
        <color-picker :disableHistory="true" :isWidget="true" v-model:pureColor="colorBridge" picker-type="chrome" />
    </div>
</template>

<script lang="ts">
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { ColorPicker } from "vue3-colorpicker";

@Options({
    components: { ColorPicker },
})
export default class opColorPicker extends mixins(WithModel) {

    declare localValue: string;

    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    rgbToHex(r, g, b) {
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }
    
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    get colorBridge(){
        let rgb = this.hexToRgb(this.localValue);;
        if(!rgb)
            return null;

        return "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b +")";
    }
    set colorBridge(value){
        if(!value){
            this.localValue = "";
        } else {
            let splitted = value.split(",");
            splitted[0] = splitted[0].replace(/\D/g, "");
            let r = parseInt(splitted[0]);
            splitted[1] = splitted[1].replace(/\D/g, "");
            let g = parseInt(splitted[1]);
            splitted[2] = splitted[2].replace(/\D/g, "");
            let b = parseInt(splitted[2]);
            
            this.localValue = this.rgbToHex(r, g, b);
        }

        this.emitModel(this.localValue);
    }

}
</script>

<style scoped>
    .opColorPicker>>>.vc-colorpicker {
        z-index: 9!important;
    }
</style>