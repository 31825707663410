<template>
    <div class="box d-flex flex-column justify-content-between">
        <div class="boxTitle d-flex justify-content-between">
            <div>
                <localized-text localizedKey="Portafoglio" text="Portafoglio"></localized-text>
            </div>
            <div class="walletProjectCountrySelector">
                <project-country-selector v-model:project="selectedProject" v-model:country="selectedCountry" :showAllOption="true"></project-country-selector>
            </div>
        </div>
        <!-- <div>
            <div class="monthNavigator mt-2">
                <div class="left">
                    <button type="button" class="btn btn-hollow" @click="prevMonth">
                        <img src="@/img/chevron_left.svg" />
                    </button>
                </div>
                <div class="center">{{periodo}}</div>
                <div class="right">
                    <button type="button" class="btn btn-hollow" @click="nextMonth">
                        <img src="@/img/chevron_right.svg" />
                    </button>
                </div>
            </div>
        </div> -->
        <template v-if="portafoglio">
            <doughnut-chart class="max-canvas-400"
                :value="[portafoglio.totaleInAttesa, portafoglio.saldoAttuale]" 
                :label="['In attesa', 'Saldo']" 
                :color="['#FFB7F5', '#6C5DD3']"
                centerLabel="Maturato €"
                :centerNumber="portafoglio.totaleMaturato"
            ></doughnut-chart>
            <div class="portafoglioLower mt-3">
                <div class="portafoglioItem">
                    <div class="icon pagato"><img src="@/img/pagato.svg" /></div>
                    <div class="itemLabel">
                        <localized-text localizedKey="Pagato" text="Pagato"></localized-text> €
                    </div>
                    <div class="grosso">{{$filters.currency(portafoglio.totalePagato)}}</div>
                </div>
                <div class="portafoglioItem">
                    <div class="icon inAttesa"><img src="@/img/in_attesa.svg" /></div>
                    <div class="itemLabel">
                        <localized-text localizedKey="In attesa" text="In attesa"></localized-text> €
                    </div>
                    <div class="grosso">{{$filters.currency(portafoglio.totaleInAttesa)}}</div>
                </div>
                <div class="portafoglioItem">
                    <div class="icon saldo"><img src="@/img/saldo.svg" /></div>
                    <div class="itemLabel">
                        <localized-text localizedKey="Saldo" text="Saldo"></localized-text> €
                    </div>
                    <div class="grosso">{{$filters.currency(portafoglio.saldoAttuale)}}</div>
                </div>
            </div>
            <hr />
            <div class="text-end">
                <template v-if="canRequestPaymentVm">
                    <template v-if="!canRequestPaymentVm.canRequest">
                        <div>
                            <localized-text localizedKey="Le richieste di pagamento vanno inserite <b>dal giorno {{dayFrom}} al giorno {{dayTo}} del mese </b>" 
                                text="Le richieste di pagamento vanno inserite <b>dal giorno {{dayFrom}} al giorno {{dayTo}} del mese </b>"
                                :object="{ dayFrom: canRequestPaymentVm.pagamentoRequestConfig.dayFrom, dayTo: canRequestPaymentVm.pagamentoRequestConfig.dayTo }"
                            ></localized-text>
                        </div>
                    </template>
                    <template v-else>
                        <button type="button" class="btn btn-primary" @click="richiediPagamentoModal" v-if="!portafoglio.richiestaPagamento">
                            <localized-text localizedKey="Richiedi pagamento" text="Richiedi pagamento"></localized-text>
                        </button>
                        <div class="text-end" v-else>
                            <div><b>
                                <localized-text localizedKey="Richiesta di pagamento inserita:" text="Richiesta di pagamento inserita:"></localized-text>
                            </b></div>
                            <div>
                                <localized-text localizedKey="Data ricevuta:" text="Data ricevuta:"></localized-text>
                                {{$filters.date(portafoglio.richiestaPagamento.dataRicevuta)}}
                            </div>
                            <div>
                                <localized-text localizedKey="Importo:" text="Importo:"></localized-text> 
                                {{$filters.currency(portafoglio.richiestaPagamento.importo)}} €
                            </div>
                            <div v-if="portafoglio.richiestaPagamento.allegato">
                                <a :href="portafoglio.richiestaPagamento.allegato.publicUrl" target="_blank">
                                    <localized-text localizedKey="Vedi allegato" text="Vedi allegato"></localized-text> 
                                </a>
                            </div>
                            <div>
                                <button type="button" class="btn btn-danger mt-2" @click="annullaRichiestaPagamento">
                                    <localized-text localizedKey="Annulla richiesta di pagamento" text="Annulla richiesta di pagamento"></localized-text> 
                                </button>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';
import store from '@/store';
import RichiediPagamentoModal from './richiediPagamentoModal.vue';
import { checkIfRichiestaPagamentoPossible } from '@/utils';
import { DashboardClient, OperatorClient, ProjectConfigClient, UserPaymentRequestClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class wallet extends Vue {

    @Prop() user: OM.PaymentUser;
    @Prop() reload;

    portafoglio: OM.DashboardPortafoglioVm  = null;

    selectedProject: string = "";
    selectedCountry: string = "";
    canRequestPaymentVm: OM.CanRequestPaymentVm = null;
    
    fromDate: string = null;
    toDate: string = null;
    periodo: string = "";
    
    created(){
        this.fromDate = moment(new Date()).startOf('month').toISOString();
        this.toDate = moment(new Date()).endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }

    @Watch('reload')
    @Watch('selectedProject')
    @Watch('selectedCountry')
    onReload(next, prev){
        this.init();
    }
    
    init(){
        DashboardClient.getDashPortafoglio(this.user.identifier, this.selectedProject, this.selectedCountry)
        .then( x => {
            this.portafoglio = x;
        });

        if(this.selectedProject){
            ProjectConfigClient.canRequestPayment(this.selectedProject)
            .then(x => {
                this.canRequestPaymentVm = x;
            })
        }
    }

    richiediPagamentoModal(){
        if(!checkIfRichiestaPagamentoPossible(this.user)){
            ModalServices.alertModal(LocalizationServices.GetLocalizedValue("Attenzione", "Attenzione"), LocalizationServices.GetLocalizedValue("Completa i dati nel profilo per richiedere il pagamento", "Completa i dati nel profilo per richiedere il pagamento"));
            return;
        }
        // OperatorClient.getById(this.user.identifier)
        // .then(x => {
        //     x.authData.password = "";
        this.$opModal.show(RichiediPagamentoModal, {
            project: this.selectedProject,
            country: this.selectedCountry,
            user: this.user,
        }, () => this.init());
        // })
    }
    annullaRichiestaPagamento(){
        if(!confirm("Confermi l'annullamento della richiesta?"))
            return;

        UserPaymentRequestClient.annullaRichiestaPagamento(this.portafoglio.richiestaPagamento.identifier)
        .then(x => {
            this.init();
        })
    }
    
    prevMonth(){
        this.fromDate = moment(this.fromDate).subtract(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).subtract(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }
    nextMonth(){
        this.fromDate = moment(this.fromDate).add(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).add(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }

}

</script>

<style scoped>

</style>