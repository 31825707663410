import { RouteComponent, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import backendRoleConfig from '../backendRoleConfig';
import operators from '../operators';
import baseVoucher from '../baseVoucher';
import booking from '../booking';
import chatRoom from '../chatRoom';
import commission from '../commission';
import dashboardDocument from '../dashboardDocument';
import deferredPayment from '../deferredPayment';
import dictionary from '../dictionary';
import faqNode from '../faqNode';
import helpTicket from '../helpTicket';
import mailSentLog from '../mailSentLog';
import messageOfTheDay from '../messageOfTheDay';
import network from '../network';
import newsFeed from '../newsFeed';
import professionConfiguration from '../professionConfiguration';
import projectConfig from '../projectConfig';
import purchase from '../purchase';
import qualificationConfig from '../qualificationConfig';
import ricettarioNode from '../ricettarioNode';
import shopOffer from '../shopOffer';
import dashboard from '../dashboard';
import smsSentLog from '../smsSentLog';
import doctors from '../doctors';
import leads from '../leads';
import customers from '../customers';
import survey from '../survey';
import videoDellaSettimana from '../videoDellaSettimana';
import defaultPlanWeek from '../defaultPlanWeek';
import mailEvents from '../mailEvents';
import smsEvents from '../smsEvents';
import profile from '../profile';
import importOperations from '../importOperations';
import paymentRequest from '../paymentRequest';

let routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import("./views/layout.vue"),
        children: [
            ...dashboard.routes,
            ...backendRoleConfig.routes,
            ...operators.routes,
            ...doctors.routes,
            ...leads.routes,
            ...customers.routes,
            ...baseVoucher.routes,
            ...booking.routes,
            ...chatRoom.routes,
            ...commission.routes,
            ...dashboardDocument.routes,
            ...deferredPayment.routes,
            ...dictionary.routes,
            ...faqNode.routes,
            ...helpTicket.routes,
            ...mailSentLog.routes,
            ...smsSentLog.routes,
            ...messageOfTheDay.routes,
            ...network.routes,
            ...newsFeed.routes,
            ...professionConfiguration.routes,
            ...projectConfig.routes,
            ...purchase.routes,
            ...qualificationConfig.routes,
            ...ricettarioNode.routes,
            ...shopOffer.routes,
            ...survey.routes,
            ...defaultPlanWeek.routes,
            ...videoDellaSettimana.routes,
            ...mailEvents.routes,
            ...smsEvents.routes,
            ...profile.routes,
            ...importOperations.routes,
            ...paymentRequest.routes
        ]
    }
];

export default routes;