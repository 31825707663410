<template>
    <div class="form-group">
        <label>Compagnia</label>
        <input type="text" class="form-control" v-model="value.compagnia" />
    </div>
    <div class="form-group">
        <label>Numero polizza</label>
        <input type="text" class="form-control" v-model="value.numero" />
    </div>
    <div class="form-group">
        <label>Indennizzo</label>
        <input type="text" class="form-control" v-model="value.indennizzo" />
    </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model'
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class polizzaData extends Vue {

    @Prop() value: OM.PolizzaData;

}
</script>
<style scoped>
</style>
