<template>
    <div class="opModal">
        <div class="modalCloser">
            <span class="pointer"><i class="fa fa-times" @click="$emit('close')"></i></span>
        </div>
        <div class="boxTitle">{{title}}</div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Progetto" text="Progetto"></localized-text>
            </label>
            <select class="form-control" v-model="selectedProject">
                <option v-for="item in projects" :value="item">{{item}}</option>
            </select>
        </div>
        <div class="form-group" v-if="showDate">
            <label>
                <localized-text localizedKey="Seleziona data e ora" text="Seleziona data e ora"></localized-text>
            </label>
            <div class="">
                <op-date-time class="form-control" :initialvalue="null" v-model="scadenza" :min-date="today" :inline="false"></op-date-time>
            </div>
        </div>
        <div class="form-group">
            <label>
                <localized-text localizedKey="Dettagli" text="Dettagli"></localized-text>
            </label>
            <textarea placeholder="Scrivi i dettagli importanti" class="form-control" rows="4" v-model="xnota"></textarea>
        </div>
        <div class="text-center">
            <button type="button" class="btn btn-primary" :disabled="!canSave" @click="save">
                <localized-text localizedKey="Conferma" text="Conferma"></localized-text>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as VM from '@/viewmodel';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient } from '@/services/Services';
import { BaseUserServices } from '@/services/BaseUserServices';

@Options({})
export default class ActionModal extends Vue {

    @Prop() title: string;
    @Prop() leadIdentifier: string;
    @Prop({
        default: true
    }) showDate: boolean
    @Prop() commentType: string
    @Prop() status: string
    @Prop() nota: string

    scadenza: string = "";
    xnota: string = "";

    today = new Date();

    projects: string[] = CONST.ProjectNames.GetAll();
    selectedProject: string = "";

    created(){
        this.xnota = this.nota;
    }

    get canSave(){
        // if(!this.nota.trim())
        //     return false;
        if(this.showDate){
            if(!this.scadenza)
                return false;
        }
        return !!this.selectedProject;
    }

    save(){
        if(!this.canSave){
            return;
        }
        if(!this.showDate){
            this.scadenza = new Date().toISOString();
        }

        // let vm: VM.SaveToDoVm = {
        //     leadIdentifier: this.leadIdentifier,
        //     type: this.commentType,
        //     text: this.xnota,
        //     status: this.status,
        //     startDate: this.scadenza,
        //     allegati: null,
        //     endDate: null,
        //     project: this.selectedProject,
        // }

        // BaseUserServices.saveToDo(vm)
        // .then(x => {
        //     this.$emit('close');
        // })
    }

}

</script>

<style scoped>

.opModal {
    min-width: 340px;
}

</style>