import { RouteRecordRaw } from 'vue-router';

let routes: RouteRecordRaw[] = [
    {
        path: '/shopOffers',
        name: 'shopOffers',
        meta: { sideMenuLabel: "Offerte" },
        component: () => import("./views/offers/shopOffers.vue")
    },
    {
        path: '/shopOffers/:categoryIdentifier',
        name: 'shopOffersByCategory',
        component: () => import("./views/offers/shopOffers.vue")
    },
    {
        path: '/shopOfferDetail/:identifier',
        name: 'shopOfferDetail',
        component: () => import("./views/offers/shopOfferDetail.vue")
    },

    {
        path: '/categories',
        name: 'categories',
        meta: { sideMenuLabel: "Categorie" },
        component: () => import("./views/categories/categories.vue")
    },
    {
        path: '/categoryDetail/:identifier',
        name: 'categoryDetail',
        component: () => import("./views/categories/categoryDetail.vue")
    },
    
    {
        path: '/products',
        name: 'products',
        meta: { sideMenuLabel: "Prodotti" },
        component: () => import("./views/products/products.vue")
    },
    {
        path: '/productDetail/:identifier',
        name: 'productDetail',
        component: () => import("./views/products/productDetail.vue")
    },

];

export default routes;