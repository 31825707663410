import * as OM from '@/model';
import * as CONST from '@/const';
import { RouteRecordRaw } from 'vue-router';

export class LoginVm
{
    email: string;
    password: string;
}

export class ModuleConfig {
    name: string;
    group: string;
    routes: RouteRecordRaw[];
}

export class SearchAnagraficaVm {
    nome: string;
    cognome: string;
    codiceFiscale: string;
    ragioneSociale: string;
    partitaIva: string;
    matricola: string;

    constructor(){
        this.nome = "";
        this.cognome = "";
        this.codiceFiscale = "";
        this.ragioneSociale = "";
        this.partitaIva = "";
        this.matricola = "";
    }
}

export class AddCommentVm
{
    fileList: File[];
    text: string;
    entityIdentifier?: string;

    constructor(){
        this.fileList = [];
        this.text = "";
    }
}

export class SocketMessage
{
    type: string;
    content: any;
}

export class WsPresentMessage
{
    chatRoomName: string;
    chatRoomIdentifier: string;
    userIdentifier: string;
}

export class WsDisableMessage
{
    disable: boolean;
    chatRoomIdentifier: string;
}

export class WsRemoveMessage
{
    messageIdentifier: string;
    chatRoomIdentifier: string;
}

export class WsMessage<T> {
    type: string;
    content: T;
}

export class SendMessageVm
{
    fromBackend: boolean;
    chatRoomIdentifier: string;
    text: string;
    senderName: string;
    senderIdentifier: string;
    clienteIdentifier: string;
    quotedMessage: OM.QuotedMessage;
}

export class UploadFileVm
{
    entityIdentifier: string;
    file: File;
}

export class NetworkUserVM extends OM.NetworkUserVm {
    offset?: string;
    hide?: boolean;

    constructor(){
        super();
    }
}

export class RichiediPagamentoUserVm
{
    project: string;
    country: string;
    importo: number;
    dataRicevuta: string;
    userIdentifier: string;
    useRitenutaAcconto: boolean;
    fatturaAttachment: File;
}

export class NameCount {
    name: string;
    count: number;
}

export class DashboardDateItems {
    date: Date;
    piani: NameCount[];
    daFare: NameCount[];
    inAttesa: NameCount[];
    constructor(){
        this.piani = [];
        this.daFare = [];
        this.inAttesa = [];
    }
}


export class LayoutSlug
{
    text: string;
    selected?: boolean;
    callback: (item: LayoutSlug) => void;
    removeCallback?: (item: LayoutSlug) => void;
}

export class ButtonSettings {
    text: string;
    callback: any;
}

export class NewLeadVm
{
    contactName: string;
    email: string;
    password: string;
    loginEnabled: boolean;
    telephone: OM.Telephone;
    list: string;
    project: string;
    country: string;
    assignToUser: OM.NameIdentifier;
    note: AddCommentVm;

    constructor(){
        this.contactName = "";
        this.email = "";
        this.password = "";
        this.loginEnabled = false;
        this.list = "";
        this.project = "" ;
        this.country = "";
        this.assignToUser = null;
        this.note = new AddCommentVm();
        this.telephone = new OM.Telephone();
    }
}

export class DetailRouteItem {
    identifier: string;
    index: number;
    routeName?: string;
}

export class SaveToDoVm
{
    project: string;
    leadIdentifier: string;
    type: string;
    text: string;
    status: string;
    startDate: string;
    endDate: string;
    allegati: File[];

    constructor(){
        this.project = "";
        this.leadIdentifier = "";
        this.type = "";
        this.text = "";
        this.status = "";
        this.startDate = "";
        this.endDate = "";
        this.allegati = [];
    }
}